import React, { useState } from 'react';
import { Login, Register, Recover } from 'components/organisms';

import * as S from './styles';

export const Alunos = () => {
  const [card, setCard] = useState('login');

  return (
    <>
      <S.Content>
        <div className="container">
          {card === 'login' && <Login changeCard={setCard} />}
          {card === 'register' && <Register changeCard={setCard} />}
          {card === 'forgot' && <Recover changeCard={setCard} />}
        </div>
      </S.Content>
    </>
  );
};

export * from './Vagas';
export * from './VagasCurriculo';
export * from './VagasInscritas';
export * from './VagasFinalizadas';
export * from './VagaDetalhe';
export * from './AlterarSenha';
