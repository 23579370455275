import { createContext, useState } from 'react';
import { baseUrl, headers } from 'api';

export const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getUsers = async (tipo) => {
    const response = await fetch(`${baseUrl}/usuarios/?tipo=${tipo}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setUsers(data);
    } else {
      setUsers([]);
    }
  };

  const saveUser = async (data) => {
    const response = await fetch(`${baseUrl}/usuarios/criar-conta/`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify(data),
    });

    return response;
  };

  const getCompanies = async (params) => {
    const response = await fetch(`${baseUrl}/dashboard/empresa/${params || ''}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await response.json();
    setCompanies(data);
  };

  const changePassword = async ({ userId, password }) => {
    const response = await fetch(`${baseUrl}/usuarios/${userId}/troca-senha/`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ senha: password }),
    });

    const data = await response.json();
    return data;
  };

  return (
    <UsersContext.Provider value={{ users, getUsers, saveUser, getCompanies, companies, changePassword }}>
      {children}
    </UsersContext.Provider>
  );
};
