import { useEffect } from 'react';
import { TextArea } from 'components/atoms';
import { baseUrl, headers } from 'api';
import { useAuth, useCurriculo } from 'hooks';

import * as S from './styles';

import DocumentoIcon from 'assets/icons/006-document.svg';

const ResumoProfissional = () => {
  const { sessionToken } = useAuth();
  const { resumoProfissional, setResumoProfissional } = useCurriculo();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResumoProfissional({
      ...resumoProfissional,
      [name]: value,
    });
  };

  const getResumoProfissionalData = async () => {
    const response = await fetch(`${baseUrl}/curriculo/resumo-profissional/`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionToken,
      },
    });

    const data = await response.json();

    setResumoProfissional({
      resumo: data.resumo || '',
    });
  };

  useEffect(() => {
    getResumoProfissionalData();
  }, []);

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={DocumentoIcon} alt="Ícone de resumo" />
          <h1>Resumo profissional</h1>
        </div>
      </S.Title>

      <S.Inputs>
        <TextArea
          placeholder="Resumo"
          name="resumo"
          value={resumoProfissional.resumo}
          onChange={handleChange}
          required
        />
      </S.Inputs>
    </>
  );
};

export default ResumoProfissional;
