import { Overlay } from 'components/atoms';
import * as S from './styles';

export const Loading = ({ text }) => {
  return (
    <>
      <Overlay />
      <S.Text>{text}</S.Text>
    </>
  );
};
