import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  border-radius: 16px;
  background: #f3f3f3;
  position: relative;
  margin: 10px 0px;
`;

export const Text = styled.div`
  padding: 12px;

  @media screen and (max-width: 768px) {
    padding: 12px 12px 0px 12px;
  }
`;

export const Title = styled.p`
  font-size: 16px;
  color: var(--blue-senac);
  font-weight: 600;
`;

export const Description = styled.p`
  color: var(--orange-senac);
  font-size: 14px;
`;

export const ActionButtons = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ActionButtonDelete = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 65px;
  background: #d5212e;
  border-radius: 0 15px 15px 0;
  padding: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 19px;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    top: auto;
    bottom: -15px;
    left: 0px;
    height: 45px;
    width: 50%;
    border-radius: 0 0 15px 0;
    padding: 16px;
  }
`;

export const ActionButtonEdit = styled.button`
  position: absolute;
  right: 65px;
  top: 0;
  height: 100%;
  width: 65px;
  background: var(--blue-senac);
  padding: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    top: auto;
    bottom: -15px;
    left: 0px;
    height: 45px;
    width: calc(50% + 30px);
    border-radius: 0 0 0 15px;
    padding: 16px;
  }
`;
