import { baseUrl, headers } from 'api';

export const getCursos = async () => {
  const response = await fetch(`${baseUrl}/curriculo/curso/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};

export const addCursos = async (preCursos) => {
  const response = await fetch(`${baseUrl}/curriculo/curso/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...preCursos }),
  });

  return response;
};

export const editCursos = async (cursosItem) => {
  const response = await fetch(`${baseUrl}/curriculo/curso/${cursosItem.id}/`, {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...cursosItem }),
  });

  return response;
};

export const deleteCursos = async (cursosItem) => {
  const response = await fetch(`${baseUrl}/curriculo/curso/${cursosItem.id}/`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
