import { ProcessosContext } from 'contexts/Processos';
import { useContext } from 'react';

export function useProcessos() {
  const context = useContext(ProcessosContext);

  if (!context) {
    throw new Error('useProcessos must be used within an ProcessosProvider');
  }

  return context;
}
