import { useState, useEffect } from 'react';
import { useAuth } from 'hooks';

import * as S from './styles';

import UserIcon from 'assets/images/user-icon.png';

export const Profile = () => {
  const { user } = useAuth();
  const [userFirstName, setUserFirstName] = useState('');

  useEffect(() => {
    const firstName = user.nome.split(' ')[0];
    setUserFirstName(firstName);
  }, [user]);

  return (
    <S.Content>
      <p>Olá, {userFirstName ? userFirstName : 'Usuário'}</p>
      <img src={user?.image?.length > 0 ? user.image : UserIcon} alt="" />
    </S.Content>
  );
};
