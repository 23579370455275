import React, { useEffect, useState } from 'react';
import { useProcessos } from 'hooks';
import { Table } from 'components/organisms';
import { TemplateEmpresas } from 'components/templates';
import { PrintCurriculo } from './PrintCurriculo';
import { Link } from 'react-router-dom';

import * as S from './styles';

import Contratados from 'assets/icons/contratados.svg';
import Home from 'assets/icons/home.svg';

export const VagasContratados = () => {
  const [alunoSelected, setAlunoSelected] = useState(null);
  const [rows, setRows] = useState([]);
  const [openCurriculo, setOpenCurriculo] = useState(false);
  const { getCurriculo, getCurriculumByCompany, curriculumList } = useProcessos();

  useEffect(() => {
    getCurriculumByCompany(`?status=contratado`);
  }, []);

  useEffect(() => {
    if (curriculumList?.results?.length > 0) {
      const rows_format = curriculumList.results.map((aluno) => {
        return {
          'nome completo': aluno.usuario?.nome,
          vaga: aluno.processo?.nome_vaga,
          situação: aluno.status,
          actions: [
            {
              action: 'Imprimir',
              color: '#9B9B9B',
              onClick: async () => {
                const curriculo = await getCurriculo(aluno.usuario.id);
                setOpenCurriculo(true);
                setAlunoSelected(curriculo);
              },
            },
          ],
        };
      });

      setRows(rows_format);
    } else {
      setRows([]);
    }
  }, [curriculumList, getCurriculo]);

  return (
    <S.PrintArea>
      <TemplateEmpresas title="PORTAL DA EMPRESA" className="no-print">
        <S.Row>
          <Link to="/empresas/vagas">
            <S.Banner>
              <img src={Home} alt="" />
              Início
            </S.Banner>
          </Link>
          <S.Banner>
            <img src={Contratados} alt="Ícone currículos" />
            Contratados
          </S.Banner>
        </S.Row>

        <S.TableContainer>
          <Table columns={['Nome Completo', 'Vaga', 'Situação', '']} rows={rows} />
        </S.TableContainer>
      </TemplateEmpresas>

      {alunoSelected && openCurriculo ? <PrintCurriculo curriculo={alunoSelected} className="print-area" /> : null}
    </S.PrintArea>
  );
};
