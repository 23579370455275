import styled from 'styled-components';

export const Cards = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > input {
    width: 300px;
  }

  > div,
  a > div {
    width: max-content;
    margin: 0 24px 0 0;
    display: flex;
    align-items: center;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
      padding: 12px 16px;
      margin-bottom: 24px;
    }
  }
`;

export const Curriculo = styled.div`
  margin-top: 24px;
  width: calc(100% - 32px);

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > div {
      width: 30%;
      margin: 8px;
    }

    > p {
      width: calc(70% - 16px);
    }
  }

  .space-between {
    justify-content: space-between;
  }

  h1 {
    color: var(--orange-senac);
    font-size: 28px;
    text-transform: uppercase;
  }

  h2 {
    color: var(--blue-senac);
    font-size: 18px;
    text-transform: uppercase;
  }

  h3,
  div h3,
  div div h3 {
    color: var(--orange-senac);
    font-size: 18px;
    text-transform: uppercase;
  }

  p {
    color: #656565;
    font-size: 16px;
  }

  a {
    color: #656565;
    font-weight: bold;
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      border-radius: 50%;
      background: var(--blue-senac);
      padding: 8px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FollowUp = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 24px auto;
  cursor: pointer;
  position: sticky;
  bottom: 0;
  background: #f5f6f7;
  padding: 8px;

  > img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  > p {
    color: var(--orange-senac);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }
`;

export const ModalContent = styled.div`
  width: 100%;

  > .row {
    margin: 16px 0;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;

    > button {
      width: 200px;
      margin: 16px 8px;
    }
  }

  .space-between {
    justify-content: space-between;
  }

  > button {
    width: 200px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #d2d2d2;
  margin: 12px 0;
`;
