import * as S from './styles';

export const Table = ({ columns, rows, fontSize, handlePageChange, pageCount, handleClick, pageSelected }) => {
  return (
    <>
      <S.TableContent fontSize={fontSize} isClickable={true}>
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index}>
              {columns?.map((column, i) => {
                if (column.toLowerCase() === '' && row['actions']) {
                  return (
                    <td key={i} className="actions">
                      {row['actions']?.map(({ action, color, onClick, hide }, ind) => (
                        <S.Action color={color} onClick={onClick} key={ind} hide={hide}>
                          {action}
                        </S.Action>
                      ))}
                    </td>
                  );
                } else if (column.toLowerCase() === 'cidade' && row['cidade']) {
                  return (
                    <td
                      onClick={handleClick || row.handleClick}
                      key={i}
                      style={{ color: row['cidade'].color || '#656565' }}
                    >
                      {row['cidade'].text}
                    </td>
                  );
                } else {
                  return (
                    <td onClick={handleClick || row.handleClick} key={i}>
                      {row[column.toLowerCase()]}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </S.TableContent>
      {pageCount > 1 ? (
        <S.Paginator
          className="paginate"
          onPageChange={handlePageChange}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          nextLabel=">"
          previousLabel="<"
          forcePage={pageSelected - 1 || 0}
        />
      ) : null}
    </>
  );
};
