import { baseUrl, headers } from 'api';

export const getAreaInteresseSelect = async () => {
  const response = await fetch(`${baseUrl}/curriculo/area-interesse/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};

export const getAreaInteresse = async () => {
  const response = await fetch(`${baseUrl}/curriculo/area-interesse-aluno/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};

export const addAreaInteresse = async (preAreaInteresse) => {
  const response = await fetch(`${baseUrl}/curriculo/area-interesse-aluno/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ area_interesse: preAreaInteresse.area_interesse }),
  });

  return response;
};

export const deleteAreaInteresse = async (areaInteresseItemId) => {
  const response = await fetch(`${baseUrl}/curriculo/area-interesse-aluno/${areaInteresseItemId}/`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
