import { CurriculoContext } from 'contexts/Alunos/Curriculo';
import { useContext } from 'react';

export function useCurriculo() {
  const context = useContext(CurriculoContext);

  if (!context) {
    throw new Error('useCurriculo must be used within an CurriculoProvider');
  }

  return context;
}
