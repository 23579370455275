import { Input, Switch } from 'components/atoms';
import { useCadastroVaga } from 'hooks';
import { moneyMask } from 'helpers';

import * as S from './styles';
import { useEffect } from 'react';

const Beneficios = ({ processo }) => {
  const { cadastroVaga, setCadastroVaga } = useCadastroVaga();

  const handleChange = (e, isMoney) => {
    const { name, value } = e.target;

    if (isMoney) {
      setCadastroVaga({
        ...cadastroVaga,
        [name]: moneyMask(value),
      });
    } else {
      setCadastroVaga({
        ...cadastroVaga,
        [name]: value,
      });
    }
  };

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;
    setCadastroVaga({
      ...cadastroVaga,
      [name]: checked,
    });
  };

  return (
    <>
      <S.Title>Benefícios</S.Title>

      <S.Inputs>
        <div className="first-column__beneficios">
          <Switch
            id="1"
            text="Vale transporte?"
            name="vale_transporte"
            checked={cadastroVaga.vale_transporte}
            onChange={handleChangeSwitch}
          />
          <Input
            placeholder="Reais por dia"
            name="valor_transporte"
            value={moneyMask(cadastroVaga.valor_transporte)}
            onChange={(e) => handleChange(e, true)}
            disabled={!cadastroVaga.vale_transporte}
          />
        </div>
        <div className="second-column__beneficios">
          <Switch
            id="2"
            text="Vale alimentação/refeição?"
            name="vale_alimentacao"
            checked={cadastroVaga.vale_alimentacao}
            onChange={handleChangeSwitch}
          />
          <Input
            placeholder="Reais por dia"
            name="valor_alimentacao"
            value={moneyMask(cadastroVaga.valor_alimentacao)}
            onChange={(e) => handleChange(e, true)}
            disabled={!cadastroVaga.vale_alimentacao}
          />
        </div>
        <div className="third-column__beneficios">
          <Switch
            id="3"
            text="Assistencia médica?"
            name="assistencia_medica"
            checked={cadastroVaga.assistencia_medica}
            onChange={handleChangeSwitch}
          />
          <Input
            placeholder="Observações"
            name="obs_assistencia_medica"
            value={cadastroVaga.obs_assistencia_medica}
            onChange={handleChange}
            disabled={!cadastroVaga.assistencia_medica}
          />
        </div>
      </S.Inputs>
    </>
  );
};

export default Beneficios;
