import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 12px 0px;
  z-index: 9999;

  .row {
    display: flex;
    padding: 25px 15px;

    p {
      width: calc(100% - 158px);
      margin-right: 8px;
    }

    button {
      width: max-content;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: 0;

    .row {
      flex-direction: column;

      p {
        width: 100%;
        margin-right: 0;
      }
    }
  }
`;
