import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TemplateAdmin } from 'components/templates';
import { BlueButton, Card, Input, Select, Switch } from 'components/atoms';
import { cpfMask, removeMask } from 'helpers';
import { getUnidades, useUsers } from 'hooks';
import { toast } from 'react-toastify';
import { baseUrl, headers } from 'api';

import * as S from '../styles';
import * as Style from './styles';

import ArrowLeft from 'assets/icons/arrow-left.svg';

export const NewUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { saveUser } = useUsers();
  const [unidades, setUnidades] = useState([]);
  const [values, setValues] = useState({
    nome: '',
    cpf: '',
    email: '',
    unidade: '',
    perfil: '',
    senha: '',
    ativo: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;

    setValues({
      ...values,
      [name]: checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (id) {
      const res = await fetch(`${baseUrl}/usuarios/info/?usuario=${id}`, {
        method: 'PUT',
        headers: {
          ...headers,
          Authorization: sessionStorage.getItem('@senac:token'),
        },
        body: JSON.stringify({
          ...values,
          cpf_cnpj: removeMask(values.cpf),
          tipo_usuario: parseInt(values.perfil, 10),
          status: values.ativo,
        }),
      });
      if (res.status === 200) {
        toast.success('Usuário editado com sucesso');
      } else {
        toast.error('Erro ao editar usuário!');
      }
    } else {
      const response = await saveUser({
        ...values,
        cpf_cnpj: removeMask(values.cpf),
        tipo_usuario: parseInt(values.perfil, 10),
        status: values.ativo,
      });
      if (response.status === 200) {
        toast.success('Usuário criado com sucesso!');
      } else {
        toast.error('Erro ao criar usuário!');
      }
    }
  };

  const getInitialData = async () => {
    const response = await getUnidades();
    const units = await response.json();
    const unitsOptions = units.results.map((item) => {
      return {
        label: item.titulo,
        value: item.id,
      };
    });
    setUnidades(unitsOptions);
  };

  const getUserData = async () => {
    const res = await fetch(`${baseUrl}/usuarios/info/?usuario=${id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    const data = await res.json();
    setValues({
      ...data,
      cpf: data.cpf_cnpj,
      perfil: data.tipo_usuario,
      ativo: data.status,
    });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, [id]);

  return (
    <TemplateAdmin page="Dashboard">
      <S.Cards>
        <Style.Row>
          <Card blue>{!id ? <p>CADASTRO DE USUÁRIO</p> : <p>EDITAR USUÁRIO</p>}</Card>
          <a onClick={() => navigate(-1)}>
            <img src={ArrowLeft} alt="" />
            Voltar
          </a>
        </Style.Row>
      </S.Cards>

      <S.Form onSubmit={handleSubmit}>
        <Card>
          <div className="row">
            <Input placeholder="Nome Completo" name="nome" value={values.nome} onChange={handleChange} required />
            <Input placeholder="CPF" name="cpf" value={cpfMask(values.cpf)} onChange={handleChange} required />
            <Input
              placeholder="E-Mail"
              name="email"
              value={values.email}
              onChange={handleChange}
              type="email"
              required
            />
            {!id ? (
              <Input
                placeholder="Senha"
                name="senha"
                value={values.senha}
                onChange={handleChange}
                required
                type="password"
              />
            ) : null}
            <Select
              title="Unidade"
              name="unidade"
              actualSelected={values.unidade}
              options={unidades}
              onChange={handleChange}
              required
            />
            <Select
              title="Perfil"
              name="perfil"
              actualSelected={values.perfil}
              options={[
                { label: 'Administrador', value: '1' },
                { label: 'Colaborador', value: '4' },
              ]}
              onChange={handleChange}
              required
            />
            <Switch id="ativo" name="ativo" text="Ativo" checked={values.ativo} onChange={handleChangeSwitch} />
          </div>

          <BlueButton type="submit">SALVAR</BlueButton>
        </Card>
      </S.Form>
    </TemplateAdmin>
  );
};
