import React, { useEffect } from 'react';
import { Input, Select, InputFile } from 'components/atoms';
import { useAuth, useCadastro } from 'hooks';
import { cnpjCpfMaskRead, phoneMask } from 'helpers';
import { getCadastroEmpresa } from './requests';

import * as S from './styles';

export const Dados = ({ id }) => {
  const { user } = useAuth();
  const { cadastro, setCadastro } = useCadastro();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setCadastro({
      ...cadastro,
      [name]: value,
    });
  };

  const getCadastroEmpresaData = async () => {
    const response = await getCadastroEmpresa(id);

    if (response.status === 200) {
      const data = await response.json();

      setCadastro({
        cpf_cnpj: data.cpf_cnpj,
        nome_fantasia: data.nome_fantasia,
        inscricao_municipal: data.inscricao_municipal || '',
        razao_social: data.razao_social || '',
        home_page: data.home_page || '',
        ramo_atividade: data.ramo_atividade || '',
        quantidade_funcionario: data.quantidade_funcionario || '',
        telefone: data.telefone || '',
        logotipo: data.logotipo || null,
        email: data.email || '',
        celular: data.celular || '',
        tipo_empresa: data.tipo_empresa || '',
      });
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const { name, files } = e.target;

    setCadastro({
      ...cadastro,
      [name]: files[0],
    });
  };

  useEffect(() => {
    getCadastroEmpresaData();
  }, [user]);

  return (
    <S.InputsCadastroEmpresa>
      <div className="first-column__cadastro-empresa">
        <Input placeholder="CNPJ/CPF*" name="cpf_cnpj" value={cnpjCpfMaskRead(cadastro.cpf_cnpj)} disabled />
        <Input
          placeholder="Inscrição municipal"
          name="inscricao_municipal"
          value={cadastro.inscricao_municipal}
          onChange={handleChange}
          type="number"
        />
      </div>
      <div className="second-column__cadastro-empresa">
        <Input
          placeholder="Razão social*"
          name="razao_social"
          value={cadastro.razao_social}
          onChange={handleChange}
          required
        />
        <Input
          placeholder="Nome fantasia*"
          name="nome_fantasia"
          value={cadastro.nome_fantasia}
          onChange={handleChange}
        />
      </div>
      <div className="third-column__cadastro-empresa">
        <Input placeholder="Home page" name="home_page" value={cadastro.home_page} onChange={handleChange} />
        <Input
          placeholder="Ramo de atividade"
          name="ramo_atividade"
          value={cadastro.ramo_atividade}
          onChange={handleChange}
          required
        />
        <Input
          placeholder="Quantidade de funcionários"
          name="quantidade_funcionario"
          value={cadastro.quantidade_funcionario}
          onChange={handleChange}
          type="number"
        />
      </div>
      <div className="fourth-column__cadastro-empresa">
        <Input placeholder="Telefone" name="telefone" value={phoneMask(cadastro.telefone)} onChange={handleChange} />
        <InputFile
          label="Logotipo"
          id="logotipo"
          file={cadastro.logotipo}
          onChange={handleFileChange}
          accept="image/png, image/jpeg"
        />
        <Input
          placeholder="E-mail*"
          name="email"
          value={cadastro.email}
          onChange={handleChange}
          type="email"
          required
        />
      </div>
      <div className="sixth-column__cadastro-empresa">
        <Input
          name="celular"
          value={phoneMask(cadastro.celular)}
          onChange={handleChange}
          type="phone"
          placeholder="Celular*"
          required
        />
        <Select
          title="Tipo da empresa*"
          options={[
            { label: 'Com fins lucrativos', value: 'Com fins lucrativos' },
            { label: 'Sem fins lucrativos', value: 'Sem fins lucrativos' },
            { label: 'Estadual', value: 'Estadual' },
            { label: 'Municipal', value: 'Municipal' },
            { label: 'Federal', value: 'Federal' },
            { label: 'Sistemas', value: 'Sistemas' },
            { label: 'ONG', value: 'ONG' },
            { label: 'Outros', value: 'Outros' },
          ]}
          name="tipo_empresa"
          actualSelected={cadastro.tipo_empresa}
          onChange={handleChange}
          required
        />
      </div>
    </S.InputsCadastroEmpresa>
  );
};
