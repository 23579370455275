import styled from 'styled-components';

export const Cards = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > input {
    width: 300px;
  }

  > div,
  a > div {
    width: max-content;
    margin: 0 24px 0 0;
    display: flex;
    align-items: center;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
      padding: 12px 16px;
      margin-bottom: 24px;
    }
  }
`;

export const GoBack = styled.a`
  color: #656565;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 50%;
    background: var(--blue-senac);
    padding: 8px;
    margin-right: 8px;
  }
`;

export const FollowUp = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 24px auto;
  cursor: pointer;
  position: sticky;
  bottom: 0;
  background: #f5f6f7;
  padding: 8px;

  > img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  > p {
    color: var(--orange-senac);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }
`;

export const ModalContent = styled.div`
  width: 100%;

  > .row {
    margin: 16px 0;
  }

  .row {
    display: flex;

    > .card {
      width: 90%;
      margin-right: 16px;
      padding: 8px 15px;

      > p {
        font-size: 28px;
        color: var(--blue-senac);
        font-weight: bold;
      }
    }

    span {
      font-size: 18px;
      color: #656565;

      &:nth-child(2) {
        color: var(--orange-senac);
      }
    }

    > .switch {
      width: max-content;
      display: flex;
      align-items: center;
    }
  }

  .space-between {
    justify-content: space-between;
  }

  > button {
    width: 200px;
  }
`;

export const PrintArea = styled.div`
  .print-area {
    display: none;
  }

  .button {
    width: max-content;
    margin: 16px 0 32px;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    > img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
      object-fit: contain;
    }
  }

  @media print {
    .print-area {
      display: initial;
    }
    .no-print {
      display: none !important;
    }
  }
`;
