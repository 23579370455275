import React from 'react';
import WhatsIcon from 'assets/icons/whatsapp.svg';
import * as S from './styles';

export const WhatsappIcon = () => {
  return (
    <S.Container href="https://wa.me/558632289500" target="_blank" rel="noreferrer">
      <S.Icon src={WhatsIcon} alt="Logo WhatsApp" />
    </S.Container>
  );
};
