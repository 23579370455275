import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Banner = styled.div`
  display: flex;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px 16px;
  color: #fff;
  background: #75bd10;
  align-items: center;
  font-weight: 600;
  font-size: 24px;

  &:first-child {
    width: max-content;
    margin-right: 16px;
  }

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin: 0 16px;
  }

  @media screen and (max-width: 768px) {
    &:first-child {
      display: none;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 24px 0;
`;

export const PrintArea = styled.div`
  .print-area {
    display: none;
  }
  @media print {
    .print-area {
      display: initial;
    }
    .no-print {
      display: none;
    }
  }
`;
