import styled from 'styled-components';

export const SubTitle = styled.p`
  color: #fff;
  margin: 16px 0;
  font-weight: bold;
`;

export const Question = styled.p`
  color: var(--blue-senac);
  margin: 16px 0 8px;
  font-weight: 600;
`;

export const Answers = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  label {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #d9d9d9;
    color: var(--blue-senac);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
  }

  input {
    opacity: 0;
    position: absolute;
    left: -200vw;

    &:checked ~ label {
      background: var(--blue-senac);
      color: #d9d9d9;
    }
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 80px;
  border-radius: 16px;
  padding: 8px;
`;

export const Submit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 0;

  button {
    width: 230px;
  }
`;
