import { Loading } from 'components/molecules';
import { TemplateCurriculum, TemplateEmpresas } from 'components/templates';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useProcessos } from 'hooks';

import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from './styles';

export const DetalheAluno = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { curriculo, getCurriculo } = useProcessos();
  const { id } = useParams();
  const navigate = useNavigate();

  const getInitialData = async () => {
    setIsLoading(true);
    await getCurriculo(id);
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      {isLoading ? <Loading text="Carregando dados..." /> : null}

      <TemplateEmpresas page="PORTAL DA EMPRESA">
        <S.GoBack onClick={() => navigate(-1)}>
          <img src={ArrowLeft} alt="" />
          Voltar
        </S.GoBack>
        <TemplateCurriculum curriculo={curriculo} />
      </TemplateEmpresas>
    </>
  );
};
