import { useState } from 'react';
import { Input, BlueButton } from 'components/atoms';
import { TemplateAlunos } from 'components/templates';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';

import * as S from './styles';

import Home from 'assets/icons/home.svg';

export const AlterarSenhaEmpresa = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    senha_atual: '',
    senha1: '',
    senha2: '',
  });

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const submitNewPassword = async (e) => {
    e.preventDefault();
    if (values.senha1 !== values.senha2) {
      toast.warning('As novas senhas estão diferentes.');
    } else {
      const res = await fetch(`${baseUrl}/usuarios/troca-senha/`, {
        method: 'POST',
        headers: {
          ...headers,
          Authorization: sessionStorage.getItem('@senac:token'),
        },
        body: JSON.stringify(values),
      });
      const data = await res.json();
      if (data.OK) {
        toast.success('Senha alterada com sucesso.');
        navigate('/empresas/vagas');
      } else {
        toast.error(data.error);
      }
    }
  };

  return (
    <TemplateAlunos title="PORTAL DO CANDIDATO">
      <S.Row>
        <Link to="/alunos/vagas">
          <S.Banner>
            <img src={Home} alt="" />
            Início
          </S.Banner>
        </Link>
        <S.Banner>Recuperar Senha</S.Banner>
      </S.Row>

      <S.Form onSubmit={submitNewPassword}>
        <Input
          placeholder="Senha Atual"
          name="senha_atual"
          value={values.senha_atual}
          onChange={handleChange}
          required
          type="password"
        />
        <Input
          placeholder="Senha Nova"
          name="senha1"
          value={values.senha1}
          onChange={handleChange}
          required
          type="password"
        />
        <Input
          placeholder="Repetir Senha Nova"
          name="senha2"
          value={values.senha2}
          onChange={handleChange}
          required
          type="password"
        />
        <BlueButton type="submit">SALVAR</BlueButton>
      </S.Form>
    </TemplateAlunos>
  );
};
