import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Banner = styled.div`
  display: flex;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px 16px;
  color: #fff;
  background: var(--blue-senac);
  align-items: center;
  font-weight: 600;
  font-size: 24px;

  &:first-child {
    width: max-content;
    margin-right: 16px;
  }

  &.button {
    width: max-content;
    margin-left: 16px;
    cursor: pointer;
  }

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin: 0 16px;
  }

  @media screen and (max-width: 768px) {
    &:first-child {
      display: none;
    }
    &.button {
      width: 100%;
      margin-left: 0;
    }
  }
`;

export const Submit = styled.div`
  width: 230px;
  margin: 8px 0px 20px 0px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PrintArea = styled.div`
  .print-area {
    display: none;
  }
  @media print {
    .print-area {
      display: initial;
    }
    .no-print {
      display: none !important;
    }
  }
`;
