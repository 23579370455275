import styled from 'styled-components';

export const Title = styled.div`
  margin-top: 38px;
  font-size: 28px;
  color: var(--blue-senac);
  font-weight: 600;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title__text {
    display: flex;
    align-items: center;

    img {
      margin-right: 24px;
    }

    h1 {
      font-size: 28px;
    }
  }

  button {
    width: max-content;
    height: 42px;
    font-size: 20px;
  }
`;

export const Inputs = styled.div`
  * {
    display: grid;
    grid-gap: 10px;
  }

  .first-column__endereco {
    grid-template-columns: 1fr 60px 2fr;

    > button {
      height: 54.5px;
      display: grid;
      place-items: center;
    }
  }

  .second-column__endereco {
    grid-template-columns: 1fr;
  }

  .third-column__endereco {
    grid-template-columns: 1fr 2fr 2fr 1fr;
  }

  @media screen and (max-width: 768px) {
    * {
      grid-gap: 0px;
      grid-template-columns: 1fr !important;
    }
    .first-column__endereco {
      grid-template-columns: 1fr 60px !important;
      grid-gap: 10px;
      > div:last-child {
        grid-column: span 2 / span 2;
      }
    }
  }
`;
