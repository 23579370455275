import React from 'react';
import { Navigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from '../hooks';
import { routes, authenticatedRoutesAlunos, authenticatedRoutesEmpresas, authenticatedRoutesAdmin } from './constants';

export const RequireAuthAlunos = ({ children }) => {
  const { isLoggedIn, userType } = useAuth();

  if (userType) {
    if (!isLoggedIn || userType !== 3) {
      return <Navigate to="/" />;
    }
  }

  return children;
};

export const RequireAuthEmpresas = ({ children }) => {
  const { isLoggedIn, userType } = useAuth();

  if (userType) {
    if (!isLoggedIn || userType !== 2) {
      return <Navigate to="/" />;
    }
  }

  return children;
};

export const RequireAuthAdmin = ({ children }) => {
  const { isLoggedIn, userType } = useAuth();
  if (userType) {
    const isNotAdmin = userType !== 0 && userType !== 1 && userType !== 4;

    if (!isLoggedIn || isNotAdmin) {
      return <Navigate to="/" />;
    }
  }

  return children;
};

export default function Router() {
  return (
    <Routes>
      {routes.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
      {authenticatedRoutesAlunos.map(({ path, element }, index) => (
        <Route key={index} path={path} element={<RequireAuthAlunos>{element}</RequireAuthAlunos>} />
      ))}
      {authenticatedRoutesEmpresas.map(({ path, element }, index) => (
        <Route key={index} path={path} element={<RequireAuthEmpresas>{element}</RequireAuthEmpresas>} />
      ))}
      {authenticatedRoutesAdmin.map(({ path, element, children }, index) => (
        <Route key={index} path={path} element={<RequireAuthAdmin>{element}</RequireAuthAdmin>}>
          {children && children.length > 0
            ? children.map(({ path: childrenPath, element: childrenElement }, i) => (
                <Route key={i} path={childrenPath} element={childrenElement} />
              ))
            : null}
        </Route>
      ))}
    </Routes>
  );
}
