import { useState, useEffect, useMemo } from 'react';
import { Table } from 'components/organisms';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { cnpjCpfMaskRead } from 'helpers';
import { useNavigate } from 'react-router-dom';

import * as S from '../styles';

import ArrowLeft from 'assets/icons/arrow-left.svg';
import { BlueButton } from 'components/atoms';

export const Encaminhamentos = ({ relatoriosMes, relatoriosAno, MonthYearSelects }) => {
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = useMemo(() => 20);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPageSelected(page.selected + 1);
  };

  const getInitialData = async (page) => {
    const params = new URLSearchParams({
      page_size: pageSize,
      page: page || pageSelected,
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/encaminhamento/?${params}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setPageCount(data.count / pageSize);
      setPageSelected(1);
      const rowsFormat = data?.results?.map((item) => {
        return {
          ...item,
          'nome da empresa': item.nome_empresa,
          'nome da vaga': item.nome_vaga,
          'nome do aluno': item.nome_aluno,
          mês: item.mes,
          cpf: cnpjCpfMaskRead(item.cpf),
        };
      });
      setRows(rowsFormat);
    } else {
      toast.error('Erro ao sincronizar os relatórios');
      setRows([]);
    }
  };

  useEffect(() => {
    getInitialData();
  }, [pageSelected]);

  return (
    <>
      <S.GoBack onClick={() => navigate(-1)}>
        <img src={ArrowLeft} alt="" />
        Voltar
      </S.GoBack>

      <S.SelectSection>
        <MonthYearSelects />
        <BlueButton onClick={() => getInitialData(1)}>Buscar</BlueButton>
      </S.SelectSection>
      <S.TableWrapper>
        <Table
          columns={['ANO', 'MÊS', 'NOME DA EMPRESA', 'NOME DA VAGA', 'NOME DO ALUNO', 'CPF', 'QUANTIDADE', 'SEGMENTO']}
          rows={rows}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          pageSelected={pageSelected}
        />
      </S.TableWrapper>
    </>
  );
};
