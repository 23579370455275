export const phoneMask = (value) => {
  if (value) {
    value = value.replace(/\D/g, '');
    if (value.length <= 10) {
      return value
        .replace(/(\d{2})/, '($1) ')
        .replace(/(\d{4})/, '$1-')
        .replace(/(\d{4})/, '$1');
    }

    return value
      .replace(/(\d{2})/, '($1) ')
      .replace(/(\d{5})/, '$1-')
      .replace(/(\d{4})/, '$1')
      .substring(0, 15);
  }
};
