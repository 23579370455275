import styled from 'styled-components';

export const Drawer = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  padding: 16px 40px 16px 16px;
  background: var(--blue-senac);
  z-index: 999;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 32px;
    background: #f5f6f7;
    border-radius: 40px 0 0 40px;
  }

  img {
    width: 130px;
    height: 80px;
    display: flex;
    margin: 24px auto;
    object-fit: contain;
  }

  ul {
    list-style: none;

    li {
      padding: 4px 0;
    }
  }

  a {
    margin: 8px 16px;
    display: flex;
    color: #fff;
    transition: color 0.3s;
    align-items: center;
    justify-content: left;
    font-weight: 500;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin: 0 8px 0 0;
    }

    &.active,
    :hover {
      color: var(--orange-senac);
    }
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    left: -300px;
    transition: left 0.3s;
    border-radius: 0 40px 40px 0;

    &:after {
      display: none;
    }

    &.open {
      left: 0;
    }
  }
`;

export const Leave = styled.button`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px;
  color: #fff;
  background: transparent;
  border: none;
  font-size: 18px;
  text-align: left;
  margin-top: 16px;
`;
