import styled from 'styled-components';

export const Banner = styled.div`
  display: flex;
  width: 100%;
  border-radius: 16px;
  margin: 24px 0;
  padding: 24px 16px;
  color: #fff;
  background: var(--orange-senac);
  align-items: center;
  font-weight: 600;
  font-size: 24px;

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin: 0 16px;
  }
`;

export const InputsCadastroEmpresa = styled.div`
  * {
    display: grid;
    grid-gap: 10px;
  }

  .first-column__cadastro-empresa {
    grid-template-columns: 1fr 1fr;
  }

  .second-column__cadastro-empresa {
    grid-template-columns: 1fr 1fr;
  }

  .third-column__cadastro-empresa {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .fourth-column__cadastro-empresa {
    grid-template-columns: 1fr 1fr 2fr;
  }

  .fifth-column__cadastro-empresa {
    grid-template-columns: 9fr 1fr;
  }
  .sixth-column__cadastro-empresa {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    * {
      grid-gap: 0px;
      grid-template-columns: 1fr !important;
    }
  }
`;

export const Submit = styled.div`
  width: 230px;
  margin: 8px 0px 20px 0px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
