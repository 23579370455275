import { useState, useEffect, useMemo } from 'react';
import { Table } from 'components/organisms';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { cnpjCpfMaskRead, phoneMask } from 'helpers';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from '../styles';
import { BlueButton } from 'components/atoms';

export const Usuarios = ({ relatoriosMes, relatoriosAno, MonthYearSelects }) => {
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = useMemo(() => 20);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPageSelected(page.selected + 1);
  };

  const getInitialData = async (page) => {
    const params = new URLSearchParams({
      page_size: pageSize,
      page: page || pageSelected,
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/usuarios/?${params}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setPageCount(data.count / pageSize);
      setPageSelected(1);
      const rowsFormat = data?.results?.map((item) => {
        return {
          ...item,
          mês: item.mes,
          nome: item.nome_aluno,
          'e-mail': item.email,
          cpf: cnpjCpfMaskRead(item.cpf_cnpj),
          // @todo: retornar o perfil do usuário
          // perfil:
          status: item.status ? 'Ativo' : 'Inativo',
        };
      });
      setRows(rowsFormat);
    } else {
      toast.error('Erro ao sincronizar os relatórios');
      setRows([]);
    }
  };

  useEffect(() => {
    getInitialData();
  }, [pageSelected]);

  return (
    <>
      <S.GoBack onClick={() => navigate(-1)}>
        <img src={ArrowLeft} alt="" />
        Voltar
      </S.GoBack>

      <S.SelectSection>
        <MonthYearSelects />
        <BlueButton onClick={() => getInitialData(1)}>Buscar</BlueButton>
      </S.SelectSection>

      <S.TableWrapper>
        <Table
          columns={['ANO', 'MÊS', 'NOME', 'E-MAIL', 'CPF', 'PERFIL', 'UNIDADE', 'STATUS']}
          rows={rows}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          pageSelected={pageSelected}
        />
      </S.TableWrapper>
    </>
  );
};
