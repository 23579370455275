import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card, Input, WhiteButton, BlueButton } from 'components/atoms';
import { Table } from 'components/organisms';
import { TemplateAdmin } from 'components/templates';
import { Modal } from 'components/molecules';
import { useUsers, useAuth } from 'hooks';
import { cnpjCpfMaskRead } from 'helpers';
import { toast } from 'react-toastify';

import Database from 'assets/icons/database.svg';

import * as S from './styles';

export const AdminEmpresas = () => {
  const navigate = useNavigate();
  const { userType } = useAuth();
  const { companies, getCompanies, changePassword } = useUsers();
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const [searchParams] = useSearchParams();
  const [userToChangePassword, setUserToChangePassword] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const pageSize = 20;

  const handleCloseChangePassword = () => {
    setNewPassword('');
    setUserToChangePassword(null);
  };

  const handleChangePassword = async () => {
    const response = await changePassword({ userId: userToChangePassword, password: newPassword });
    toast.success(response.OK);
    handleCloseChangePassword();
  };

  useEffect(() => {
    if (companies?.results?.length > 0) {
      const rows_format = companies.results.map((company) => {
        return {
          id: company.id,
          nome: company.nome.toLowerCase(),
          cpf: cnpjCpfMaskRead(company.cpf_cnpj),
          handleClick: () => {
            navigate(`/admin/empresas/editar/${company.id}`);
          },
          actions: [
            {
              action: 'Vagas',
              color: '#004B8D',
              onClick: () => {
                navigate(`/admin/empresas/vagas/${company.id}`);
              },
            },
            {
              action: 'Editar',
              color: '#F68B1F',
              onClick: () => {
                navigate(`/admin/empresas/editar/${company.id}`);
              },
              hide: userType !== 0 && userType !== 1,
            },
            {
              action: 'Trocar Senha',
              color: '#656565',
              onClick: () => {
                setUserToChangePassword(company.id);
              },
              hide: userType !== 0 && userType !== 1,
            },
          ],
        };
      });

      setRows(rows_format);
    } else {
      setRows([]);
    }
    setPageCount(companies.total_pages);
  }, [navigate, companies, userType]);

  useEffect(() => {
    const page = searchParams.get('page');
    if (page) setPageSelected(page);
    getCompanies(`?page_size=${pageSize}&page=${page || 1}`);
  }, [searchParams]);

  const handlePageChange = (e) => {
    if (pageSelected === 1) {
      navigate(`/admin/empresas/?page=${pageSelected}`);
    } else {
      navigate(`/admin/empresas/?page=${e.selected + 1}`);
    }
  };

  const searchUser = (e) => {
    e.preventDefault();
    getCompanies(`?search=${e.target.value}&page_size=${pageSize}&page=1`);
  };

  return (
    <TemplateAdmin page="Dashboard">
      <S.Cards>
        <Card blue>
          <img src={Database} alt="" />
          <p>Empresas</p>
        </Card>

        <Input
          border="var(--orange-senac)"
          placeholder="Buscar por Nome da Empresa ou CPF/CNPJ"
          onChange={searchUser}
        />
      </S.Cards>

      <S.TableCard>
        <Card>
          <Table
            columns={['NOME', 'CPF', '']}
            rows={rows}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            pageSelected={pageSelected}
          />
        </Card>
      </S.TableCard>

      {userToChangePassword ? (
        <Modal isOpen closeButton onClose={handleCloseChangePassword} title="Cadastrar nova senha">
          <Input
            placeholder="Nova senha"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            marginBottom={24}
          />
          <S.Row>
            <WhiteButton onClick={handleCloseChangePassword}>Cancelar</WhiteButton>
            <BlueButton onClick={handleChangePassword}>Confirmar</BlueButton>
          </S.Row>
        </Modal>
      ) : null}
    </TemplateAdmin>
  );
};

export * from './Edit';
