import styled from 'styled-components';

export const Wrapper = styled.div`
  > input {
    position: absolute;
    left: -200vw;
    opacity: 0;
  }

  > label {
    width: 100%;
    border-radius: 16px;
    border: 1px solid #d2d2d2;
    padding: 16px 40px 16px 16px;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
