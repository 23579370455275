import { useState, useEffect, useMemo } from 'react';
import { TemplateAdmin } from 'components/templates';
import { Table } from 'components/organisms';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ModalDesclassificar } from 'pages/Empresas/CurriculoPorVaga/ModalDesclassificar';
import { ModalContratar } from 'pages/Empresas/CurriculoPorVaga/ModalContratar';
import { ModalReservar } from 'pages/Empresas/CurriculoPorVaga/ModalReservar';
import { PrintCurriculo } from 'pages/Empresas/CurriculoPorVaga/PrintCurriculo';

import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from './styles';
import { useProcessos } from 'hooks';

export const InscritosVagaAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [alunoSelected, setAlunoSelected] = useState(null);
  const [rows, setRows] = useState([]);
  const [isOpenDesclassificar, setIsOpenDesclassificar] = useState(false);
  const [isOpenContratar, setIsOpenContratar] = useState(false);
  const [isOpenReservar, setIsOpenReservar] = useState(false);
  const [openCurriculo, setOpenCurriculo] = useState(false);
  const { getCurriculo, getCurriculumAdmin, curriculumList, pageCurriculums, setPageCurriculums } = useProcessos();
  const [pageSelected] = useMemo(() => {
    const page = searchParams.get('page');
    if (page) return page;
    return '1';
  }, [searchParams]);
  const page_size = 20;

  const handlePageChange = (e) => {
    if (id) {
      const filter = {
        page_size,
        page: pageSelected < 1 ? 1 : e.selected + 1,
      };
      const filterFormatted = new URLSearchParams(filter);
      navigate(
        `/admin/vagas/inscritos/${id}/?${filterFormatted
          .toString()
          .replace(/\w+=&/g, '')
          .replace(/&\w+=$/, '')}`,
      );
    }
  };

  useEffect(() => {
    if (id) {
      const page = searchParams.get('page');
      const filter = {
        page_size,
        page: page || 1,
      };
      const filterFormatted = new URLSearchParams(filter);
      getCurriculumAdmin({
        processoId: id,
        params: `?${filterFormatted
          .toString()
          .replace(/\w+=&/g, '')
          .replace(/&\w+=$/, '')}`,
      });
    }
  }, [id, searchParams]);

  useEffect(() => {
    if (curriculumList?.results?.length > 0) {
      const rows_format = curriculumList.results.map((aluno) => {
        return {
          'nome completo': aluno.usuario.nome.toLowerCase(),
          vaga: aluno.processo?.nome_vaga,
          situação: aluno.status,
          handleClick: async () => {
            navigate(`/admin/alunos/curriculo/${aluno.usuario.id}`);
          },
          actions: [
            {
              action: 'Imprimir',
              color: '#9B9B9B',
              onClick: async () => {
                const curriculo = await getCurriculo(aluno.usuario.id);
                setOpenCurriculo(true);
                setAlunoSelected(curriculo);
              },
            },
            {
              action: 'Contratar',
              color: '#004B8D',
              onClick: async () => {
                setOpenCurriculo(false);
                setIsOpenContratar(true);
                setAlunoSelected(aluno);
              },
            },
            {
              action: 'Cadastro de Reserva',
              color: '#F68B1F',
              onClick: () => {
                setOpenCurriculo(false);
                setIsOpenReservar(true);
                setAlunoSelected(aluno);
              },
            },
            {
              action: 'Desclassificar',
              color: '#656565',
              onClick: async () => {
                setOpenCurriculo(false);
                setIsOpenDesclassificar(true);
                setAlunoSelected(aluno);
              },
            },
          ],
        };
      });

      setRows(rows_format);
    } else {
      setRows([]);
    }
  }, [curriculumList, getCurriculo]);

  return (
    <S.PrintArea>
      <TemplateAdmin page="Dashboard" className="no-print">
        <S.Wrapper>
          <div className="row space-between">
            <h1>Currículos</h1>
            <a onClick={() => navigate(-1 * Number(pageSelected))}>
              <img src={ArrowLeft} alt="" />
              Voltar
            </a>
          </div>
        </S.Wrapper>
        <S.Separator />

        <S.TableContainer>
          <Table
            columns={['Nome Completo', 'Vaga', 'Situação', '']}
            rows={rows}
            pageCount={pageCurriculums}
            handlePageChange={handlePageChange}
            pageSelected={pageSelected}
          />

          <ModalDesclassificar
            aluno={alunoSelected}
            isOpen={isOpenDesclassificar}
            onClose={() => setIsOpenDesclassificar(false)}
          />
          <ModalContratar aluno={alunoSelected} isOpen={isOpenContratar} onClose={() => setIsOpenContratar(false)} />
          <ModalReservar aluno={alunoSelected} isOpen={isOpenReservar} onClose={() => setIsOpenReservar(false)} />
        </S.TableContainer>
      </TemplateAdmin>

      {alunoSelected && openCurriculo ? <PrintCurriculo curriculo={alunoSelected} className="print-area" /> : null}
    </S.PrintArea>
  );
};
