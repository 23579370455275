import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% - 32px);
  margin: 32px 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow: hidden;
    > div {
      overflow: auto;
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > input {
    width: 300px;
  }

  > div,
  a > div {
    width: max-content;
    margin: 0 24px 0 0;
    display: flex;
    align-items: center;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
      padding: 12px 16px;
      margin-bottom: 24px;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;

  a {
    color: #333;
    padding: 4px 8px;
    border-bottom: 2px solid transparent;
    transition: background-color 0.3s;

    &:hover {
      background: #f5f5f5;
    }
    &.active {
      font-weight: 500;
      border-bottom-color: var(--orange-senac);
    }
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin: 32px 0;
  overflow: auto;

  table {
    width: max-content;
  }
`;

export const DownloadCsv = styled.button`
  border-radius: 8px;
  width: max-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: auto;
  background: #f5f5f5;
  padding: 8px 16px;

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const GoBack = styled.div`
  display: flex;
  padding: 8px 16px;
  border-radius: 50px;
  background: #004b8d;
  color: #fff;
  width: max-content;
  cursor: pointer;

  img {
    margin-right: 8px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  gap: 0 32px;
  width: 100%;
  flex-wrap: wrap;

  > div {
    width: 320px;
    max-width: 100%;
  }
`;

export const SelectSection = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 16px 0;

  > div {
    width: max-content;
  }

  > button {
    width: max-content;
    height: max-content;
  }
`;
