import { useState } from 'react';
import { baseUrl } from 'api';

export const Banners = () => {
  const [banners, setBanners] = useState([]);

  const getBanners = async () => {
    const response = await fetch(`${baseUrl}/banner/`);
    const data = await response.json();
    setBanners(data);
  };

  return { banners, getBanners };
};
