import { useState, useEffect } from 'react';
import { Input, Select, BlueButton } from 'components/atoms';
import { CardIdiomas } from 'pages/Alunos/components/CardsCurriculo/CardIdiomas';
import { useCurriculo } from 'hooks';
import { getIdiomas, addIdiomas, editIdiomas } from './requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import IdiomasIcon from 'assets/icons/010-idiomas.svg';

const Idiomas = () => {
  const { idiomas, setIdiomas, preIdiomas, setPreIdiomas } = useCurriculo();
  const preIdiomasInitialState = {
    nome: '',
    nivel: '',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreIdiomas({
      ...preIdiomas,
      [name]: value,
    });
  };

  const addMoreIdiomas = async () => {
    const response = await addIdiomas(preIdiomas);

    if (response.status === 200) {
      const data = await response.json();
      setIdiomas([...idiomas, data]);
      setPreIdiomas(preIdiomasInitialState);
      toast.success('Idioma adicionado com sucesso!');
    } else {
      toast.error('Erro ao adicionar idioma.');
    }
  };

  const confirmEditIdiomas = async () => {
    const response = await editIdiomas(preIdiomas);

    if (response.status === 200) {
      const data = await response.json();
      setIdiomas(idiomas.map((item) => (item.id === data.id ? data : item)));
      setPreIdiomas(preIdiomasInitialState);
      toast.success('Idioma editado com sucesso!');
    } else {
      toast.error('Erro ao editar idioma');
    }
  };

  const getIdiomasData = async () => {
    const response = await getIdiomas();
    const data = await response.json();
    setIdiomas(data);
  };

  useEffect(() => {
    getIdiomasData();
  }, []);

  const checkIdiomas = () => {
    if (preIdiomas.nome === '') {
      toast.error('Preencha o nome do idioma.');
    } else if (preIdiomas.nivel === '') {
      toast.error('Preencha o nível do idioma.');
    } else {
      addMoreIdiomas();
    }
  };

  const nivelOptions = [
    { value: 'basico', label: 'Básico' },
    { value: 'intermediario', label: 'Intermediário' },
    { value: 'avancado', label: 'Avançado' },
    { value: 'fluente', label: 'Fluente' },
  ];

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={IdiomasIcon} alt="Ícone de idiomas" />
          <h1>Idiomas</h1>
        </div>

        <BlueButton type="button" onClick={() => checkIdiomas()} disabled={idiomas.some((item) => item.editing)}>
          +
        </BlueButton>
      </S.Title>

      <S.Inputs>
        <div className="first-column__idiomas">
          <Input placeholder="Idioma" name="nome" value={preIdiomas.nome} onChange={handleChange} />
          <Select
            title="Nível"
            name="nivel"
            options={nivelOptions}
            onChange={handleChange}
            actualSelected={preIdiomas.nivel ? preIdiomas.nivel : null}
          />
        </div>

        <BlueButton
          type="button"
          className={!idiomas.some((item) => item.editing) && 'is-editing'}
          onClick={() => confirmEditIdiomas()}
        >
          Confirmar edição
        </BlueButton>
      </S.Inputs>

      {idiomas.map((idioma, index) => (
        <CardIdiomas
          key={index}
          id={idioma.id}
          nome={idioma.nome}
          nivel={idioma.nivel}
          idiomas={idiomas}
          setIdiomas={setIdiomas}
          setPreIdiomas={setPreIdiomas}
        />
      ))}
    </>
  );
};

export default Idiomas;
