import { useState, useEffect, useMemo } from 'react';
import { Table } from 'components/organisms';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ExportCsv from 'assets/icons/export-csv.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from '../styles';
import { BlueButton } from 'components/atoms';

export const Vagas = ({ relatoriosMes, relatoriosAno, MonthYearSelects }) => {
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = useMemo(() => 20);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPageSelected(page.selected + 1);
  };

  const getInitialData = async (page) => {
    const params = new URLSearchParams({
      page_size: pageSize,
      page: page || pageSelected,
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/vagas/?${params}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setPageCount(data.count / pageSize);
      setPageSelected(1);
      const rowsFormat = data?.results?.map((item) => {
        return {
          ...item,
          mês: item.mes,
          'nome da empresa': item.nome_empresa,
          'nome da vaga': item.nome_vaga,
          'qtd de vagas': item.qtd_vagas,
          // salário: Number(item.valor_salario).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          'valor mínimo': Number(item.valor_minimo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          'valor máximo': Number(item.valor_maximo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          horarios: `${item.horario_inicio} - ${item.horario_fim}`,
          'tipo de vaga': item.tipo_vaga,
          'vale transporte': item.vale_transporte ? 'Sim' : 'Não',
          'vale alimentação/refeição': item.vale_alimentacao ? 'Sim' : 'Não',
          'assistência médica': item.assistencia_medica ? 'Sim' : 'Não',
          'status da vaga': item.status,
        };
      });
      setRows(rowsFormat);
    } else {
      toast.error('Erro ao sincronizar os relatórios');
      setRows([]);
    }
  };

  async function handleExportCsv() {
    const params = new URLSearchParams({
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/vagas/csv/?${params}`, {
      methdo: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    if (response.ok) {
      const data = await response.blob();
      const a = document.createElement('a');
      const url = URL.createObjectURL(data);
      a.style.display = 'none';
      a.href = url;
      a.download = 'relatorio-vagas.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      toast.error('Erro ao gerar CSV.');
    }
  }

  useEffect(() => {
    getInitialData();
  }, [pageSelected]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <S.GoBack onClick={() => navigate(-1)}>
          <img src={ArrowLeft} alt="" />
          Voltar
        </S.GoBack>
        <S.DownloadCsv onClick={handleExportCsv}>
          <p>EXPORTAR CSV</p>
          <img src={ExportCsv} alt="Baixar CSV" />
        </S.DownloadCsv>
      </div>

      <S.SelectSection>
        <MonthYearSelects />
        <BlueButton onClick={() => getInitialData(1)}>Buscar</BlueButton>
      </S.SelectSection>

      <S.TableWrapper>
        <Table
          columns={[
            'ANO',
            'MÊS',
            'NOME DA EMPRESA',
            'NOME DA VAGA',
            'QTD DE VAGAS',
            // 'SALÁRIO',
            'VALOR MÍNIMO',
            'VALOR MÁXIMO',
            'DIAS',
            'HORÁRIOS',
            'TIPO DE VAGA',
            'ESCOLARIDADE',
            'CIDADE',
            'SEGMENTO',
            'VALE TRANSPORTE',
            'VALE ALEMENTAÇÃO/REFEIÇÃO',
            'ASSISTÊNCIA MÉDICA',
            'STATUS DA VAGA',
          ]}
          rows={rows}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          pageSelected={pageSelected}
        />
      </S.TableWrapper>
    </>
  );
};
