import { useState } from 'react';
import { BlueButton, Input } from 'components/atoms';
import { Modal } from 'components/molecules';
import { dateMask } from 'helpers';

import * as S from './styles';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';

export const FormProrrogar = ({ isOpen, onClose, processoSelected, isAdmin }) => {
  const [data_expiracao, setDataExpiracao] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${baseUrl}${isAdmin ? '/dashboard' : ''}/processo/${processoSelected}/`, {
      method: 'PUT',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ id: processoSelected, data_expiracao }),
    });

    if (response.ok) {
      toast.success('Vaga prorrogada com sucesso.');
      onClose();
    } else {
      toast.error('Erro ao prorrogar a vaga.');
    }
  };

  return (
    <Modal closeButton isOpen={isOpen} onClose={onClose} title="Prorrogar">
      <S.Text>Alterar data de expiração</S.Text>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Data de expiração da vaga"
          name="data_expiracao"
          value={dateMask(data_expiracao)}
          onChange={(e) => {
            setDataExpiracao(e.target.value);
          }}
          required
        />
        <BlueButton type="submit">Confirmar</BlueButton>
      </form>
    </Modal>
  );
};
