import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 24px;
  width: calc(100% - 32px);

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageProfile = styled.img`
  width: 212px;
  height: 260px;
  object-fit: contain;
  object-position: left;
`;

export const Contact = styled.div`
  margin: 24px 0 24px 36px;
  width: calc(100% - 212px - 36px);

  > div {
    background: #e5e5e5;
    width: 100%;
    padding: 24px;
    border: 1px solid #ffbf00;
  }

  li {
    display: flex;
    margin-left: 24px;
    align-items: center;
    position: relative;

    > img {
      position: absolute;
      left: -24px;
    }

    &::marker {
      color: transparent;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 24px 0;
  }
`;

export const Name = styled.h1`
  font-size: 48px;
  text-align: center;
  width: fit-content;
  text-transform: uppercase;
  width: 100%;

  @media print {
    font-size: 36px;
  }
`;

export const Title = styled.h2`
  /* color: var(--blue-senac); */
  color: #252525;
  font-size: 24px;
  text-transform: uppercase;
  margin: 16px 0 8px;
`;

export const Text = styled.p`
  color: #656565;
  font-size: 18px;

  &.justify {
    text-align: justify;
  }
`;
