import React, { useState, createContext, useEffect } from 'react';
import { baseUrl, headers } from 'api';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [user, setUser] = useState({
    id: '',
    tipo_usuario: '',
    status: false,
    nome: '',
    cpf_cnpj: '',
    email: '',
    image: '',
    acesso: '',
    ultimo_acesso: '',
  });

  const sessionToken = sessionStorage.getItem('@senac:token');
  const [isLoggedIn, setIsLoggedIn] = useState(sessionToken ? true : false);

  const sessionUserType = parseInt(sessionStorage.getItem('@senac:tipo_usuario'));
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    setUserType(sessionUserType);
  }, [sessionUserType]);

  const signIn = async (data) => {
    const response = await fetch(`${baseUrl}/usuarios/login/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    return response;
  };

  const register = async (data) => {
    const response = await fetch(`${baseUrl}/usuarios/`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: sessionToken,
      },
      body: JSON.stringify({ ...data }),
    });

    return response;
  };

  const logout = () => {
    sessionStorage.removeItem('@senac:token');
    sessionStorage.removeItem('@senac:tipo_usuario');
    setIsLoggedIn(false);
    setUserType(null);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userLogged'));
    if (userData) setUser(userData);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
        user,
        setUser,
        register,
        signIn,
        logout,
        sessionToken,
        isLoggedIn,
        setIsLoggedIn,
        userType,
        setUserType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
