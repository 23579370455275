import styled from 'styled-components';

export const Cards = styled.div`
  display: flex;
  width: 100%;

  > div,
  a > div {
    width: max-content;
    margin: 0 24px 0 0;
    display: flex;
    align-items: center;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
      padding: 12px 16px;
      margin-bottom: 24px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  width: 100% !important;

  .row {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: max-content;
      margin-right: 16px;

      &:last-child {
        cursor: pointer;
      }
    }
  }

  > div {
    width: max-content;
  }

  a {
    color: #656565;
    font-weight: bold;
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      border-radius: 50%;
      background: var(--blue-senac);
      padding: 8px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    .row {
      flex-wrap: wrap;
      margin-bottom: 16px;
      > div {
        margin-bottom: 16px;
        width: 100%;
      }
    }
  }
`;

export const Form = styled.form`
  margin-top: 24px;
  width: calc(100% - 32px);

  .row {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: calc(50% - 32px);
      margin: 8px 16px;
    }
  }

  button {
    width: max-content;
    min-width: 200px;
    max-width: 100%;
    margin: 16px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    .row {
      > div {
        width: 100%;
        margin: 0;
      }
    }

    button {
      width: 100%;
      margin: 24px 0;
    }
  }
`;
