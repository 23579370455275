import { useEffect } from 'react';
import { Input } from 'components/atoms';
import { phoneMask } from 'helpers';
import { getEndereco } from './requests';
import { useCadastro } from 'hooks';

import * as S from './styles';

import ContatoIcon from 'assets/icons/006-document.svg';

export const Contato = ({ id }) => {
  const { contato, setContato } = useCadastro();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContato({
      ...contato,
      [name]: value,
    });
  };

  const getContatoData = async () => {
    const response = await getEndereco(id);
    const data = await response.json();

    setContato({
      nome_do_responsavel: data.nome || '',
      telefone: data.telefone || '',
    });
  };

  useEffect(() => {
    getContatoData();
  }, []);

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={ContatoIcon} alt="Ícone contato" />
          <h1>Contato</h1>
        </div>
      </S.Title>

      <S.Inputs>
        <div className="first-column__contato">
          <Input
            placeholder="Nome do Responsável*"
            name="nome_do_responsavel"
            value={contato.nome_do_responsavel}
            onChange={handleChange}
            required
          />
          <Input
            placeholder="Telefone*"
            name="telefone"
            value={phoneMask(contato.telefone)}
            onChange={handleChange}
            required
          />
        </div>
      </S.Inputs>
    </>
  );
};
