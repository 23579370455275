import React, { useState } from 'react';
import { Login, Register, Recover } from 'components/organisms';

import * as S from './styles';

export const Empresas = () => {
  const [card, setCard] = useState('login');

  return (
    <S.Content>
      <div className="container">
        {card === 'login' && <Login changeCard={setCard} />}
        {card === 'register' && <Register changeCard={setCard} />}
        {card === 'forgot' && <Recover changeCard={setCard} />}
      </div>
    </S.Content>
  );
};

export * from './Vagas';
export * from './VagasAndamento';
export * from './VagasFinalizadas';
export * from './VagasContratados';
export * from './CadastroEmpresa';
export * from './CadastroVaga';
export * from './VagasCurriculos';
export * from './CurriculoPorVaga';
export * from './Vagas/Edit';
export * from './AlterarSenha';
export * from './DetalheVaga';
export * from './DetalheAluno';
