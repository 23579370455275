import styled from 'styled-components';

export const Title = styled.h1`
  color: #fff;
  font-size: 32px;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: none;

  &.open {
    display: block;
  }

  > .card {
    max-width: calc(100% - 30px);
    width: 800px;
    max-height: 80%;
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1001;
    overflow: auto;
  }
`;

export const Close = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  transition: background-color 0.3s;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: #ffffff4d;
  }
`;
