import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  span {
    font-size: 12px;
    color: #b1b1b1;
    margin-top: -16px;
    text-align: right;
  }
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 16px;
  padding: 16px 40px 16px 16px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom + 'px' : '10px')};
  border: 1px solid ${({ border }) => (border ? border : '#d2d2d2')};

  &[type='time'] {
    appearance: none;
    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }

  ~ img {
    position: absolute;
    top: 22px;
    right: 18px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;
