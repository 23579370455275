import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  a {
    color: #656565;
    font-weight: bold;
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      border-radius: 50%;
      background: var(--blue-senac);
      padding: 8px;
      margin-right: 8px;
    }
  }
`;
