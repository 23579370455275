import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BlueButton, Card, Input, WhiteButton } from 'components/atoms';
import { Table } from 'components/organisms';
import { TemplateAdmin } from 'components/templates';
import { useAuth, useUsers } from 'hooks';
import { cpfMask } from 'helpers';

import User from 'assets/icons/user.svg';

import * as S from './styles';
import { Modal } from 'components/molecules';
import { toast } from 'react-toastify';

export const AdminAlunos = () => {
  const navigate = useNavigate();
  const { users, getUsers, changePassword } = useUsers();
  const { userType } = useAuth();
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const [searchParams] = useSearchParams();
  const [userToChangePassword, setUserToChangePassword] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const pageSize = 20;

  const handlePageChange = (e) => {
    if (pageSelected === 1) {
      navigate(`/admin/alunos/?page=${pageSelected}`);
    } else {
      navigate(`/admin/alunos/?page=${e.selected + 1}`);
    }
    // getUsers(`3&page_size=${pageSize}&page=${e.selected + 1}`);
  };

  const handleCloseChangePassword = () => {
    setNewPassword('');
    setUserToChangePassword(null);
  };

  const handleChangePassword = async () => {
    const response = await changePassword({ userId: userToChangePassword, password: newPassword });
    toast.success(response.OK);
    handleCloseChangePassword();
  };

  useEffect(() => {
    if (users?.results?.length > 0) {
      const rows_format = users.results.map((user) => {
        return {
          id: user.id,
          'nome completo': user.nome.toLowerCase(),
          cpf: cpfMask(user.cpf_cnpj),
          handleClick: () => {
            navigate(`/admin/alunos/editar/${user.id}`);
          },
          actions: [
            {
              action: 'Currículo',
              color: '#004B8D',
              onClick: () => {
                navigate(`/admin/alunos/curriculo/${user.id}`);
              },
            },
            {
              action: 'Editar',
              color: '#F68B1F',
              onClick: () => {
                navigate(`/admin/alunos/editar/${user.id}`);
              },
            },
            {
              action: 'Trocar Senha',
              color: '#656565',
              onClick: () => {
                setUserToChangePassword(user.id);
              },
              hide: userType !== 0 && userType !== 1,
            },
          ],
        };
      });

      setRows(rows_format);
      setPageCount(users.totalPages);
    }
  }, [navigate, users]);

  useEffect(() => {
    const page = searchParams.get('page');
    if (page) setPageSelected(page);
    getUsers(`3&page_size=${pageSize}&page=${page || 1}`);
  }, [searchParams]);

  const searchUser = (e) => {
    e.preventDefault();
    getUsers(`3&search=${e.target.value}`);
  };

  return (
    <TemplateAdmin page="Dashboard">
      <S.Cards>
        <Card blue>
          <img src={User} alt="" />
          <p>Alunos</p>
        </Card>

        <Input border="var(--orange-senac)" placeholder="Busca por CPF" onChange={searchUser} />
      </S.Cards>

      <S.TableCard>
        <Card>
          <Table
            columns={['NOME COMPLETO', 'CPF', '']}
            rows={rows}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            pageSelected={pageSelected}
          />
        </Card>
      </S.TableCard>

      {userToChangePassword ? (
        <Modal isOpen closeButton onClose={handleCloseChangePassword} title="Cadastrar nova senha">
          <Input
            placeholder="Nova senha"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            marginBottom={24}
          />
          <S.Row>
            <WhiteButton onClick={handleCloseChangePassword}>Cancelar</WhiteButton>
            <BlueButton onClick={handleChangePassword}>Confirmar</BlueButton>
          </S.Row>
        </Modal>
      ) : null}
    </TemplateAdmin>
  );
};

export * from './Curriculo';
export * from './Edit';
