import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { BlueButton } from 'components/atoms';

import * as S from './styles';

export const CookiesComponent = () => {
  const cookies = new Cookies();
  const [hasCookies, setHasCookies] = useState(false);

  useEffect(() => {
    if (cookies.get('hasCookies')) {
      setHasCookies(true);
    }
  }, []);

  // Set Cookies
  const acceptCookies = () => {
    cookies.set('hasCookies', true, { path: '/' });
    setHasCookies(true);
  };

  if (!hasCookies) {
    return (
      <S.Wrapper>
        <div className="row">
          <p>
            Coletamos informações do seu dispositivo e da sua navegação por meio de cookies para melhorar o
            funcionamento das páginas, mensurar a audiência e oferecer uma melhor experiência no site. Ao continuar
            navegando, você concorda com a nossa{' '}
            <a href="https://www.pi.senac.br/institucional/politicadeprivacidade" target="_blank" rel="noreferrer">
              Política de Privacidade e Cookies.
            </a>
          </p>
          <BlueButton onClick={acceptCookies}>Permitir acesso</BlueButton>
        </div>
      </S.Wrapper>
    );
  } else {
    return null;
  }
};
