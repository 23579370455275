import React, { useEffect } from 'react';
import { TemplateEmpresas } from 'components/templates';
import { VagaCard } from '../components/VagaCard';
import { useProcessos } from 'hooks';
import { Link } from 'react-router-dom';

import * as S from './styles';

import Finalizados from 'assets/icons/user-finalizados.svg';
import Home from 'assets/icons/home.svg';

export const VagasFinalizadas = () => {
  const { processos, getProcessosEmpresa } = useProcessos();

  useEffect(() => {
    getProcessosEmpresa('?status=finalizado');
  }, []);

  return (
    <TemplateEmpresas title="PORTAL DA EMPRESA">
      <S.Row>
        <Link to="/empresas/vagas">
          <S.Banner>
            <img src={Home} alt="" />
            Início
          </S.Banner>
        </Link>
        <S.Banner>
          <img src={Finalizados} alt="" />
          Finalizados
        </S.Banner>
      </S.Row>

      <S.Title>Vagas</S.Title>
      {processos?.map((item) => (
        <VagaCard processo={item} candidateText="REABRIR" />
      ))}
    </TemplateEmpresas>
  );
};
