import * as S from './styles';

export const InputFile = ({ id, label, file, ...props }) => {
  return (
    <S.Wrapper>
      <label htmlFor={id}>
        {label}
        {file?.name ? `: ${file?.name}` : ''}
      </label>
      <input type="file" name={id} id={id} {...props} />
    </S.Wrapper>
  );
};
