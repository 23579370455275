import styled from 'styled-components';

export const Title = styled.div`
  margin-top: 38px;
  font-size: 28px;
  color: var(--blue-senac);
  font-weight: 600;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title__text {
    display: flex;
    align-items: center;

    img {
      margin-right: 24px;
    }

    h1 {
      font-size: 28px;
    }
  }

  button {
    width: max-content;
    height: 42px;
    font-size: 20px;
  }
`;

export const Inputs = styled.div`
  * {
    display: grid;
    grid-gap: 10px;
  }

  .first-column__dados-pessoais {
    grid-template-columns: 2fr 1fr;
  }

  .second-column__dados-pessoais {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .third-column__dados-pessoais {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .fourth-column__dados-pessoais {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .image {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    * {
      grid-gap: 0px;
      grid-template-columns: 1fr !important;
    }
  }
`;

export const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 0 16px 0;
  border: 1px solid #d2d2d2;
`;
