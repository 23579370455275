import styled from 'styled-components';

export const Cards = styled.div`
  display: flex;
  width: 100%;

  > div,
  a > div {
    width: max-content;
    margin: 0 24px 0 0;
    display: flex;
    align-items: center;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
      object-fit: contain;
    }
  }

  input {
    width: 300px;
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
      padding: 12px 16px;
      margin-bottom: 24px;
    }
  }
`;

export const TableCard = styled.div`
  margin-top: 24px;
  width: calc(100% - 32px);

  td {
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow: hidden;
    > div {
      overflow: auto;
    }
  }
`;
