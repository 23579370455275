import { useEffect, useState, useCallback } from 'react';
import { BlueButton, Input, Select } from 'components/atoms';
import { cepMask, removeMask } from 'helpers';
import { getEndereco } from './requests';
import { useCadastro } from 'hooks';
import { headers } from 'api';

import * as S from './styles';

import SearchIcon from 'assets/icons/search.svg';
import EnderecoIcon from 'assets/icons/004-endereco.svg';
import { toast } from 'react-toastify';

export const Endereco = ({ id }) => {
  const { endereco, setEndereco } = useCadastro();
  const [initialCep, setInitialCep] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEndereco({
      ...endereco,
      [name]: value,
    });
  };

  const getEnderecoData = async () => {
    const response = await getEndereco(id);
    const data = await response.json();

    setInitialCep(removeMask(data.cep || ''));
    setEndereco({
      endereco_completo: data.logradouro || '',
      cep: data.cep || '',
      numero: data.numero || '',
      complemento: data.complemento || '',
      bairro: data.bairro || '',
      cidade: data.cidade || '',
      tipo_endereco: data.tipo_endereco || '',
    });
  };

  const getCepAddress = useCallback(
    (cep) => {
      if (cep) {
        fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`)
          .then((response) => response.json())
          .then((data) => {
            if (!data?.errors) {
              const { street, neighborhood, city } = data;
              setEndereco({
                ...endereco,
                endereco_completo: street || '',
                bairro: neighborhood || '',
                cidade: city || '',
              });
            } else {
              toast.error('Endereço não localizado!');
            }
          })
          .catch(() => toast.error('Endereço não localizado!'));
      }
    },
    [endereco.cep],
  );

  useEffect(() => {
    getEnderecoData();
  }, []);

  const tipoEnderecoOptions = [
    { value: 'residencial', label: 'Residencial' },
    { value: 'comercial', label: 'Comercial' },
  ];

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={EnderecoIcon} alt="Ícone endereço" />
          <h1>Endereço</h1>
        </div>
      </S.Title>

      <S.Inputs>
        <div className="second-column__endereco">
          <Input
            placeholder="Endereço Completo*"
            name="endereco_completo"
            value={endereco.endereco_completo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="first-column__endereco">
          <Input placeholder="CEP" name="cep" value={cepMask(endereco.cep)} onChange={handleChange} required />
          <BlueButton type="button" onClick={() => getCepAddress(removeMask(endereco.cep))}>
            <img src={SearchIcon} alt="" width="14" height="14" />
          </BlueButton>
          <Input
            placeholder="Número*"
            name="numero"
            value={endereco.numero}
            onChange={handleChange}
            type="number"
            required
          />
          <Input placeholder="Complemento" name="complemento" value={endereco.complemento} onChange={handleChange} />
        </div>
        <div className="third-column__endereco">
          <Input placeholder="Bairro*" name="bairro" value={endereco.bairro} onChange={handleChange} required />
          <Input placeholder="Cidade*" name="cidade" value={endereco.cidade} onChange={handleChange} required />
          <Select
            title="Tipo de endereço*"
            name="tipo_endereco"
            options={tipoEnderecoOptions}
            optionValue={endereco.tipo_endereco}
            actualSelected={endereco.tipo_endereco}
            onChange={handleChange}
            required
          />
        </div>
      </S.Inputs>
    </>
  );
};
