import styled from 'styled-components';

export const Curriculo = styled.div`
  margin-top: 24px;
  width: calc(100% - 32px);

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > div {
      width: 30%;
      margin: 8px;
    }

    > p {
      width: calc(70% - 16px);
    }
  }

  .space-between {
    justify-content: space-between;
  }

  h1 {
    color: var(--orange-senac);
    font-size: 28px;
    text-transform: uppercase;
  }

  h2 {
    color: var(--blue-senac);
    font-size: 18px;
    text-transform: uppercase;
  }

  h3,
  div h3,
  div div h3 {
    color: var(--orange-senac);
    font-size: 18px;
    text-transform: uppercase;
  }

  p {
    color: #656565;
    font-size: 16px;
  }

  a {
    color: #656565;
    font-weight: bold;
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      border-radius: 50%;
      background: var(--blue-senac);
      padding: 8px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #d2d2d2;
  margin: 12px 0;
`;
