import { useState } from 'react';
import { Modal } from 'components/molecules';
import { useAuth } from 'hooks';

import * as S from './styles';

export const AdvertiseNewData = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { user } = useAuth();

  return (
    <S.Wrapper>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title={`Olá, ${user.nome} 🙃`} closeButton>
        <p>Estamos de cara nova!</p>
        <p>Aumente suas chances de ser contratado(a) atualizando o seu cadastro.</p>
        <p>É rapidinho! 😉</p>
        <br />
        <p>Você já atualizou?</p>
      </Modal>
    </S.Wrapper>
  );
};
