import styled from 'styled-components';

export const Cards = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  > div {
    width: max-content;

    label {
      width: max-content;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  input {
    width: 350px;
    padding: 16px;
    max-width: calc(100% - 30px);
  }
`;

export const SendFile = styled.button`
  max-width: 100%;
  width: max-content;
  margin: 32px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;

  > button {
    width: max-content;
    color: #fff;
    font-weight: 600;
    padding-right: 48px;
    padding-left: 48px;
    pointer-events: none;
  }

  > p {
    color: #004b8d;
    font-size: 16px;
    margin: 0 0 0 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Table = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;
`;
