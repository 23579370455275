import { useState, useEffect } from 'react';
import { Input, BlueButton, Switch } from 'components/atoms';
import { monthYearMask } from 'helpers';
import { CardExperienciaProfissional } from 'pages/Alunos/components/CardsCurriculo/CardExperienciaProfissional';
import { useCurriculo } from 'hooks';
import { getExperienciaProfissional, addExperienciaProfissional, editExperienciaProfissional } from './requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import ExperienciaIcon from 'assets/icons/003-suitcase.svg';

const ExperienciaProfissional = () => {
  const {
    experienciaProfissional,
    setExperienciaProfissional,
    preExperienciaProfissional,
    setPreExperienciaProfissional,
    semExperienciaProfissional,
    setSemExperienciaProfissional,
    setNoExperienceFiltered,
  } = useCurriculo();
  const preExperienciaProfissionalInitialState = {
    funcao: '',
    empresa: '',
    data_inicio: '',
    data_fim: '',
    nome_referencia: '',
    telefone_fixo: '',
    telefone_celular: '',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreExperienciaProfissional({
      ...preExperienciaProfissional,
      [name]: value,
    });
  };

  const addMoreExperienciaProfissional = async () => {
    const newData = {
      ...preExperienciaProfissional,
      data_inicio: `01/${preExperienciaProfissional.data_inicio}`,
      data_fim: preExperienciaProfissional.data_fim.length > 0 ? `01/${preExperienciaProfissional.data_fim}` : null,
    };
    const response = await addExperienciaProfissional(newData);

    if (response.status === 200) {
      const data = await response.json();
      setExperienciaProfissional([...experienciaProfissional, data]);
      setPreExperienciaProfissional(preExperienciaProfissionalInitialState);
      toast.success('Experiência profissional adicionada com sucesso!');
    } else {
      toast.error('Erro ao adicionar experiência profissional.');
    }
  };

  const confirmEditExperienciaProfissional = async () => {
    const response = await editExperienciaProfissional(preExperienciaProfissional);

    if (response.status === 200) {
      const data = await response.json();
      setExperienciaProfissional(experienciaProfissional.map((item) => (item.id === data.id ? data : item)));
      setPreExperienciaProfissional(preExperienciaProfissionalInitialState);
      toast.success('Formação acadêmica editada com sucesso!');
    } else {
      toast.error('Erro ao editar formação acadêmica');
    }
  };

  const getExperienciaProfissionalData = async () => {
    const response = await getExperienciaProfissional();
    const data = await response.json();
    const noExperienceFiltered = data.filter((item) => item.funcao === '1º emprego/sem experiência');
    setSemExperienciaProfissional(noExperienceFiltered?.length > 0);
    setNoExperienceFiltered(noExperienceFiltered);
    const dataFiltered = data.filter((item) => item.funcao !== '1º emprego/sem experiência');
    setExperienciaProfissional(dataFiltered);
  };

  useEffect(() => {
    getExperienciaProfissionalData();
  }, []);

  const checkExperienciaProfissional = () => {
    if (preExperienciaProfissional.funcao === '') {
      toast.error('Preencha o campo Função.');
    } else if (preExperienciaProfissional.empresa === '') {
      toast.error('Preencha o campo Empresa.');
    } else if (preExperienciaProfissional.data_inicio === '') {
      toast.error('Preencha o campo Data de início.');
    } else {
      addMoreExperienciaProfissional();
    }
  };

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={ExperienciaIcon} alt="Ícone de experiência" />
          <h1>Experiência profissional</h1>
        </div>

        <BlueButton
          type="button"
          onClick={() => checkExperienciaProfissional()}
          disabled={experienciaProfissional.some((item) => item.editing)}
        >
          +
        </BlueButton>
      </S.Title>

      <S.Inputs>
        <div className="first-column__experiencia-profissional">
          <Input placeholder="Função" name="funcao" value={preExperienciaProfissional.funcao} onChange={handleChange} />
        </div>
        <div className="second-column__experiencia-profissional">
          <Input
            placeholder="Empresa"
            name="empresa"
            value={preExperienciaProfissional.empresa}
            onChange={handleChange}
          />
        </div>
        <div className="third-column__experiencia-profissional">
          <Input
            placeholder="Mês/Ano de início (Ex. 01/2022)"
            name="data_inicio"
            value={monthYearMask(preExperienciaProfissional.data_inicio)}
            onChange={handleChange}
          />
          <Input
            placeholder="Mês/Ano de fim (Ex. 01/2022)"
            name="data_fim"
            value={monthYearMask(preExperienciaProfissional.data_fim)}
            onChange={handleChange}
          />
        </div>

        <BlueButton
          type="button"
          className={!experienciaProfissional.some((item) => item.editing) && 'is-editing'}
          onClick={() => confirmEditExperienciaProfissional()}
        >
          Confirmar edição
        </BlueButton>
      </S.Inputs>

      <Switch
        text="1º emprego/sem experiência"
        name="semExperienciaProfissional"
        id="semExperienciaProfissional"
        onChange={() => setSemExperienciaProfissional(!semExperienciaProfissional)}
        checked={semExperienciaProfissional && !experienciaProfissional?.length}
        disabled={experienciaProfissional?.length}
      />

      {experienciaProfissional.map((experiencia, index) => (
        <CardExperienciaProfissional
          key={index}
          id={experiencia.id}
          funcao={experiencia.funcao}
          empresa={experiencia.empresa}
          data_inicio={experiencia.data_inicio}
          data_fim={experiencia.data_fim}
          experienciaProfissional={experienciaProfissional}
          setExperienciaProfissional={setExperienciaProfissional}
          setPreExperienciaProfissional={setPreExperienciaProfissional}
        />
      ))}
    </>
  );
};

export default ExperienciaProfissional;
