import { useEffect, useState } from 'react';
import { baseUrl, headers } from 'api';
import { WhiteButton, BlueButton, Select } from 'components/atoms';
import { Modal } from 'components/molecules';
import { getUnidades } from 'hooks';
import { toast } from 'react-toastify';

import * as S from './styles';

export const ModalEncaminhar = ({ id, isOpen, onClose }) => {
  const [unidades, setUnidades] = useState([]);
  const [unidadeSelected, setUnidadeSelected] = useState('');

  const getInitialData = async () => {
    const response = await getUnidades();
    const units = await response.json();
    const unitsOptions = units.results.map((item) => {
      return {
        label: item.titulo,
        value: item.id,
      };
    });
    setUnidades(unitsOptions);
  };

  const handleSubmit = () => {
    fetch(`${baseUrl}/processo/${id}/encaminhar/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ unidade: parseInt(unidadeSelected) }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Vaga encaminhada com sucesso!');
          onClose();
        } else if (response.status === 400) {
          toast.error('Vaga já encaminhada!');
        } else {
          toast.error('Erro ao encaminhar vaga!');
        }
      })
      .catch(() => {
        toast.error('Erro ao encaminhar vaga!');
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Modal title="Encaminhar vaga" isOpen={isOpen} onClose={onClose}>
      <S.ModalContent>
        <Select
          title="Cidade/Unidade"
          options={unidades}
          onChange={(e) => setUnidadeSelected(e.target.value)}
          actualSelected={unidadeSelected}
        />
        <div className="buttons">
          <WhiteButton onClick={onClose}>Cancelar</WhiteButton>
          <BlueButton onClick={() => handleSubmit()}>Confirmar</BlueButton>
        </div>
      </S.ModalContent>
    </Modal>
  );
};
