import React from 'react';

import * as S from './styles';

export const Switch = ({ text, id, ...props }) => {
  return (
    <S.Wrapper>
      <input type="checkbox" id={`switch-${id}`} {...props} />
      <label htmlFor={`switch-${id}`}></label>
      <p>{text}</p>
    </S.Wrapper>
  );
};
