import styled from 'styled-components';

export const Banners = styled.div`
  display: flex;
  width: 100%;
  margin: 24px 0;
  align-items: center;

  > a {
    width: max-content;
    margin: 8px;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 8px 0;
    border-radius: 16px;
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    background: #656565;
    cursor: pointer;

    &.curriculo {
      background: var(--blue-senac);
    }
    &.inscritos {
      background: var(--orange-senac);
    }

    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
      margin: 8px 8px 8px 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > a {
      width: 100%;
    }
  }
`;
