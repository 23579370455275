import { CadastroProvider } from './Cadastro';
import { CadastroVagaProvider } from './CadastroVaga';

const EmpresasProvider = ({ children }) => {
  return (
    <CadastroProvider>
      <CadastroVagaProvider>{children}</CadastroVagaProvider>
    </CadastroProvider>
  );
};

export default EmpresasProvider;
