import React, { useState, useEffect } from 'react';
import { TemplateEmpresas } from 'components/templates';
import { BlueButton } from 'components/atoms';
import { useCadastroVaga } from 'hooks';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import Vaga from './Vaga';
import Beneficios from './Beneficios';
import { baseUrl, headers } from 'api';
import { editVaga } from './requests';
import { toast } from 'react-toastify';
import { removeMoneyMask } from 'helpers';

import * as S from './styles';

import CadastroVagaIcon from 'assets/icons/novo-processo.svg';
import Home from 'assets/icons/home.svg';

export const CadastroVaga = () => {
  const navigate = useNavigate();
  const { handleSubmit, loading, cadastroVaga } = useCadastroVaga();
  const [searchParams] = useSearchParams();
  const processoId = searchParams.get('processo');
  const processoIdToCopy = searchParams.get('tocopy');
  const [processo, setProcesso] = useState(null);

  const getInitialData = async () => {
    if (processoId || processoIdToCopy) {
      const response = await fetch(`${baseUrl}/processo/${processoId || processoIdToCopy}/`, {
        method: 'GET',
        headers: {
          ...headers,
          Authorization: sessionStorage.getItem('@senac:token'),
        },
      });

      const data = await response.json();
      if (data) {
        if (processoIdToCopy) {
          delete data.status;
          delete data.dias;
          setProcesso({ ...data, data_expiracao: '' });
        } else {
          setProcesso(data);
        }
      }
    }
  };

  const handleSubmitProcesso = async (e) => {
    e.preventDefault();
    const valorMinimo = removeMoneyMask(cadastroVaga.valor_minimo);
    const valorMaximo = removeMoneyMask(cadastroVaga.valor_maximo);

    if (Number.isNaN(valorMinimo) || Number(valorMinimo) <= 0) {
      toast.error('Você precisa preencher o campo de Valor Mínimo.', { toastId: 'valor_vaga' });
      return;
    }
    if (Number.isNaN(valorMaximo) || Number(valorMaximo) <= 0) {
      toast.error('Você precisa preencher o campo de Valor Máximo.', { toastId: 'valor_vaga' });
      return;
    }

    const processoId = searchParams.get('processo');
    if (!processoId) {
      handleSubmit(e);
    } else {
      const response = await editVaga({ cadastroVaga, id: processoId });
      if (response.ok) {
        toast.success('Vaga reaberta com sucesso.');
        navigate('/empresas/vagas');
      }
    }
  };

  useEffect(() => {
    getInitialData();
  }, [searchParams]);

  return (
    <TemplateEmpresas title="PORTAL DA EMPRESA">
      <S.Row>
        <Link to="/empresas/vagas">
          <S.Banner>
            <img src={Home} alt="" />
            Início
          </S.Banner>
        </Link>
        <S.Banner>
          <img src={CadastroVagaIcon} alt="Banner de cadastro de vaga" />
          Cadastro de vaga
        </S.Banner>
      </S.Row>

      <form onSubmit={handleSubmitProcesso}>
        <Vaga processo={processo} />
        <Beneficios processo={processo} />

        <S.Submit>
          <BlueButton type="submit" disabled={loading}>
            Salvar
          </BlueButton>
        </S.Submit>
      </form>
    </TemplateEmpresas>
  );
};
