import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, ButtonOrangeLetter, Input, BlueButton, OrangeButton } from 'components/atoms';
import { Loading } from 'components/molecules';
import { useAuth } from 'hooks';
import { cpfMask, cnpjMask, removeMask } from 'helpers';
import ReCAPTCHA from 'react-google-recaptcha';

import * as S from '../styles';
import SenacLogo from 'assets/images/senac-logo.png';
import Email from 'assets/icons/email.svg';
import Shield from 'assets/icons/shield.svg';
import Contact from 'assets/icons/contact.svg';
import CheckCircle from 'assets/icons/check_circle.svg';

export const Register = ({ changeCard }) => {
  const { register } = useAuth();
  const { pathname } = useLocation();
  const isCompany = pathname.includes('/empresas');
  const [captchaKey, setCaptchaKey] = useState(false);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    nome: '',
    email: '',
    cpf_cnpj: '',
    senha1: '',
    senha2: '',
    tipo_usuario: pathname === '/empresas' ? 2 : 3,
  });

  const registerAndHandleErrors = async () => {
    setLoading(true);
    const response = await register({ ...values, cpf_cnpj: removeMask(values.cpf_cnpj) });
    setLoading(false);

    if (response.status === 200) {
      toast.success('Cadastro realizado com sucesso!');
      changeCard('login');
    } else if (response.status === 400) {
      const data = await response.json();
      if (data.erro) {
        toast.warn(
          <>
            <h3>Acesso não autorizado!</h3>
            <br />
            <p>{data.erro}</p>
            <br />
          </>,
          { className: 'w-560', autoClose: 7000 },
        );
        changeCard('login');
      } else {
        toast.warn(
          <>
            <h3>Acesso não autorizado!</h3>
            <br />
            <p>
              Para proceder com o cadastro ao Banco de Oportunidades do Senac PI como candidato, é necessário ter
              concluído um curso no Senac PI de no mínimo 20h, nos últimos 5 anos.
            </p>
            <br />
            <p>Venha se qualificar conosco!</p>
          </>,
          { className: 'w-560', autoClose: 7000 },
        );
      }
    } else if (response.status === 500) {
      toast.warn(
        <>
          <h3>Acesso não autorizado!</h3>
          <br />
          <p>Falha ao se conectar com a Base de Dados Educacional do Senac PI. Tente novamente mais tarde.</p>
          <br />
        </>,
        { className: 'w-560', autoClose: 7000 },
      );
    } else {
      const responseMessage = await response.json();
      toast.warn(responseMessage.erro);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    registerAndHandleErrors();
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const changeRecaptcha = () => {
    setCaptchaKey(true);
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit}>
        <Card>
          <S.Logo src={SenacLogo} alt="Senac" />
          <S.Title>Banco de Oportunidades</S.Title>
          <Input
            name="nome"
            value={values.nome}
            onChange={handleChange}
            type="text"
            placeholder="Nome"
            icon={Contact}
            required
          />
          <Input
            name="email"
            value={values.email}
            onChange={handleChange}
            type="email"
            placeholder="E-mail"
            icon={Email}
            required
          />
          <Input
            name="cpf_cnpj"
            value={isCompany ? cnpjMask(values.cpf_cnpj) : cpfMask(values.cpf_cnpj)}
            onChange={handleChange}
            type="text"
            placeholder={isCompany ? 'CNPJ/CPF' : 'CPF'}
            icon={Contact}
            required
          />
          <Input
            name="senha1"
            value={values.senha1}
            onChange={handleChange}
            type="password"
            placeholder="Senha"
            icon={Shield}
            required
          />
          <Input
            name="senha2"
            value={values.senha2}
            onChange={handleChange}
            type="password"
            placeholder="Confirme sua senha"
            icon={CheckCircle}
            required
          />

          <ReCAPTCHA
            sitekey="6LddpdshAAAAAB9RkTyByPLIH9o9on9qqff5-Txi"
            badge="bottomright"
            onChange={changeRecaptcha}
          />
          {isCompany ? (
            <OrangeButton
              disabled={loading || !captchaKey}
              type="submit"
              style={{ margin: '16px 0', color: '#fff', fontWeight: 'bold' }}
            >
              Registrar
            </OrangeButton>
          ) : (
            <BlueButton disabled={loading || !captchaKey} type="submit" style={{ margin: '16px 0' }}>
              Registrar
            </BlueButton>
          )}
          <S.Text>
            Já possui conta? <ButtonOrangeLetter onClick={() => changeCard('login')}>Clique aqui</ButtonOrangeLetter>
          </S.Text>
        </Card>
      </S.Form>

      {loading && !isCompany ? <Loading text="Verificando cadastro no banco de dados do SENAC!" /> : null}
    </>
  );
};
