import styled from 'styled-components';

export const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > h1 {
    width: 100%;
  }

  > div {
    width: calc(25% - 24px);
    margin: 0 24px 0 0;

    p {
      font-size: 14px;
      color: var(--orange-senac);
      font-weight: bold;
      text-transform: uppercase;
    }

    span {
      font-size: 24px;
      color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      width: calc(50% - 4px);
      margin: 16px 0 0 0;
    }
  }
`;

export const Graphs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contratacoes {
    width: calc(60% - 48px);
    margin: 24px 24px 24px 0;

    p {
      font-size: 16px;
      color: var(--blue-senac);
      margin: 0 0 8px 0;
      font-weight: bold;
    }

    &__select {
      width: max-content;
      select {
        width: max-content;
      }
    }

    .apexcharts-canvas {
      cursor: pointer;
    }
  }

  .status {
    width: 40%;
    margin: 24px 24px 24px 0;
    background-color: var(--orange-senac);

    p {
      font-size: 16px;
      color: #fff;
      margin: 0 0 8px 0;
      font-weight: bold;
    }

    &__select {
      width: max-content;
      select {
        width: max-content;
        background: var(--orange-senac);
        color: #fff;
        border-color: #fff;
        font-weight: bold;
        padding: 12px 8px;
      }
    }

    &__dados {
      span {
        font-size: 28px;
        color: #fff;
      }
      p {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .ranking {
    width: calc(60% - 48px);
    margin: 24px 24px 24px 0;

    p {
      font-size: 16px;
      color: var(--blue-senac);
      margin: 0 0 8px 0;
      font-weight: bold;
    }

    &__select {
      width: max-content;
      select {
        width: max-content;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .contratacoes,
    .ranking {
      width: 100%;
      margin: 24px 0;
    }
    .status {
      width: 100%;
      margin: 24px 0;
      position: relative;
      &__dados {
        position: absolute;
        right: 10px;
        bottom: 0;
      }
    }

    .ranking {
      overflow-x: auto;
      > .row {
        position: sticky;
        left: 0;
        top: 0;
      }
      > table {
        width: 500px;
        overflow: hidden;
      }
    }
  }
`;
