import { baseUrl, headers } from 'api';
import { removeMask } from 'helpers';
import { toast } from 'react-toastify';

export const saveCurriculo = async ({ dados, endereco, resumoProfissional, complemento, isAdmin, id }) => {
  if (isAdmin) {
    const response = await Promise.all([saveDadosPessoais(dados, id, isAdmin), saveEndereco(endereco, id, isAdmin)]);

    return response;
  } else {
    const response = await Promise.all([
      saveDadosPessoais(dados),
      saveEndereco(endereco),
      saveResumoProfissional(resumoProfissional),
      saveComplementos(complemento),
    ]);

    return response;
  }
};

export const saveDadosPessoais = async (dados, id, isAdmin) => {
  if (dados.nome === '' && !isAdmin) {
    toast.error('Preencha o nome!');
  } else if (dados.cpf_cnpj === '' && !isAdmin) {
    toast.error('Preencha o CPF/CNPJ!' && !isAdmin);
  } else if (dados.celular === '') {
    toast.error('Preencha o celular!' && !isAdmin);
  } else if (dados.email === '') {
    toast.error('Preencha o e-mail!' && !isAdmin);
  } else if (dados.data_nascimento === '') {
    toast.error('Preencha a data de nascimento!' && !isAdmin);
  } else {
    const response = await fetch(`${baseUrl}/curriculo/dados/${id ? '?usuario=' + id : ''}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({
        ...dados,
        cpf_cnpj: removeMask(dados.cpf_cnpj),
        telefone: removeMask(dados.telefone),
        celular: removeMask(dados.celular),
      }),
    });
    if (response.ok) toast.success('Dados salvos com sucesso!');
    return response;
  }
};

export const saveProfilePhoto = async (image) => {
  const response = await fetch(`${baseUrl}/usuarios/upload-image/`, {
    method: 'POST',
    headers: {
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: image,
  });

  return response;
};

export const saveEndereco = async (endereco, id, isAdmin) => {
  if (endereco.cep === '' && !isAdmin) {
    toast.error('Preencha o CEP!');
  } else if (endereco.tipo_endereco === '' && !isAdmin) {
    toast.error('Preencha o tipo de endereço!');
  } else if (endereco.logradouro === '' && !isAdmin) {
    toast.error('Preencha o logradouro!');
  } else if (endereco.numero === '' && !isAdmin) {
    toast.error('Preencha o número!');
  } else if (endereco.bairro === '' && !isAdmin) {
    toast.error('Preencha o bairro!');
  } else if (endereco.cidade === '' && !isAdmin) {
    toast.error('Preencha a cidade!');
  } else {
    const response = await fetch(`${baseUrl}/curriculo/endereco/${id ? '?usuario=' + id : ''}`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({
        ...endereco,
        cep: removeMask(endereco.cep),
      }),
    });

    if (response.ok) toast.success('Endereço salvo com sucesso!');

    return response;
  }
};

export const saveResumoProfissional = async (resumoProfissional) => {
  if (resumoProfissional.resumo === '') {
    toast.error('Preencha o resumo!');
  } else {
    const response = await fetch(`${baseUrl}/curriculo/resumo-profissional/`, {
      method: 'PATCH',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ ...resumoProfissional }),
    });

    return response;
  }
};

export const saveComplementos = async (complemento) => {
  const response = await fetch(`${baseUrl}/curriculo/complemento/`, {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...complemento }),
  });

  return response;
};
