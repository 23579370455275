import { deleteCursos } from 'pages/Alunos/VagasCurriculo/Cursos/requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import Trash from 'assets/icons/trash.svg';
import Pen from 'assets/icons/pen.svg';

export const CardCursos = ({
  id,
  nome,
  observacao,
  data_inicio,
  data_fim,
  carga_horaria,
  cursos,
  setCursos,
  setPreCursos,
}) => {
  const checkIfAlreadyEditing = () => {
    const cursosIndex = cursos.findIndex((item) => item.editing);
    return cursosIndex > -1 ? true : false;
  };

  const deleteItem = async (id) => {
    if (checkIfAlreadyEditing()) {
      alert('Termine a edição antes de deletar.');
    } else {
      const cursosIndex = cursos.findIndex((item) => item.id === id);
      const response = await deleteCursos(cursos[cursosIndex]);

      if (response.status === 200) {
        const data = await response.json();
        setCursos(cursos.filter((item) => item.id !== data.id));
        toast.success('Curso deletado com sucesso!');
      } else {
        toast.error('Erro ao deletar curso.');
      }
    }
  };

  const editItem = (id) => {
    if (checkIfAlreadyEditing()) {
      alert('Termine a edição antes de editar.');
    } else {
      const cursosIndex = cursos.findIndex((item) => item.id === id);
      const newCursos = [...cursos];

      newCursos[cursosIndex] = {
        ...newCursos[cursosIndex],
        editing: true,
      };

      setCursos(newCursos);
      setPreCursos(newCursos[cursosIndex]);
    }
  };

  return (
    <S.Card>
      <S.Text>
        <S.Title>{`${nome} - ${observacao || ''}`}</S.Title>
        {data_inicio && <S.Description>{`Período: ${data_inicio} à ${data_fim}`}</S.Description>}
        <S.Description>{`Carga horária: ${carga_horaria}`}</S.Description>
      </S.Text>

      <S.ActionButtons>
        <S.ActionButtonDelete type="button" onClick={() => deleteItem(id)}>
          <img src={Trash} alt="Ícone de lixeira" />
        </S.ActionButtonDelete>

        <S.ActionButtonEdit type="button" onClick={() => editItem(id)}>
          <img src={Pen} alt="Ícone de lápis" />
        </S.ActionButtonEdit>
      </S.ActionButtons>
    </S.Card>
  );
};
