import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Switch, BlueButton } from 'components/atoms';
import { Modal, Loading } from 'components/molecules';
import { TemplateAdmin, TemplateCurriculum } from 'components/templates';
import { useProcessos, useAuth } from 'hooks';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { PrintCurriculo } from 'pages/Empresas/CurriculoPorVaga/PrintCurriculo';

import User from 'assets/icons/user.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';
import ForwardIcon from 'assets/icons/forward-icon.svg';
import ExportFile from 'assets/icons/export-file.svg';

import * as S from './styles';

export const CurriculoAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [values, setValues] = useState([]);
  const [vagasAluno, setVagasAluno] = useState(false);
  const { curriculo, getCurriculo } = useProcessos();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openCurriculo, setOpenCurriculo] = useState(false);
  const [alunoSelected, setAlunoSelected] = useState(null);

  const handleChangeSwitch = (vagaId) => {
    const hasId = values.includes(vagaId);
    if (!hasId) {
      setValues([...values, vagaId]);
    } else {
      const newValues = values.filter((vaga) => vaga !== vagaId);
      setValues(newValues);
    }
  };

  const submitCurriculum = async () => {
    const body = values.map((vagaId) => {
      return {
        usuario: id,
        processo: vagaId,
        obs: '',
        requisitos: true,
        ciente: true,
      };
    });
    const res = await fetch(`${baseUrl}/dashboard/inscrever-vaga/?usuario=${id}`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify(body),
    });
    if (res.status === 200) {
      toast.success('Currículo encaminhado com sucesso!');
      setModalOpen(false);
    } else {
      toast.error('Erro ao encaminhar currículo!');
    }
  };

  const getInitialData = async () => {
    setIsLoading(true);
    await getCurriculo(id);
    setIsLoading(false);
    const resVagas = await fetch(`${baseUrl}/dashboard/vagas-aluno/?usuario=${id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    const dataVagas = await resVagas.json();
    setVagasAluno(dataVagas);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <S.PrintArea>
      <TemplateAdmin page="Dashboard" className="no-print">
        <S.Cards>
          <Card blue>
            <img src={User} alt="" />
            <p>Alunos</p>
          </Card>

          <S.GoBack onClick={() => navigate(-1)}>
            <img src={ArrowLeft} alt="" />
            Voltar
          </S.GoBack>
        </S.Cards>

        <Card
          blue
          className="button"
          onClick={async () => {
            setIsLoading(true);
            const curriculo = await getCurriculo(id);
            setIsLoading(false);
            setOpenCurriculo(true);
            setAlunoSelected(curriculo);
          }}
        >
          <img src={ExportFile} alt="Baixar CSV" />
          Imprimir
        </Card>

        <TemplateCurriculum curriculo={curriculo} />

        <S.FollowUp onClick={() => setModalOpen(true)}>
          <img src={ForwardIcon} alt="" />
          <p>Encaminhar Currículo</p>
        </S.FollowUp>
      </TemplateAdmin>

      <Modal title="Encaminhar Curriculo para Vagas" onClose={() => setModalOpen(false)} isOpen={modalOpen} closeButton>
        <S.ModalContent>
          {vagasAluno?.results?.map((vaga) => (
            <div className="row">
              <Card className="card">
                <p>{vaga?.nome_vaga}</p>
                <div className="row space-between">
                  <span>{vaga?.usuario?.nome}</span>
                  <span>{vaga?.cidade?.nome}</span>
                </div>
              </Card>

              <div className="switch">
                <Switch id={vaga.id} onChange={() => handleChangeSwitch(vaga.id)} checked={values.includes(vaga.id)} />
              </div>
            </div>
          ))}

          <BlueButton onClick={() => submitCurriculum()}>Enviar</BlueButton>
        </S.ModalContent>
      </Modal>

      {isLoading ? <Loading text="Carregando dados..." /> : null}
      {alunoSelected && openCurriculo ? <PrintCurriculo curriculo={alunoSelected} className="print-area" /> : null}
    </S.PrintArea>
  );
};
