import { Link, useNavigate } from 'react-router-dom';
import * as S from './styles';

import CadastroVaga from 'assets/icons/novo-processo.svg';
import Andamento from 'assets/icons/andamento.svg';
import Finalizados from 'assets/icons/user-finalizados.svg';
import Contratados from 'assets/icons/contratados.svg';
import Curriculo from 'assets/icons/curriculo.svg';
import { useAuth } from 'hooks';
import { toast } from 'react-toastify';
import { getCadastroEmpresa } from 'pages/Empresas/CadastroEmpresa/requests';
import { getEndereco } from 'pages/Empresas/CadastroEmpresa/Endereco/requests';
import { getEndereco as getContato } from 'pages/Empresas/CadastroEmpresa/Contato/requests';

export const NavigationEmpresas = () => {
  const navigate = useNavigate();

  async function verifyData() {
    const empresa = await getCadastroEmpresa();
    const empresaData = await empresa.json();
    const endereco = await getEndereco();
    const enderecoData = await endereco.json();
    const contato = await getContato();
    const contatoData = await contato.json();

    if (
      !empresaData?.cpf_cnpj ||
      !empresaData?.razao_social ||
      !empresaData?.nome_fantasia ||
      !empresaData?.email ||
      !empresaData?.celular ||
      !empresaData?.tipo_empresa ||
      !enderecoData?.logradouro ||
      !enderecoData?.cep ||
      !enderecoData?.numero ||
      !enderecoData?.bairro ||
      !enderecoData?.cidade ||
      !enderecoData?.tipo_endereco ||
      !contatoData?.nome ||
      !contatoData?.telefone
    ) {
      toast.warning(
        'Olá, para cadastrar vaga na nossa plataforma, você precisa preencher todos dados da empresa em seu perfil.',
      );
      return;
    }
    navigate('/empresas/vagas/cadastro');
  }

  return (
    <S.Banners>
      <a onClick={verifyData}>
        <div className="cadastro">
          <img src={CadastroVaga} alt="Ícone de cadastro de vagas" />
          Cadastrar vagas
        </div>
      </a>
      <Link to="/empresas/vagas/andamento">
        <div className="andamento">
          <img src={Andamento} alt="Ícone de vagas em andamento" />
          Ativas
        </div>
      </Link>
      <Link to="/empresas/vagas/finalizadas">
        <div className="finalizadas">
          <img src={Finalizados} alt="Ícone de vagas em finalizadas" />
          Finalizados
        </div>
      </Link>
      <Link to="/empresas/vagas/contratados">
        <div className="contratados">
          <img src={Contratados} alt="Ícone de contratados" />
          Contratados
        </div>
      </Link>
      <Link to="/empresas/vagas/curriculos">
        <div className="curriculos">
          <img src={Curriculo} alt="Ícone de currículos" />
          Currículo
        </div>
      </Link>
    </S.Banners>
  );
};
