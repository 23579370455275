import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex !important;
  align-items: center;
  grid-gap: 0px !important;
  margin-bottom: 10px;

  input {
    height: 0px;
    width: 0px;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 21px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-right: 12px;
  }

  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 17px;
    height: 16px;
    background: white;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #bada55;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 23px;
  }
`;
