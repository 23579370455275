import { useState, useEffect } from 'react';
import { Input, Select } from 'components/atoms';
import { useCadastroVaga, getCidades } from 'hooks';
import { moneyMask, dateMask } from 'helpers';
import { useSearchParams } from 'react-router-dom';
import { getAreaInteresseSelect } from './requests';

import * as S from './styles';

const Vaga = ({ processo }) => {
  const { cadastroVaga, setCadastroVaga, turnos, setTurnos, horarios, setHorarios, isAdmin } = useCadastroVaga();
  const [cidadesOptions, setCidadesOptions] = useState([]);
  const [isReopen, setIsReopen] = useState(false);
  const [searchParams] = useSearchParams();
  const [areaInteresse, setAreaInteresse] = useState([]);
  const [preAreaInteresse, setPreAreaInteresse] = useState([]);

  const escolaridadeOptions = [
    {
      label: 'Ensino Fundamental incompleto',
      value: 'Ensino Fundamental incompleto',
    },
    {
      label: 'Ensino Fundamental completo',
      value: 'Ensino Fundamental completo',
    },
    {
      label: 'Ensino Médio incompleto',
      value: 'Ensino Médio incompleto',
    },
    {
      label: 'Ensino Médio completo',
      value: 'Ensino Médio completo',
    },
    {
      label: 'Técnico incompleto',
      value: 'Técnico incompleto',
    },
    {
      label: 'Técnico completo',
      value: 'Técnico completo',
    },
    {
      label: 'Ensino Superior incompleto',
      value: 'Ensino Superior incompleto',
    },
    {
      label: 'Ensino Superior completo',
      value: 'Ensino Superior completo',
    },
  ];

  const horariosOptions = [
    {
      label: 'Segunda à Sexta',
      value: 'segunda-sexta',
    },
    {
      label: 'Segunda à Sábado',
      value: 'segunda-sabado',
    },
    {
      label: 'Horário Específico',
      value: 'especifico',
    },
  ];

  const getInitialData = async () => {
    const cities = await getCidades();
    const citiesData = await cities.json();
    const citiesOptions = citiesData.map((city) => {
      return {
        label: city.nome,
        value: city.id,
      };
    });
    setCidadesOptions(citiesOptions);
  };

  const getAreaInteresseSelectData = async () => {
    const response = await getAreaInteresseSelect();
    const data = await response.json();

    setAreaInteresse([
      ...data.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.nome,
      })),
    ]);
  };

  const handleChange = (e, isMoney) => {
    const { name, value } = e.target;

    if (isMoney) {
      setCadastroVaga({
        ...cadastroVaga,
        [name]: moneyMask(value),
      });
    } else {
      setCadastroVaga({
        ...cadastroVaga,
        [name]: value,
      });
    }
  };

  const handleChangeCheckBox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPreAreaInteresse([...preAreaInteresse, parseInt(value)]);
    } else {
      const removedAreaInteresse = preAreaInteresse.filter((item) => item !== parseInt(value));
      setPreAreaInteresse(removedAreaInteresse);
    }
  };

  const handleChangeHorario = (e) => {
    const { value } = e.target;
    setHorarios(value);
  };

  const handleChangeTurnos = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setTurnos([...turnos, value]);
    } else {
      const removedTurnos = turnos.filter((item) => item !== value);
      setTurnos(removedTurnos);
    }
  };

  useEffect(() => {
    if (processo) {
      const isNew = !!searchParams.get('processo');
      setIsReopen(isNew);
      const areas_interesses = processo?.areas_interesses?.map((item) => {
        return item.id;
      });
      setCadastroVaga({
        ...processo,
        cidade: processo?.cidade_vaga,
        cidade_vaga: processo?.cidade_vaga,
        usuario: processo?.usuario.id,
        areas_interesses,
        // valor_salario: moneyMask(processo?.valor_salario),
        valor_minimo: moneyMask(processo?.valor_minimo),
        valor_maximo: moneyMask(processo?.valor_maximo),
        valor_transporte: moneyMask(processo.valor_transporte),
        valor_alimentacao: moneyMask(processo.valor_alimentacao),
      });
      setPreAreaInteresse(areas_interesses);
      if (processo?.turno) {
        setTurnos(processo?.turno.split(','));
      }
      if (processo?.horario_comercial) {
        setHorarios(processo?.horario_comercial);
      }
    }
  }, [processo]);

  useEffect(() => {
    setCadastroVaga({ ...cadastroVaga, areas_interesses: preAreaInteresse });
  }, [preAreaInteresse]);

  useEffect(() => {
    getAreaInteresseSelectData();
    getInitialData();
  }, []);

  const tipoVagaOptions = [
    { value: 'CLT', label: 'CLT' },
    { value: 'MEI', label: 'MEI' },
    { value: 'Estágio', label: 'Estágio' },
    { value: 'Jovem Aprendiz', label: 'Jovem Aprendiz' },
    { value: 'Temporário', label: 'Temporário' },
  ];

  return (
    <>
      <S.Title>Vaga</S.Title>

      <S.Inputs>
        <div className="first-column__vaga">
          <Input
            placeholder="Nome da vaga*"
            name="nome_vaga"
            value={cadastroVaga?.nome_vaga}
            onChange={handleChange}
            disabled={isReopen}
          />
          <Input
            placeholder="Data de expiração da vaga*"
            name="data_expiracao"
            value={dateMask(cadastroVaga?.data_expiracao)}
            onChange={handleChange}
          />
        </div>
        <div className="second-column__vaga">
          <Input
            placeholder="Quantidade de vagas*"
            name="qtd_vagas"
            value={cadastroVaga?.qtd_vagas}
            onChange={handleChange}
            type="number"
          />
          <Input
            placeholder="Salário Mínimo*"
            name="valor_minimo"
            value={moneyMask(cadastroVaga?.valor_minimo)}
            onChange={(e) => handleChange(e, true)}
          />
          <Input
            placeholder="Salário Máximo*"
            name="valor_maximo"
            value={moneyMask(cadastroVaga?.valor_maximo)}
            onChange={(e) => handleChange(e, true)}
          />
        </div>
        <div className="third-column__vaga">
          <Select
            title="Cidade*"
            name="cidade"
            options={cidadesOptions}
            actualSelected={cadastroVaga?.cidade}
            onChange={handleChange}
          />
          <Select
            title="Tipo de vaga*"
            name="tipo_vaga"
            options={tipoVagaOptions}
            onChange={handleChange}
            actualSelected={cadastroVaga?.tipo_vaga}
          />
          <Select
            title="Escolaridade*"
            name="escolaridade"
            actualSelected={cadastroVaga?.escolaridade}
            onChange={handleChange}
            options={escolaridadeOptions}
          />
        </div>
        <div className="fourth-column__vaga">
          <Input
            placeholder="Atividades a serem executadas*"
            name="atividade"
            value={cadastroVaga?.atividade}
            onChange={handleChange}
            maxLength="500"
          />
          <Input
            placeholder="Requisitos da vaga*"
            name="observacao"
            value={cadastroVaga?.observacao}
            onChange={handleChange}
            maxLength="500"
          />
        </div>

        <S.Title>Turnos</S.Title>
        <div className="turnos">
          <div>
            <S.InputCheckbox
              name="turnos"
              type="checkbox"
              value="Manhã"
              id="manha"
              onChange={handleChangeTurnos}
              checked={turnos?.some((area) => area.includes('Manhã'))}
            />
            <S.Label htmlFor="manha">Manhã</S.Label>
          </div>
          <div>
            <S.InputCheckbox
              name="turnos"
              type="checkbox"
              value="Tarde"
              id="tarde"
              onChange={handleChangeTurnos}
              checked={turnos?.some((area) => area.includes('Tarde'))}
            />
            <S.Label htmlFor="tarde">Tarde</S.Label>
          </div>
          <div>
            <S.InputCheckbox
              name="turnos"
              type="checkbox"
              value="Noite"
              id="noite"
              onChange={handleChangeTurnos}
              checked={turnos?.some((area) => area.includes('Noite'))}
            />
            <S.Label htmlFor="noite">Noite</S.Label>
          </div>
        </div>

        <S.Title>Horários</S.Title>
        <div className="horarios">
          <Select
            title="Escolha o horário*"
            name="horario"
            actualSelected={horarios}
            onChange={handleChangeHorario}
            options={horariosOptions}
          />
          {horarios === 'especifico' ? (
            <>
              <Input
                placeholder="Horário Específico*"
                name="horario_especifico"
                value={cadastroVaga?.horario_especifico}
                onChange={handleChange}
              />
              <Input
                placeholder="Obeservação sobre o horário"
                name="horario_observacao"
                value={cadastroVaga?.horario_observacao}
                onChange={handleChange}
              />
            </>
          ) : null}
        </div>

        {isAdmin && (
          <>
            <S.Title>Áreas de Interesse</S.Title>
            <div className="seventh-column__vaga">
              {areaInteresse.map((item) => (
                <S.Inputs key={item.id} style={{ display: 'flex' }}>
                  <S.InputCheckbox
                    name="areaInteresse"
                    type="checkbox"
                    value={item.id}
                    id={item.id}
                    onChange={handleChangeCheckBox}
                    checked={preAreaInteresse?.some((area) => area === item.id)}
                  />
                  <S.Label htmlFor={item.id}>{item.label}</S.Label>
                </S.Inputs>
              ))}
            </div>
          </>
        )}
      </S.Inputs>
    </>
  );
};

export default Vaga;
