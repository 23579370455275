import styled from 'styled-components';
import { BlueButton } from 'components/atoms';

export const Title = styled.h1`
  font-size: 28px;
  color: #656565;
  font-weight: 600;
  padding: 24px 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #d2d2d2;
`;

export const LoadMore = styled(BlueButton)`
  width: max-content;
  display: flex;
  margin: 16px auto;
`;
