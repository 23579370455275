import { useState } from 'react';
import { TemplateAlunos } from 'components/templates';
import { BlueButton } from 'components/atoms';
import { useAuth, useCurriculo, useProcessos } from 'hooks';
import { Link } from 'react-router-dom';

import DadosPessoais from './DadosPessoais';
import Endereco from './Endereco';
import ResumoProfissional from './ResumoProfissional';
import AreaInteresse from './AreaInteresse';
import FormacaoAcademica from './FormacaoAcademica';
import ExperienciaProfissional from './ExperienciaProfissional';
import Cursos from './Cursos';
import Idiomas from './Idiomas';
import Complementos from './Complementos';

import * as S from './styles';

import Curriculo from 'assets/icons/curriculo.svg';
import Home from 'assets/icons/home.svg';
import ExportFile from 'assets/icons/export-file.svg';
import { PrintCurriculo } from 'pages/Empresas/CurriculoPorVaga/PrintCurriculo';

export const VagasCurriculo = () => {
  const { loading, handleSubmit } = useCurriculo();
  const { getCurriculo } = useProcessos();
  const { user } = useAuth();
  const [openCurriculo, setOpenCurriculo] = useState(false);
  const [alunoSelected, setAlunoSelected] = useState(null);

  return (
    <S.PrintArea>
      <TemplateAlunos title="PORTAL DO CANDIDATO" className="no-print">
        <S.Row>
          <Link to="/alunos/vagas">
            <S.Banner>
              <img src={Home} alt="" />
              Início
            </S.Banner>
          </Link>
          <S.Banner>
            <img src={Curriculo} alt="" />
            Currículo
          </S.Banner>
          <S.Banner
            className="button"
            onClick={async () => {
              const curriculo = await getCurriculo(user.id);
              setOpenCurriculo(true);
              setAlunoSelected(curriculo);
            }}
          >
            <img src={ExportFile} alt="Baixar CSV" />
            Baixar
          </S.Banner>
        </S.Row>

        <form onSubmit={handleSubmit}>
          <DadosPessoais />
          <Endereco />
          <ResumoProfissional />
          <AreaInteresse />
          <FormacaoAcademica />
          <ExperienciaProfissional />
          <Cursos />
          <Idiomas />
          <Complementos />

          <S.Submit>
            <BlueButton disabled={loading} type="submit">
              Salvar
            </BlueButton>
          </S.Submit>
        </form>
      </TemplateAlunos>

      {alunoSelected && openCurriculo ? <PrintCurriculo curriculo={alunoSelected} className="print-area" /> : null}
    </S.PrintArea>
  );
};
