import { useParams, useNavigate } from 'react-router-dom';
import { BlueButton } from 'components/atoms';
import { TemplateAdmin } from 'components/templates';
import { useCurriculo } from 'hooks';
import DadosPessoais from 'pages/Alunos/VagasCurriculo/DadosPessoais';
import Endereco from 'pages/Alunos/VagasCurriculo/Endereco';

import * as S from 'pages/Alunos/VagasCurriculo/styles';
import * as Style from './styles';

import Curriculo from 'assets/icons/curriculo.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';

export const EditCurriculo = () => {
  const { handleSubmit, loading } = useCurriculo();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <TemplateAdmin page="Dashboard">
      <S.Banner>
        <img src={Curriculo} alt="" />
        Currículo
      </S.Banner>
      <Style.Row className="row space-between">
        <h1 />
        <a onClick={() => navigate(-1)}>
          <img src={ArrowLeft} alt="" />
          Voltar
        </a>
      </Style.Row>

      <form onSubmit={(e) => handleSubmit(e, id)}>
        <DadosPessoais id={id} />
        <Endereco id={id} />

        <S.Submit>
          <BlueButton disabled={loading} type="submit">
            Salvar
          </BlueButton>
        </S.Submit>
      </form>
    </TemplateAdmin>
  );
};
