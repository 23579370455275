export const dateMask = (value) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .substring(0, 10);
};

export const monthYearMask = (value) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1/$2')
    .substring(0, 7);
};

export const timeMask = (value) => {
  if (!value) return null;

  return value
    .replace(/\D/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1:$2')
    .substring(0, 5);
};
