import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

export const GraficoContratacoes = ({ dataContratacoes, dataEncaminhamentos }) => {
  const [series, setSeries] = useState([
    {
      name: 'Contratações',
      data: dataContratacoes,
    },
    {
      name: 'Encaminhamentos',
      data: dataEncaminhamentos,
    },
  ]);
  const options = {
    chart: {
      type: 'line',
      height: 230,
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth' },
    colors: ['#F68B1F', '#D2D2D2'],
    xaxis: {
      categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    },
    yaxis: {},
    legend: { horizontalAlign: 'left' },
  };

  useEffect(() => {
    setSeries([
      {
        name: 'Contratações',
        data: dataContratacoes,
      },
      {
        name: 'Encaminhamentos',
        data: dataEncaminhamentos,
      },
    ]);
  }, [dataContratacoes, dataEncaminhamentos]);

  return <Chart options={options} series={series} type="line" height={230} />;
};
