import styled from 'styled-components';

export const Content = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  thead {
    tr {
      th {
        text-align: left;
        font-size: 14px;
        color: var(--orange-senac);
        width: 33.3%;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: #efefef;
      }

      td {
        width: 33.3%;
        max-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px;
      }
    }
  }
`;
