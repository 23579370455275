import { baseUrl, headers } from 'api';
import { WhiteButton, BlueButton } from 'components/atoms';
import { Modal } from 'components/molecules';
import { toast } from 'react-toastify';

import * as S from './styles';

export const ModalAtivar = ({ id, isOpen, onClose }) => {
  const handleSubmit = () => {
    fetch(`${baseUrl}/processo/${id}/ativar/`, {
      method: 'GET',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Vaga ativada com sucesso!');
          onClose();
        } else if (response.status === 400) {
          toast.error('Vaga já ativada!');
        } else {
          toast.error('Erro ao ativar vaga!');
        }
      })
      .catch(() => {
        toast.error('Erro ao ativar vaga!');
      });
  };

  return (
    <Modal title="Ativar vaga" isOpen={isOpen} onClose={onClose}>
      <S.ModalContent>
        <p>Você tem certeza que deseja ativar esta vaga?</p>
        <div className="buttons">
          <WhiteButton onClick={onClose}>Cancelar</WhiteButton>
          <BlueButton onClick={handleSubmit}>Confirmar</BlueButton>
        </div>
      </S.ModalContent>
    </Modal>
  );
};
