import styled from 'styled-components';

export const Container = styled.a`
  bottom: 15px;
  right: 15px;
  position: fixed;
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;
