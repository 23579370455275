import styled from 'styled-components';

export const Form = styled.form`
  > div > div {
    display: flex;
    justify-content: center;
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 80px;
  object-fit: contain;
  object-position: center;
  margin: 16px 0;
`;

export const Title = styled.p`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin: 8px 0;
`;

export const Forgot = styled.p`
  font-size: 14px;
  color: #656565;
  margin: 8px 0 8px auto;
  display: flex;
  width: max-content;
  cursor: pointer;
`;

export const Text = styled.p`
  width: max-content;
  font-size: 14px;
  font-weight: 600;
  color: #656565;
  margin: 8px auto;
  display: flex;
  align-items: center;
  > button {
    margin-left: 4px;
  }
`;
