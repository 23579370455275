import { UsersContext } from 'contexts/Users';
import { useContext } from 'react';

export function useUsers() {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error('useUsers must be uses within a UsersProvider');
  }

  return context;
}
