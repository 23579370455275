import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 28px;
  color: var(--blue-senac);
  font-weight: 600;
  padding: 24px 0;
  width: 100%;
  text-align: left;
`;

export const Subtitle = styled.h1`
  font-size: 18px;
  color: #ff0000;
  font-weight: 400;
  padding: 0 0 24px;
  width: 100%;
  text-align: left;
  margin-top: -18px;
`;

export const Inputs = styled.div`
  * {
    display: grid;
    grid-gap: 10px;
  }

  .first-column__vaga {
    grid-template-columns: 2fr 1fr;
  }

  .second-column__vaga {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .third-column__vaga {
    grid-template-columns: 1fr 1fr 2fr;
  }

  .fourth-column__vaga {
    grid-template-columns: 1fr 1fr;
  }

  .seventh-column__vaga {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .turnos {
    display: flex;
    gap: 32px;

    > div {
      display: flex;
      gap: 0px;
      > input,
      > label {
        cursor: pointer;
      }
    }
  }

  .horarios {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    * {
      grid-gap: 0px;
      grid-template-columns: 1fr !important;
    }
  }
`;

export const InputCheckbox = styled.input`
  display: flex;
`;

export const Label = styled.label`
  margin-left: 8px;
`;
