import styled from 'styled-components';

export const Banners = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 24px 0;
  text-align: center;

  > a {
    width: calc(95% / 5);
  }

  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    margin: 8px 0;
    border-radius: 16px;
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    background: #656565;
    cursor: pointer;

    &.cadastro {
      background: var(--blue-senac);
    }
    &.andamento {
      background: var(--orange-senac);
    }
    &.finalizados {
      background: #4eacbe;
    }
    &.contratados {
      background: #75bd10;
    }
    &.curriculos {
      background: #4eacbe;
    }

    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
      margin: 8px 8px 8px 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > a {
      width: 100%;
    }
  }
`;
