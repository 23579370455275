import { useEffect } from 'react';
import { Input, Switch, Select } from 'components/atoms';
import { useCurriculo } from 'hooks';
import { getComplementos } from './requests';

import * as S from './styles';

import ComplementosIcon from 'assets/icons/011-complementos.svg';

const Complementos = () => {
  const { complemento, setComplemento } = useCurriculo();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setComplemento({
      ...complemento,
      [name]: value,
    });
  };

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;
    setComplemento({
      ...complemento,
      [name]: checked,
    });
  };

  const getComplementosData = async () => {
    const response = await getComplementos();
    const data = await response.json();

    setComplemento({
      email_alternativo: data.email_alternativo || '',
      url: data.url || '',
      viagem: data.viagem || false,
      cnh: data.cnh || false,
      estrangeiro: data.estrangeiro || false,
      categoria_cnh: data.categoria_cnh || '',
      transporte: data.transporte || '',
      turno: data.turno || '',
    });
  };

  useEffect(() => {
    getComplementosData();
  }, []);

  const turnoPretendidoOptions = [
    { value: 'matutino', label: 'Matutino' },
    { value: 'vespertino', label: 'Vespertino' },
    { value: 'noturno', label: 'Noturno' },
  ];

  const categoriaCNHOptions = [
    { value: 'a', label: 'A' },
    { value: 'b', label: 'B' },
    { value: 'ab', label: 'AB' },
    { value: 'c', label: 'C' },
    { value: 'd', label: 'D' },
    { value: 'e', label: 'E' },
  ];

  return (
    <>
      <S.Title>
        <img src={ComplementosIcon} alt="Ícone de complementos" />
        Complementos
      </S.Title>

      <S.Inputs>
        <div className="first-column__complementos">
          <Input
            placeholder="E-mail alternativo"
            name="email_alternativo"
            type="email"
            value={complemento.email_alternativo}
            onChange={handleChange}
          />
          <Input placeholder="URL da página profissional" name="url" value={complemento.url} onChange={handleChange} />
        </div>
        <div className="second-column__complementos">
          <Switch
            text="Disponibilidade para viagem?"
            id="2"
            name="viagem"
            onChange={handleChangeSwitch}
            checked={complemento.viagem}
          />
          <Switch text="Possui CNH?" id="3" name="cnh" onChange={handleChangeSwitch} checked={complemento.cnh} />
        </div>
        <div className="third-column__complementos">
          <Select
            title="Turno pretendido"
            name="turno"
            options={turnoPretendidoOptions}
            onChange={handleChange}
            actualSelected={complemento.turno}
            required
          />
          <Select
            title="Categoria CNH"
            name="categoria_cnh"
            options={categoriaCNHOptions}
            onChange={handleChange}
            actualSelected={complemento.categoria_cnh}
            disabled={!complemento.cnh}
          />
          <Select
            title="Possui transporte?"
            name="transporte"
            options={[
              { value: 'nao-possui', label: 'Não possui' },
              { value: 'carro', label: 'Carro' },
              { value: 'moto', label: 'Moto' },
            ]}
            onChange={handleChange}
            actualSelected={complemento.transporte}
            required
          />
        </div>
        <div className="fourth-column__complementos">
          <Switch
            text="Estrangeiro?"
            id="4"
            name="estrangeiro"
            onChange={handleChangeSwitch}
            checked={complemento.estrangeiro}
          />
        </div>
      </S.Inputs>
    </>
  );
};

export default Complementos;
