import { Link, useNavigate } from 'react-router-dom';
import * as S from './styles';

export const VagaCard = ({ vaga, hasCandidated, finalized }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    if (hasCandidated && !finalized) {
      navigate(`/alunos/vagas/${vaga?.id}/?inscrito=true`);
    } else if (!hasCandidated && !finalized) {
      navigate(`/alunos/vagas/${vaga?.id}`);
    }
  };
  return (
    <S.Card key={vaga?.id}>
      <S.Title onClick={handleRedirect}>{vaga?.nome_vaga}</S.Title>
      <S.Modelo>{vaga?.cargo_digitado}</S.Modelo>
      <S.Cidade>{vaga?.cidade?.nome}</S.Cidade>
      {!hasCandidated && !finalized && (
        <Link to={`/alunos/vagas/${vaga?.id}`}>
          <S.CandidateButton>SE CANDIDATAR</S.CandidateButton>
        </Link>
      )}
      {hasCandidated && !finalized && (
        <Link to={`/alunos/vagas/${vaga?.id}/?inscrito=true`}>
          <S.CandidateButton>INSCRITO</S.CandidateButton>
        </Link>
      )}
      {finalized && <S.CandidateButton>FINALIZADO</S.CandidateButton>}
    </S.Card>
  );
};
