import React, { useState, createContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveCadastroEmpresa, saveEndereco, saveContato } from 'pages/Empresas/CadastroEmpresa/requests';
import { removeMask } from 'helpers';

export const CadastroContext = createContext({});

export const CadastroProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isAdmin = pathname.includes('/admin');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [cadastro, setCadastro] = useState({
    nome_fantasia: '',
    cpf_cnpj: '',
    inscricao_municipal: '',
    razao_social: '',
    home_page: '',
    ramo_atividade: '',
    quantidade_funcionario: '',
    telefone: '',
    logotipo: null,
    email: '',
    celular: '',
    tipo_da_empresa: '',
  });
  const [endereco, setEndereco] = useState({
    endereco_completo: '',
    cep: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    tipo_endereco: '',
  });
  const [contato, setContato] = useState({
    nome_do_responsavel: '',
    telefone: '',
  });

  function validateFields() {
    if (cadastro?.razao_social?.length < 1) {
      toast.error('Preencha o campo de Razão Social.');
      return false;
    }
    if (cadastro?.nome_fantasia?.length < 1) {
      toast.error('Preencha o campo de Nome Fantasia.');
      return false;
    }
    if (cadastro?.email?.length < 5 || !cadastro?.email?.includes('@')) {
      toast.error('Preencha o campo de E-mail.');
      return false;
    }
    if (cadastro?.celular?.length < 10) {
      toast.error('Preencha um número de celular válido.');
      return false;
    }
    if (cadastro?.tipo_da_empresa?.length < 1 || cadastro?.tipo_empresa?.length < 1) {
      toast.error('Selecione um Tipo de Empresa.');
      return false;
    }

    if (endereco?.endereco_completo?.length < 1) {
      toast.error('Preencha o campo Endereço Completo.');
    }
    if (endereco?.cep?.length < 8) {
      toast.error('Preencha o campo CEP.');
    }
    if (endereco?.numero?.length < 1) {
      toast.error('Preencha o campo Número.');
    }
    if (endereco?.bairro?.length < 1) {
      toast.error('Preencha o campo Bairro.');
    }
    if (endereco?.cidade?.length < 1) {
      toast.error('Preencha o campo Cidade.');
    }
    if (endereco?.tipo_endereco?.length < 1) {
      toast.error('Preencha o campo Tipo de endereço.');
    }

    if (contato?.nome_do_responsavel.length < 3) {
      toast.error('Preencha o campo Nome do Responsável.');
    }
    if (contato?.telefone.length < 10) {
      toast.error('Preencha o campo Telefone.');
    }

    return true;
  }

  const saveCadastroAndHandleErrors = async (id) => {
    if (validateFields() === false) return;

    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    Object.keys(cadastro).map((item) => {
      if (item !== 'logotipo' && item !== 'telefone') {
        formData.append(item, cadastro[item]);
      }
    });
    formData.append('telefone', removeMask(cadastro.telefone));
    if (!id) formData.append('logotipo', cadastro.logotipo);

    setLoading(true);
    const response = await Promise.all([
      saveCadastroEmpresa({ formData, id }),
      saveEndereco(endereco),
      saveContato(contato),
    ]);
    setLoading(false);

    const allResponsesOk = response.every((res) => res.ok);
    if (allResponsesOk) {
      if (isAdmin) {
        toast.success('Edição realizada com sucesso!');
        navigate('/admin/empresas');
      } else {
        toast.success('Cadastro realizado com sucesso!');
        navigate('/empresas/vagas');
      }
    } else {
      toast.error('Erro ao cadastrar empresa!');
    }
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    saveCadastroAndHandleErrors(id);
  };

  return (
    <CadastroContext.Provider
      value={{
        loading,
        setLoading,
        cadastro,
        setCadastro,
        handleSubmit,
        endereco,
        setEndereco,
        contato,
        setContato,
      }}
    >
      {children}
    </CadastroContext.Provider>
  );
};
