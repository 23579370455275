import React, { useCallback, useState, useEffect } from 'react';
import { Card, Select } from 'components/atoms';
import { TemplateAdmin } from 'components/templates';
import { GraficoContratacoes, GraficoStatus, Ranking } from './components';

import * as S from './styles';
import { getIndicators, getYears, getRankingVacancies, getGraphic, getResume } from './requests';
import { Loading } from 'components/molecules';

export function Dashboard() {
  const [years, setYears] = useState([]);
  const [rankingAno, setRankingAno] = useState('');
  const [contratacoesAno, setContratacoesAno] = useState('');
  const [statusAno, setStatusAno] = useState('');
  const [statusMes, setStatusMes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cardsTop, setCardsTop] = useState();
  const [dataContratacoes, setDataContratacoes] = useState([]);
  const [dataEncaminhamentos, setDataEncaminhamentos] = useState([]);
  const [status, setStatus] = useState();
  const [ranking, setRanking] = useState([]);

  const getInitialData = useCallback(async () => {
    setIsLoading(true);
    const yearsData = await getYears();
    if (yearsData) {
      const organizedYears = yearsData.flatMap((year) => {
        return {
          label: year,
          value: year,
        };
      });
      setYears(organizedYears);
    }

    const indicators = await getIndicators();
    if (indicators) setStatus(indicators);

    const vacancies = await getRankingVacancies();
    if (vacancies) setRanking(vacancies);

    const graphicData = await getGraphic();
    if (graphicData) {
      setDataContratacoes(graphicData.contratacoes);
      setDataEncaminhamentos(graphicData.encaminhamentos);
    }

    const resumeData = await getResume();
    if (resumeData) setCardsTop(resumeData);
    setIsLoading(false);
  }, []);

  const filterIndicators = useCallback(async () => {
    setIsLoading(true);
    const params = new URLSearchParams({ mes: statusMes, ano: statusAno }).toString();
    const indicators = await getIndicators(`?${params}`);
    if (indicators) setStatus(indicators);
    setIsLoading(false);
  }, [statusMes, statusAno]);

  const filterRanking = useCallback(async () => {
    setIsLoading(true);
    const vacancies = await getRankingVacancies(`?ano=${rankingAno}`);
    if (vacancies) setRanking(vacancies);
    setIsLoading(false);
  }, [rankingAno]);

  const filterGraphic = useCallback(async () => {
    setIsLoading(true);
    const graphicData = await getGraphic(`?ano=${contratacoesAno}`);
    if (graphicData) {
      setDataContratacoes(graphicData.contratacoes);
      setDataEncaminhamentos(graphicData.encaminhamentos);
    }
    setIsLoading(false);
  }, [contratacoesAno]);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (statusAno.length || statusMes.length) {
      filterIndicators();
    }
  }, [statusAno, statusMes]);

  useEffect(() => {
    if (rankingAno.length) {
      filterRanking();
    }
  }, [rankingAno]);

  useEffect(() => {
    if (contratacoesAno.length) {
      filterGraphic();
    }
  }, [contratacoesAno]);

  return (
    <TemplateAdmin page="Dashboard">
      {isLoading ? <Loading text="Carregando dados..." /> : null}
      <S.Cards>
        <h1>Totais do Ano Atual</h1>
        <Card blue>
          <p>Empresas</p>
          <span>{cardsTop?.empresas}</span>
        </Card>
        <Card blue>
          <p>Encaminhamentos</p>
          <span>{cardsTop?.encaminhamentos}</span>
        </Card>
        <Card blue>
          <p>Contratações</p>
          <span>{cardsTop?.contratacoes}</span>
        </Card>
        <Card blue>
          <p>Media Salarial</p>
          <span>
            {cardsTop?.media_salarial.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </Card>
      </S.Cards>

      <S.Graphs>
        <Card className="contratacoes">
          <div className="row">
            <p>Encaminhamentos / Contratações</p>
            <Select
              actualSelected={contratacoesAno}
              onChange={(e) => setContratacoesAno(e.target.value)}
              className="contratacoes__select"
              title="Ano"
              options={years}
            />
          </div>
          <GraficoContratacoes dataContratacoes={dataContratacoes} dataEncaminhamentos={dataEncaminhamentos} />
        </Card>

        <Card className="status">
          <div className="row">
            <p>Indicadores</p>
            <Select
              actualSelected={statusMes}
              onChange={(e) => setStatusMes(e.target.value)}
              className="status__select"
              title="Mes"
              options={[
                { label: 'Janeiro', value: 1 },
                { label: 'Fevereiro', value: 2 },
                { label: 'Março', value: 3 },
                { label: 'Abril', value: 4 },
                { label: 'Maio', value: 5 },
                { label: 'Junho', value: 6 },
                { label: 'Julho', value: 7 },
                { label: 'Agosto', value: 8 },
                { label: 'Setembro', value: 9 },
                { label: 'Outubro', value: 10 },
                { label: 'Novembro', value: 11 },
                { label: 'Dezembro', value: 12 },
              ]}
            />
            <Select
              actualSelected={statusAno}
              onChange={(e) => setStatusAno(e.target.value)}
              className="status__select"
              title="Ano"
              options={years}
            />
          </div>
          <div className="row">
            <GraficoStatus data={status?.aprovacao ?? 0} />
            <div className="status__dados">
              <span>{status?.empresas}</span>
              <p>Empresas</p>
              <span>{status?.encaminhamentos}</span>
              <p>Encaminhamentos</p>
              <span>{status?.contratacoes}</span>
              <p>Contratações</p>
            </div>
          </div>
        </Card>

        <Card className="ranking">
          <div className="row">
            <p>Ranking de vagas ofertadas</p>
            <Select
              actualSelected={rankingAno}
              onChange={(e) => setRankingAno(e.target.value)}
              className="contratacoes__select"
              title="Ano"
              options={years}
            />
          </div>
          <Ranking data={ranking} />
        </Card>
      </S.Graphs>
    </TemplateAdmin>
  );
}
