import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PrincipalBannerButton, SecondaryBannerButton } from 'components/molecules';
import { Banners } from 'contexts/Banner';
import { isMobile } from 'react-device-detect';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import * as S from './styles';
import SenacLogo from 'assets/images/white_logo.svg';
import Usuario from 'assets/icons/resume.svg';
import Empresa from 'assets/icons/employee.svg';
// import User2 from 'assets/icons/user-2.svg';

function Home() {
  const { banners, getBanners } = Banners();

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <S.Main>
      <div>
        {/* <S.AdminButton to="/admin">
          <img src={User2} alt="" />
          <p>Painel</p>
        </S.AdminButton> */}
        <S.Logo src={SenacLogo} alt="Senac" />
        <S.Title>
          <span>Banco de</span> Oportunidades
        </S.Title>
        <S.Banner
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {banners.length > 0 &&
            banners.map((banner) => (
              <SwiperSlide key={banner.id}>
                <img src={isMobile ? banner.imagem_mobile : banner.imagem} alt="Banner" />
              </SwiperSlide>
            ))}
        </S.Banner>
        <S.Buttons>
          <Link to="/empresas">
            <SecondaryBannerButton
              icon={Empresa}
              text="Divulgue Sua Vaga"
              subtext="Encontre o profissional ideal para sua empresa"
            />
          </Link>
          <Link to="/alunos">
            <PrincipalBannerButton icon={Usuario} text="Cadastre seu currículo" subtext="Acesse as vagas disponíveis" />
          </Link>
        </S.Buttons>
      </div>
    </S.Main>
  );
}

export default Home;
