import { baseUrl, headers } from 'api';

export const getIdiomas = async () => {
  const response = await fetch(`${baseUrl}/curriculo/idioma/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};

export const addIdiomas = async (preIdiomas) => {
  const response = await fetch(`${baseUrl}/curriculo/idioma/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...preIdiomas }),
  });

  return response;
};

export const editIdiomas = async (idiomasItem) => {
  const response = await fetch(`${baseUrl}/curriculo/idioma/${idiomasItem.id}/`, {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...idiomasItem }),
  });

  return response;
};

export const deleteIdiomas = async (idiomasItem) => {
  const response = await fetch(`${baseUrl}/curriculo/idioma/${idiomasItem.id}/`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
