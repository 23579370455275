import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

import BgHome from 'assets/images/bg-home.png';
import BgHomeMobile from 'assets/images/bg-home-mobile.png';

export const Main = styled.main`
  background-image: ${() => `url(${BgHome})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blue-senac);
    z-index: 1;
    opacity: 0.65;
  }

  > div {
    z-index: 2;
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    background-image: ${() => `url(${BgHomeMobile})`};
  }
`;

export const AdminButton = styled(Link)`
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  width: max-content;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
    margin-right: 8px;
  }
  p {
    color: #fff;
  }
`;

export const Logo = styled.img`
  width: 141px;
  height: 82px;
  margin: 24px auto;
  display: flex;
  object-fit: contain;
`;

export const Title = styled.h1`
  font-size: 40px;
  text-align: center;
  margin: 0;
  color: #fff;
  span {
    color: var(--orange-senac);
  }

  @media screen and (max-width: 800px) {
    font-size: 28px;
    margin: 16px auto;
  }
`;

export const Banner = styled(Swiper)`
  width: 100%;
  height: 400px;
  margin: 24px auto;
  display: flex;
  border-radius: 16px;

  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
  }

  .swiper-pagination-bullet {
    width: 40px;
    height: 8px;
    border-radius: 0;
    &.swiper-pagination-bullet-active {
      background: var(--orange-senac);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1023px) {
    img {
      height: 800px;
    }
  }

  @media screen and (max-width: 767px) {
    height: max-content;
    img {
      object-fit: cover;
      height: 400px;
      object-position: center;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 32px 0;

  > a {
    width: 280px;
    height: 180px;
    margin: 8px;
  }

  &[data-maintenance] {
    position: relative;
    &:before {
      content: 'Estamos em manutenção! Em breve retornaremos.';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(220, 53, 69, 0.8);
      display: grid;
      place-items: center;
      border-radius: 8px;
      color: #fff;
      font-weight: 600;
      font-size: 32px;
      z-index: 2;
    }
  }
`;
