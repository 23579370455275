import { useCallback, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Card, Select } from 'components/atoms';
import { TemplateAdmin } from 'components/templates';
import { Encaminhamentos } from './Encaminhamentos';
import { Contratacao } from './Contratacao';
import { Alunos } from './Alunos';
import { Empresas } from './Empresas';
import { Vagas } from './Vagas';
import { Consolidado } from './Consolidado';
import { Usuarios } from './Usuarios';
import { List } from './List';
import { getYears } from '../Dashboard/requests';

import Clipboard from 'assets/icons/clipboard.svg';
import * as S from './styles';
import { Loading } from 'components/molecules';

export const Relatorios = () => {
  const [loadingMessage, setLoadingMessage] = useState();
  const [relatoriosMes, setRelatoriosMes] = useState('');
  const [relatoriosAno, setRelatoriosAno] = useState('');
  const [years, setYears] = useState([]);

  const getInitialData = useCallback(async () => {
    setLoadingMessage('');
    const yearsData = await getYears();
    if (yearsData) {
      const organizedYears = yearsData.flatMap((year) => {
        return {
          label: year,
          value: year,
        };
      });
      setYears(organizedYears);
    }
    setLoadingMessage(undefined);
  }, []);

  const monthYearSelects = () => {
    return (
      <S.SelectWrapper>
        <Select
          actualSelected={relatoriosMes}
          onChange={(e) => setRelatoriosMes(e.target.value)}
          className="status__select"
          title="Mes"
          options={[
            { label: 'Janeiro', value: 1 },
            { label: 'Fevereiro', value: 2 },
            { label: 'Março', value: 3 },
            { label: 'Abril', value: 4 },
            { label: 'Maio', value: 5 },
            { label: 'Junho', value: 6 },
            { label: 'Julho', value: 7 },
            { label: 'Agosto', value: 8 },
            { label: 'Setembro', value: 9 },
            { label: 'Outubro', value: 10 },
            { label: 'Novembro', value: 11 },
            { label: 'Dezembro', value: 12 },
          ]}
        />
        <Select
          actualSelected={relatoriosAno}
          onChange={(e) => setRelatoriosAno(e.target.value)}
          className="status__select"
          title="Ano"
          options={years}
        />
      </S.SelectWrapper>
    );
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <TemplateAdmin page="Dashboard">
      {loadingMessage && <Loading text={loadingMessage} />}

      <S.Cards>
        <Card blue>
          <img src={Clipboard} alt="" />
          <p>Relatórios</p>
        </Card>
      </S.Cards>

      <S.Wrapper>
        <Card>
          <Routes>
            <Route
              index
              element={
                <List MonthYearSelects={monthYearSelects} relatoriosMes={relatoriosMes} relatoriosAno={relatoriosAno} />
              }
            />
            <Route
              path="/encaminhamentos"
              element={
                <Encaminhamentos
                  MonthYearSelects={monthYearSelects}
                  relatoriosMes={relatoriosMes}
                  relatoriosAno={relatoriosAno}
                />
              }
            />
            <Route
              path="/contratacao"
              element={
                <Contratacao
                  MonthYearSelects={monthYearSelects}
                  relatoriosMes={relatoriosMes}
                  relatoriosAno={relatoriosAno}
                />
              }
            />
            <Route
              path="/alunos"
              element={
                <Alunos
                  MonthYearSelects={monthYearSelects}
                  relatoriosMes={relatoriosMes}
                  relatoriosAno={relatoriosAno}
                />
              }
            />
            <Route
              path="/empresas"
              element={
                <Empresas
                  MonthYearSelects={monthYearSelects}
                  relatoriosMes={relatoriosMes}
                  relatoriosAno={relatoriosAno}
                />
              }
            />
            <Route
              path="/vagas"
              element={
                <Vagas
                  MonthYearSelects={monthYearSelects}
                  relatoriosMes={relatoriosMes}
                  relatoriosAno={relatoriosAno}
                />
              }
            />
            <Route
              path="/consolidado"
              element={
                <Consolidado
                  MonthYearSelects={monthYearSelects}
                  relatoriosMes={relatoriosMes}
                  relatoriosAno={relatoriosAno}
                />
              }
            />
            <Route
              path="/usuarios"
              element={
                <Usuarios
                  MonthYearSelects={monthYearSelects}
                  relatoriosMes={relatoriosMes}
                  relatoriosAno={relatoriosAno}
                />
              }
            />
          </Routes>
        </Card>
      </S.Wrapper>
    </TemplateAdmin>
  );
};

export * from './Encaminhamentos';
export * from './Contratacao';
