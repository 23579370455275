import { baseUrl, headers } from 'api';

export const getComplementos = async () => {
  const response = await fetch(`${baseUrl}/curriculo/complemento/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
