import { baseUrl, headers } from 'api';
import { removeMoneyMask } from 'helpers';

export const saveVaga = async ({ cadastroVaga }) => {
  const response = await fetch(`${baseUrl}/processo/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({
      ...cadastroVaga,
      area_interesse: null,
      unidade: cadastroVaga.unidade?.id || null,
      cidade: parseInt(cadastroVaga.cidade, 10),
      cidade_vaga: cadastroVaga.cidade,
      valor_minimo: removeMoneyMask(cadastroVaga.valor_minimo),
      valor_maximo: removeMoneyMask(cadastroVaga.valor_maximo),
      valor_transporte: removeMoneyMask(cadastroVaga.valor_transporte),
      valor_alimentacao: removeMoneyMask(cadastroVaga.valor_alimentacao),
    }),
  });

  return response;
};

export const editVaga = async ({ cadastroVaga, id, isAdmin }) => {
  const url = isAdmin ? `${baseUrl}/dashboard/processo/${id}/` : `${baseUrl}/processo/${id}/`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({
      ...cadastroVaga,
      cidade: parseInt(cadastroVaga.cidade, 10),
      cidade_vaga: cadastroVaga.cidade,
      unidade: cadastroVaga.unidade?.id || null,
      valor_minimo: removeMoneyMask(cadastroVaga.valor_minimo),
      valor_maximo: removeMoneyMask(cadastroVaga.valor_maximo),
      valor_transporte: removeMoneyMask(cadastroVaga.valor_transporte),
      valor_alimentacao: removeMoneyMask(cadastroVaga.valor_alimentacao),
    }),
  });

  return response;
};
