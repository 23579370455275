import { useEffect } from 'react';
import { phoneMask } from 'helpers';

import * as S from './styles';

export const PrintCurriculo = ({ curriculo, className }) => {
  useEffect(() => {
    if (curriculo) window.print();
  }, [curriculo]);

  return (
    <S.Curriculo className={className}>
      <div className="row space-between">
        <h1>{curriculo?.nome}</h1>
      </div>
      <S.Separator />

      <h2>Resumo</h2>
      <p>{curriculo?.resumo_profissional?.resumo}</p>
      <S.Separator />

      <h2>Contatos</h2>
      <div className="row">
        <div>
          <h3>Fone</h3>
          <p>{phoneMask(curriculo?.dados?.telefone)}</p>
        </div>
        <div>
          <h3>E-mail</h3>
          <p>{curriculo?.dados?.email}</p>
        </div>
        {/* <div>
                  <h3>Links</h3>
                  <p>facebook.com/link</p>
                </div> */}
        <div>
          <h3>Endereço</h3>
          <p>{`${curriculo?.endereco?.logradouro || ''}, ${curriculo?.endereco?.cidade || ''}`}</p>
        </div>
      </div>
      <S.Separator />

      <h2>Formação</h2>
      <div className="row">
        {curriculo?.formacao_academica?.map((formacao) => (
          <div key={formacao.id}>
            <h3>{`${formacao.data_inicio} - ${formacao.data_fim ? formacao.data_fim : 'Hoje'}`}</h3>
            <p>
              <strong>{formacao.descricao}</strong>
            </p>
            <p>{formacao.instituicao}</p>
          </div>
        ))}
      </div>
      <S.Separator />

      <h2>Experiência</h2>
      {curriculo?.experiencia_profissional?.map((experiencia) => (
        <div className="row" key={experiencia.id}>
          <div>
            <h3>{`${experiencia.data_inicio} - ${experiencia.data_fim ? experiencia.data_fim : 'Hoje'}`}</h3>
            <p>
              <strong>{experiencia.empresa}</strong>
            </p>
          </div>
          <p>{experiencia.funcao}</p>
        </div>
      ))}
      <S.Separator />

      <h2>Cursos</h2>
      <div className="row">
        {curriculo?.curso?.map((curso) => (
          <div key={curso.id}>
            <h3>{`${curso.data_inicio} - ${curso.data_fim ? curso.data_fim : 'Hoje'}`}</h3>
            <p>
              <strong>{curso.nome}</strong>
            </p>
            {/* <p>Instituto Federal do Piauí</p> */}
          </div>
        ))}
      </div>
      <S.Separator />

      <h2>Idiomas</h2>
      <div className="row">
        {curriculo?.idioma?.map((idioma) => (
          <div key={idioma.id}>
            <h3>{idioma.nome}</h3>
            <p>
              <strong>{idioma.nivel}</strong>
            </p>
          </div>
        ))}
      </div>
    </S.Curriculo>
  );
};
