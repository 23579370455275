import React from 'react';
import { TemplateEmpresas } from 'components/templates';
import { VagaCard } from '../components/VagaCard';
import { useProcessos } from 'hooks';

import * as S from './styles';
import { useEffect } from 'react';

export const Vagas = () => {
  const { processos, getProcessosEmpresa } = useProcessos();

  useEffect(() => {
    getProcessosEmpresa();
  }, []);

  return (
    <TemplateEmpresas title="PORTAL DA EMPRESA">
      <S.Title>Vagas</S.Title>

      {processos?.map((item) => (
        <VagaCard processo={item} candidateText={item.status} />
      ))}
    </TemplateEmpresas>
  );
};
