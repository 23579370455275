import { deleteIdiomas } from 'pages/Alunos/VagasCurriculo/Idiomas/requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import Trash from 'assets/icons/trash.svg';
import Pen from 'assets/icons/pen.svg';

export const CardIdiomas = ({ id, nome, nivel, idiomas, setIdiomas, setPreIdiomas }) => {
  const checkIfAlreadyEditing = () => {
    const idiomasIndex = idiomas.findIndex((item) => item.editing);
    return idiomasIndex > -1 ? true : false;
  };

  const deleteItem = async (id) => {
    if (checkIfAlreadyEditing()) {
      alert('Termine a edição antes de deletar.');
    } else {
      const idiomasIndex = idiomas.findIndex((item) => item.id === id);
      const response = await deleteIdiomas(idiomas[idiomasIndex]);

      if (response.status === 200) {
        const data = await response.json();
        setIdiomas(idiomas.filter((item) => item.id !== data.id));
        toast.success('Idioma deletado com sucesso!');
      } else {
        toast.error('Erro ao deletar idioma.');
      }
    }
  };

  const editItem = (id) => {
    if (checkIfAlreadyEditing()) {
      alert('Termine a edição antes de editar.');
    } else {
      const idiomasIndex = idiomas.findIndex((item) => item.id === id);
      const newIdiomas = [...idiomas];

      newIdiomas[idiomasIndex] = {
        ...newIdiomas[idiomasIndex],
        editing: true,
      };

      setIdiomas(newIdiomas);
      setPreIdiomas(newIdiomas[idiomasIndex]);
    }
  };

  const fixNivelLabel = (nivel) => {
    switch (nivel) {
      case 'basico':
        return 'Básico';
      case 'intermediario':
        return 'Intermediário';
      case 'avancado':
        return 'Avançado';
      case 'fluente':
        return 'Fluente';
      default:
        return 'Não informado';
    }
  };

  return (
    <S.Card>
      <S.Text>
        <S.Title>{nome}</S.Title>
        <S.Description>{fixNivelLabel(nivel)}</S.Description>
      </S.Text>

      <S.ActionButtons>
        <S.ActionButtonDelete type="button" onClick={() => deleteItem(id)}>
          <img src={Trash} alt="Ícone de lixeira" />
        </S.ActionButtonDelete>

        <S.ActionButtonEdit type="button" onClick={() => editItem(id)}>
          <img src={Pen} alt="Ícone de lápis" />
        </S.ActionButtonEdit>
      </S.ActionButtons>
    </S.Card>
  );
};
