import styled from 'styled-components';

export const Content = styled.div`
  border-radius: 16px;
  background: #f3f3f3 !important;
  margin-top: 40px;
  flex-direction: column;
  padding: 0 8px !important;
  cursor: initial !important;
  height: max-content;

  &.disabled {
    display: none;
  }
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;
  color: #656565 !important;
  font-size: 14px !important;
  padding: 0 !important;
  background: transparent !important;
  margin: 8px 0;
  font-weight: 600;
`;

export const Form = styled.form`
  width: 100%;
  display: grid;
  margin: 0;
  grid-template-columns: 1fr 1fr auto;
  gap: 16px;
  height: max-content;

  div {
    background: transparent !important;
    padding: 0 !important;
    height: max-content !important;

    select,
    input {
      margin: 0;
    }
  }

  button {
    padding: 12px 8px;
    height: max-content;
  }

  img {
    width: 14px !important;
    height: 14px !important;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 4px;
  }
`;
