import { Drawer, HeaderAdmin } from 'components/organisms';

import * as S from './styles';

export const TemplateAdmin = ({ page, children, className }) => {
  return (
    <S.Content className={className}>
      <Drawer />
      <S.Children>
        <HeaderAdmin page={page} />
        {children}
      </S.Children>
    </S.Content>
  );
};
