import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

export const TableContent = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    border-bottom: 1px solid var(--orange-senac);
    th {
      color: var(--blue-senac);
      text-align: left;
      padding: 8px;
    }
  }

  tbody {
    border-bottom: 1px solid #d2d2d2;
    td {
      color: #656565;
      padding: 12px 8px;
      text-align: left;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: ${({ fontSize }) => fontSize || 'initial'};
      &.actions {
        max-width: 400px;
        white-space: pre-wrap;
        float: right;
      }
    }
    tr {
      cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
      &:hover {
        background: #f5f5f5;
      }
    }
  }
`;

export const Action = styled.button`
  background: ${({ color }) => (color ? color : '#656565')};
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 12px;
  text-align: center;
  margin: 4px;
  display: ${({ hide }) => (hide ? 'none' : 'initial')};
`;

export const Paginator = styled(ReactPaginate)`
  display: flex;
  margin: 16px auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  list-style: none;
  li {
    &.previous,
    &.next {
      color: var(--orange-senac);
      &.disabled {
        opacity: 0.6;
      }
    }

    a {
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
    }
    &.selected {
      a {
        background: var(--orange-senac);
        color: #fff;
      }
    }
  }
`;
