import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Input, Select, Switch, BlueButton } from 'components/atoms';
import { Modal } from 'components/molecules';
import { TemplateAdmin } from 'components/templates';
import { cpfMask, removeMask } from 'helpers';
import { getUnidades } from 'hooks';
import { baseUrl, headers } from 'api';

import * as Style from './styles';

import ArrowLeft from 'assets/icons/arrow-left.svg';

export const Profile = () => {
  const navigate = useNavigate();
  const [unidades, setUnidades] = useState([]);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [values, setValues] = useState({
    nome: '',
    cpf: '',
    email: '',
    unidade: '',
    perfil: '',
    ativo: true,
  });
  const [passwords, setPasswords] = useState({
    senha_atual: '',
    senha1: '',
    senha2: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangePasswords = (e) => {
    const { name, value } = e.target;

    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;

    setValues({
      ...values,
      [name]: checked,
    });
  };

  const getUserData = async () => {
    const res = await fetch(`${baseUrl}/usuarios/info/`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    const data = await res.json();
    setValues({
      id: data.id,
      nome: data.nome,
      cpf: data.cpf_cnpj,
      email: data.email,
      unidade: data.unidade,
      perfil: data.tipo_usuario,
      ativo: data.status,
    });
  };

  const getInitialData = async () => {
    const response = await getUnidades();
    const units = await response.json();
    const unitsOptions = units.results.map((item) => {
      return {
        label: item.titulo,
        value: item.id,
      };
    });
    setUnidades(unitsOptions);
    getUserData();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      ...values,
      cpf_cnpj: removeMask(values.cpf),
      status: values.ativo,
      tipo_usuario: values.perfil,
    };

    const res = await fetch(`${baseUrl}/usuarios/info/`, {
      method: 'PUT',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify(body),
    });

    if (res.ok) toast.success('Perfi atualizado com sucesso.');
  };

  const submitNewPassword = async (e) => {
    e.preventDefault();
    if (passwords.senha1 !== passwords.senha2) {
      toast.warning('As novas senhas estão diferentes.');
    } else {
      const res = await fetch(`${baseUrl}/usuarios/troca-senha/`, {
        method: 'POST',
        headers: {
          ...headers,
          Authorization: sessionStorage.getItem('@senac:token'),
        },
        body: JSON.stringify(passwords),
      });
      const data = await res.json();
      if (data.OK) setShowModalPassword(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <TemplateAdmin page="Dashboard">
      <Style.Cards>
        <Style.Row className="row space-between">
          <div className="row">
            <Card blue>
              <p>EDITAR PERFIL</p>
            </Card>
            <Card orange onClick={() => setShowModalPassword(true)}>
              <p>EDITAR SENHA</p>
            </Card>
          </div>
          <a onClick={() => navigate(-1)}>
            <img src={ArrowLeft} alt="" />
            Voltar
          </a>
        </Style.Row>
      </Style.Cards>

      <Style.Form onSubmit={(e) => handleSubmit(e)}>
        <Card>
          <div className="row">
            <Input placeholder="Nome Completo" name="nome" value={values.nome} onChange={handleChange} required />
            <Input placeholder="CPF" name="cpf" value={cpfMask(values.cpf)} onChange={handleChange} required />
            <Input
              placeholder="E-Mail"
              name="email"
              value={values.email}
              onChange={handleChange}
              type="email"
              required
            />
            <Select
              title="Unidade"
              name="unidade"
              actualSelected={values.unidade}
              options={unidades}
              onChange={handleChange}
              required
            />
            <Select
              title="Perfil"
              name="perfil"
              actualSelected={values.perfil}
              options={[
                { label: 'Administrador', value: '1' },
                { label: 'Colaborador', value: '4' },
              ]}
              onChange={handleChange}
              required
            />
            <Switch id="ativo" name="ativo" text="Ativo" checked={values.ativo} onChange={handleChangeSwitch} />
          </div>

          <BlueButton type="submit">SALVAR</BlueButton>
        </Card>
      </Style.Form>

      <Modal isOpen={showModalPassword} onClose={() => setShowModalPassword(false)} closeButton title="Editar Senha">
        <form onSubmit={submitNewPassword}>
          <Input
            placeholder="Senha Atual"
            name="senha_atual"
            value={passwords.senha_atual}
            onChange={handleChangePasswords}
            required
            type="password"
          />
          <Input
            placeholder="Senha Nova"
            name="senha1"
            value={passwords.senha1}
            onChange={handleChangePasswords}
            required
            type="password"
          />
          <Input
            placeholder="Repetir Senha Nova"
            name="senha2"
            value={passwords.senha2}
            onChange={handleChangePasswords}
            required
            type="password"
          />
          <BlueButton type="submit">SALVAR</BlueButton>
        </form>
      </Modal>
    </TemplateAdmin>
  );
};
