import { useState, useEffect, useCallback, useMemo } from 'react';
import { TemplateAdmin } from 'components/templates';
import { Card, Input, Switch } from 'components/atoms';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from 'components/organisms';
import { headers } from 'api';
import { ModalClassificar } from './ModalClassificar';
import { ModalOcultar } from './ModalOcultar';
import { ModalReClassificar } from './ModalReClassificar';

import * as S from './styles';
import Subscribers from 'assets/icons/subscribers.svg';
import { Loading } from 'components/molecules';
import { InputCheckbox } from 'pages/Alunos/VagasCurriculo/AreaInteresse/styles';

export const Inscritos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const pageSelected = useMemo(() => {
    const page = searchParams.get('page');
    if (page) return parseInt(page);
    return 1;
  }, [searchParams]);
  const currentStatus = useMemo(() => {
    const status = searchParams.get('status');
    return status || 'triagem';
  }, [searchParams]);
  const [modalToOpen, setModalToOpen] = useState('');
  const [subscriptionSelected, setSubscriptionSelected] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const showAll = useMemo(() => {
    const mostrar_todos = searchParams?.get('mostrar_todos');
    if (mostrar_todos) return mostrar_todos;
    return null;
  }, [searchParams]);

  const handlePageChange = (e) => {
    if (currentStatus) {
      const filter = {
        status: currentStatus,
        page: pageSelected < 1 ? 1 : e.selected + 1,
        search: searchValue,
        mostrar_todos: showAll,
      };
      const filterFormatted = new URLSearchParams(filter);
      navigate(
        `/admin/inscritos/?${filterFormatted
          .toString()
          .replace(/\w+=&/g, '')
          .replace(/&\w+=$/, '')}`,
      );
    }
  };

  const getSubscribers = useCallback(async () => {
    setIsLoading(true);
    const search = searchParams.get('search');
    if (search) setSearchValue(search);
    const filter = {
      status: currentStatus,
      page: pageSelected,
      search: search || searchValue,
      mostrar_todos: showAll,
    };
    const filterFormatted = new URLSearchParams(filter);
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/processo-aluno/curriculos-vagas/?${filterFormatted
        .toString()
        .replace(/\w+=&/g, '')
        .replace(/&\w+=$/, '')}`,
      {
        method: 'GET',
        headers: {
          ...headers,
          Authorization: sessionStorage.getItem('@senac:token'),
        },
      },
    ).then((res) => res.json());
    const resultsFormatted = data?.results?.map((result) => {
      return {
        id: result?.id,
        'nome aluno': result?.usuario?.nome,
        vaga: result?.processo[0]?.nome_vaga,
        empresa: result?.processo[0]?.usuario__nome,
        cidade: { text: result?.processo[0]?.cidade__nome },
        situação: result?.status?.toLowerCase(),
        handleClick: () => {
          navigate(`/admin/vagas/${result?.processo[0]?.id}`);
        },
        actions: [
          {
            action: 'Currículo',
            color: '#656565',
            onClick: () => {
              navigate(`/admin/alunos/curriculo/${result?.usuario?.id}`);
            },
          },
          {
            action: 'Classificar',
            color: '#004B8D',
            onClick: () => {
              setModalToOpen('classificar');
              setSubscriptionSelected(result?.id);
            },
            hide: currentStatus === 'recusada',
          },
          {
            action: 'Reclassificar',
            color: '#004B8D',
            onClick: () => {
              setModalToOpen('reclassificar');
              setSubscriptionSelected(result?.id);
            },
            hide: currentStatus === 'triagem',
          },
          {
            action: 'Ocultar',
            color: '#4EACBE',
            onClick: () => {
              setModalToOpen('ocultar');
              setSubscriptionSelected(result?.id);
            },
            hide: currentStatus === 'recusada',
          },
        ],
      };
    });
    setRows(resultsFormatted);
    setPageCount(data?.total_pages);
    setIsLoading(false);
  }, [currentStatus, navigate, pageSelected, searchParams, searchValue, showAll]);

  const searchUser = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  useEffect(() => {
    const filter = {
      status: currentStatus,
      page: searchValue ? 1 : pageSelected,
      search: searchValue || '',
      mostrar_todos: showAll,
    };
    const filterFormatted = new URLSearchParams(filter);

    const waitStopTyping = setTimeout(() => {
      navigate(
        `/admin/inscritos/?${filterFormatted
          .toString()
          .replace(/\w+=&/g, '')
          .replace(/&\w+=$/, '')}`,
      );
    }, 500);
    return () => clearTimeout(waitStopTyping);
  }, [searchValue, showAll]);

  const handleCloseModals = () => {
    setModalToOpen('');
    setSubscriptionSelected(null);
    getSubscribers();
  };

  const handleChangeStatus = (newStatus) => {
    const filter = {
      status: newStatus,
      page: pageSelected,
      search: searchValue || '',
      mostrar_todos: showAll,
    };
    const filterFormatted = new URLSearchParams(filter);
    navigate(
      `/admin/inscritos/?${filterFormatted
        .toString()
        .replace(/\w+=&/g, '')
        .replace(/&\w+=$/, '')}`,
    );
  };

  useEffect(() => {
    const search = searchParams?.get('search');
    if (search) setSearchValue(search);
    const verifyRecusadosParams = !!showAll || currentStatus !== 'recusada';
    if (currentStatus && searchParams && verifyRecusadosParams) {
      getSubscribers();
    }
  }, [currentStatus, getSubscribers, searchParams, showAll]);

  return (
    <TemplateAdmin page="Dashboard">
      {isLoading ? <Loading text="Carregando dados..." /> : null}

      <S.Cards>
        <Card blue>
          <img src={Subscribers} alt="" />
          <p>Inscritos</p>
        </Card>
        <Link to="/admin/inscritos/?status=triagem">
          <Card orange onClick={() => handleChangeStatus('triagem')}>
            <p>Triagem</p>
          </Card>
        </Link>
        <Link to="/admin/inscritos/?status=recusada&mostrar_todos=false" onClick={() => handleChangeStatus('recusada')}>
          <Card color="#4EACBE">
            <p>Recusados</p>
          </Card>
        </Link>
        <Input
          border="var(--orange-senac)"
          placeholder="Buscar nome do aluno ou vaga"
          value={searchValue}
          onChange={searchUser}
        />
      </S.Cards>

      <S.TableCard>
        <Card>
          {currentStatus === 'recusada' && (
            <Switch
              text="Mostrar todos"
              id="showAll"
              checked={showAll === 'true'}
              onChange={() => {
                const params = searchParams?.toString();
                const paramsFormatted = params
                  .replace(`mostrar_todos=${showAll}`, `mostrar_todos=${showAll === 'true' ? 'false' : 'true'}`)
                  .replace(`page=${pageSelected}`, `page=1`);
                navigate(`/admin/inscritos/?${paramsFormatted}`);
              }}
            />
          )}
          <Table
            columns={['NOME ALUNO', 'VAGA', 'EMPRESA', 'CIDADE', 'SITUAÇÃO', '']}
            rows={rows}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            pageSelected={pageSelected}
          />
        </Card>
      </S.TableCard>

      <ModalClassificar id={subscriptionSelected} isOpen={modalToOpen === 'classificar'} onClose={handleCloseModals} />

      <ModalOcultar id={subscriptionSelected} isOpen={modalToOpen === 'ocultar'} onClose={handleCloseModals} />

      <ModalReClassificar
        id={subscriptionSelected}
        isOpen={modalToOpen === 'reclassificar'}
        onClose={handleCloseModals}
      />
    </TemplateAdmin>
  );
};
