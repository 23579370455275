import { BlueButton } from 'components/atoms';
import { MainTemplate } from 'components/templates';
import { Dados } from './Dados';
import { Endereco } from './Endereco';
import { Contato } from './Contato';
import { useCadastro } from 'hooks';

import * as S from './styles';

export const CadastroEmpresa = () => {
  const { handleSubmit, loading } = useCadastro();

  return (
    <MainTemplate title="PORTAL DA EMPRESA">
      <S.Banner>Cadastro da empresa</S.Banner>

      <form onSubmit={handleSubmit}>
        <Dados />
        <Endereco />
        <Contato />

        <S.Submit>
          <BlueButton disabled={loading} type="submit">
            Salvar
          </BlueButton>
        </S.Submit>
      </form>
    </MainTemplate>
  );
};
