import Home from 'pages/Home';
import { RecuperarSenha } from 'pages/RecuperarSenha';
import {
  Alunos,
  Vagas,
  VagasCurriculo,
  VagasInscritas,
  VagasFinalizadas,
  VagaDetalheAluno,
  AlterarSenhaAluno,
} from 'pages/Alunos';
import {
  Empresas,
  Vagas as VagasEmpresas,
  VagasAndamento,
  VagasFinalizadas as VagasFinalizadasEmpresa,
  VagasContratados,
  CadastroEmpresa,
  CadastroVaga,
  VagasCurriculos,
  CurriculoPorVaga,
  EditVaga,
  AlterarSenhaEmpresa,
  VagaDetalhe as VagaDetalheEmpresa,
  DetalheAluno,
} from 'pages/Empresas';
import {
  Admin,
  Dashboard,
  Users,
  NewUser,
  AdminAlunos,
  AdminVagas,
  CurriculoAdmin,
  EditCurriculo,
  VagaDetalhe,
  AdminEmpresas,
  EditEmpresa,
  EditVagaAdmin,
  Profile,
  Relatorios,
  InscritosVagaAdmin,
  Inscritos,
} from 'pages/Admin';
import { Importacao } from 'pages/Admin/Importacao';

export const routes = [
  {
    path: '/',
    element: <Home />,
  },

  {
    path: '/alunos',
    element: <Alunos />,
  },

  {
    path: '/empresas',
    element: <Empresas />,
  },

  {
    path: '/admin',
    element: <Admin />,
  },
  {
    path: '/recuperar-senha/:token',
    element: <RecuperarSenha />,
  },
];

export const authenticatedRoutesAdmin = [
  {
    path: '/admin/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/admin/usuarios',
    element: <Users />,
  },
  {
    path: '/admin/usuarios/novo',
    element: <NewUser />,
  },
  {
    path: '/admin/usuarios/edit/:id',
    element: <NewUser />,
  },
  {
    path: '/admin/alunos',
    element: <AdminAlunos />,
  },
  {
    path: '/admin/alunos/curriculo/:id',
    element: <CurriculoAdmin />,
  },
  {
    path: '/admin/alunos/editar/:id',
    element: <EditCurriculo />,
  },
  {
    path: '/admin/vagas',
    element: <AdminVagas />,
  },
  {
    path: '/admin/vagas/:id',
    element: <VagaDetalhe />,
  },
  {
    path: '/admin/vagas/edit/:id',
    element: <EditVagaAdmin />,
  },
  {
    path: '/admin/vagas/inscritos/:id',
    element: <InscritosVagaAdmin />,
  },
  {
    path: '/admin/empresas',
    element: <AdminEmpresas />,
  },
  {
    path: '/admin/empresas/editar/:id',
    element: <EditEmpresa />,
  },
  {
    path: '/admin/empresas/vagas/:id',
    element: <AdminVagas />,
  },
  {
    path: '/admin/perfil',
    element: <Profile />,
  },
  {
    path: '/admin/relatorios//*',
    element: <Relatorios />,
  },
  {
    path: '/admin/importacao',
    element: <Importacao />,
  },
  {
    path: '/admin/inscritos',
    element: <Inscritos />,
  },
];

export const authenticatedRoutesAlunos = [
  {
    path: '/alunos/vagas',
    element: <Vagas />,
  },

  {
    path: '/alunos/vagas/curriculo',
    element: <VagasCurriculo />,
  },

  {
    path: '/alunos/vagas/inscritas',
    element: <VagasInscritas />,
  },

  {
    path: '/alunos/vagas/finalizadas',
    element: <VagasFinalizadas />,
  },
  {
    path: '/alunos/vagas/:id',
    element: <VagaDetalheAluno />,
  },
  {
    path: '/alunos/alterar-senha',
    element: <AlterarSenhaAluno />,
  },
];

export const authenticatedRoutesEmpresas = [
  {
    path: 'empresas/cadastro',
    element: <CadastroEmpresa />,
  },

  {
    path: '/empresas/vagas',
    element: <VagasEmpresas />,
  },

  {
    path: '/empresas/vagas/cadastro',
    element: <CadastroVaga />,
  },

  {
    path: '/empresas/vagas/andamento',
    element: <VagasAndamento />,
  },

  {
    path: '/empresas/vagas/finalizadas',
    element: <VagasFinalizadasEmpresa />,
  },

  {
    path: '/empresas/vagas/contratados',
    element: <VagasContratados />,
  },
  {
    path: '/empresas/vagas/curriculos',
    element: <VagasCurriculos />,
  },
  {
    path: '/empresas/vagas/curriculos/:id',
    element: <CurriculoPorVaga />,
  },
  {
    path: '/empresas/vagas/edit/:id',
    element: <EditVaga />,
  },
  {
    path: '/empresas/alterar-senha',
    element: <AlterarSenhaEmpresa />,
  },
  {
    path: '/empresas/vaga/:id',
    element: <VagaDetalheEmpresa />,
  },
  {
    path: '/empresas/aluno/:id',
    element: <DetalheAluno />,
  },
];
