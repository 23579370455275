import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TemplateAdmin } from 'components/templates';
import { Card } from 'components/atoms';
import { Table } from 'components/organisms';
import { useUsers } from 'hooks';

import * as S from './styles';

import UserOrange from 'assets/icons/user-orange.svg';
import UserWhite from 'assets/icons/user-white.svg';
import { cpfMask } from 'helpers';

export const Users = () => {
  const { users, getUsers } = useUsers();
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const pageSize = 20;

  const handlePageChange = (e) => {
    if (pageSelected === 1) {
      navigate(`/admin/usuarios/?page=${pageSelected}`);
    } else {
      navigate(`/admin/usuarios/?page=${e.selected + 1}`);
    }
  };

  useEffect(() => {
    if (users?.results?.length > 0) {
      const rows_format = users.results.map((user) => {
        return {
          id: user.id,
          nome: user.nome,
          cpf: cpfMask(user.cpf_cnpj),
          'e-mail': user.email,
          unidade: user.unidade,
          perfil: user.tipo_usuario === 1 ? 'Administrador' : 'Colaborador',
          ativo: user.status ? 'Ativo' : 'Inativo',
          handleClick: () => {
            navigate(`/admin/usuarios/edit/${user.id}`);
          },
        };
      });

      setRows(rows_format);
      setPageCount(users.totalPages);
    } else {
      setRows([]);
    }
  }, [users]);

  useEffect(() => {
    const page = searchParams.get('page');
    if (page) setPageSelected(page);
    getUsers(`1,4&page_size=${pageSize}&page=${page || 1}`);
  }, [searchParams]);

  return (
    <TemplateAdmin page="Dashboard">
      <S.Cards>
        <Card blue>
          <img src={UserOrange} alt="" />
          <p>Usuários</p>
        </Card>
        <Link to="/admin/usuarios/novo">
          <Card orange>
            <img src={UserWhite} alt="" />
            <p>Novo Usuário</p>
          </Card>
        </Link>
      </S.Cards>

      <S.TableCard>
        <Card>
          <Table
            columns={['NOME', 'E-MAIL', 'CPF', 'PERFIL', 'UNIDADE', 'ATIVO']}
            rows={rows}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            pageSelected={pageSelected}
          />
        </Card>
      </S.TableCard>
    </TemplateAdmin>
  );
};
