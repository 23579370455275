import { baseUrl, headers } from 'api';
import { WhiteButton, BlueButton } from 'components/atoms';
import { Modal } from 'components/molecules';
import { toast } from 'react-toastify';

import * as S from './styles';

export const ModalReservar = ({ aluno, isOpen, onClose }) => {
  const handleReservar = () => {
    fetch(`${baseUrl}/processo-aluno/${aluno.id}/cadastrar-reserva/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
    }).then((response) => {
      if (response.ok) {
        toast.success('Aluno reservado!');
        onClose();
      } else {
        toast.error('Erro ao reservar aluno!');
      }
    });
  };

  return (
    <Modal title="Reservar currículo" isOpen={isOpen} onClose={onClose}>
      <S.ModalContent>
        <p>Você tem certeza que deseja reservar este currículo?</p>
        <div className="buttons">
          <WhiteButton onClick={onClose}>Cancelar</WhiteButton>
          <BlueButton onClick={() => handleReservar()}>Confirmar</BlueButton>
        </div>
      </S.ModalContent>
    </Modal>
  );
};
