import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TemplateEmpresas } from 'components/templates';
import { BlueButton } from 'components/atoms';
import { useCadastroVaga } from 'hooks';
import Vaga from '../../CadastroVaga/Vaga';
import Beneficios from '../../CadastroVaga/Beneficios';
import { getProcesso } from './requests';

import * as S from './styles';

import CadastroVagaIcon from 'assets/icons/novo-processo.svg';

export const EditVaga = () => {
  const { id } = useParams();
  const { handleEdit, loading } = useCadastroVaga();
  const [processo, setProcesso] = useState(null);

  const getData = async () => {
    const data = await getProcesso(id);
    const processoData = await data.json();
    setProcesso(processoData);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  return (
    <TemplateEmpresas title="PORTAL DA EMPRESA">
      <S.Banner>
        <img src={CadastroVagaIcon} alt="Banner de cadastro de vaga" />
        Vaga
      </S.Banner>

      {processo?.status === 'rejeitada' ? <S.Title>{processo?.rejeitada?.observacao}</S.Title> : null}

      <form onSubmit={(e) => handleEdit(e, processo)}>
        <Vaga processo={processo} />
        <Beneficios processo={processo} />

        <S.Submit>
          <BlueButton type="submit" disabled={loading}>
            Salvar
          </BlueButton>
        </S.Submit>
      </form>
    </TemplateEmpresas>
  );
};
