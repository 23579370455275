import * as S from './styles';

export const Input = ({ icon, iconRight, maxLength, value, marginBottom, ...props }) => {
  return (
    <S.Wrapper>
      <S.Input value={value} maxLength={maxLength} marginBottom={marginBottom} {...props} />
      {icon && <img src={icon} alt="" />}
      {maxLength && (
        <span>
          {value.length}/{maxLength}
        </span>
      )}
    </S.Wrapper>
  );
};
