import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Profile, Menu } from 'components/molecules';
import * as S from './styles';

import SenacLogo from 'assets/images/senac-logo.png';

export const Header = ({ title }) => {
  const location = useLocation();
  const [actualPath, setActualPath] = useState('');

  const checkLocation = () => {
    const splitedPath = location.pathname.split('/');
    const firstPath = splitedPath[1];
    setActualPath(firstPath);
  };

  const redirectToAlunoOrEmpresa = () => {
    return actualPath === 'alunos' ? '/alunos/vagas' : '/empresas/vagas';
  };

  useEffect(() => {
    checkLocation();
  }, [location]);

  return (
    <S.Content>
      <div className="container">
        <Link to={redirectToAlunoOrEmpresa()}>
          <S.Logo src={SenacLogo} alt="Senac" />
        </Link>

        <S.Title>{title}</S.Title>

        <S.MenuContent>
          <Profile />
          <Menu actualPath={actualPath} />
        </S.MenuContent>
      </div>
    </S.Content>
  );
};
