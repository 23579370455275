import styled from 'styled-components';

export const Title = styled.div`
  margin-top: 38px;
  font-size: 28px;
  color: var(--blue-senac);
  font-weight: 600;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title__text {
    display: flex;
    align-items: center;

    img {
      margin-right: 24px;
    }

    h1 {
      font-size: 28px;
    }
  }

  button {
    width: max-content;
    height: 42px;
    font-size: 20px;
  }
`;

export const Inputs = styled.div``;
