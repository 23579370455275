import styled from 'styled-components';

export const Wrapper = styled.div`
  .card {
    background-color: #0c4885;

    > img {
      display: flex;
      margin: 16px auto;
      object-fit: contain;
      max-width: 100%;
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      padding: 24px 0 24px;

      > button {
        color: #fff;
        font-weight: bold;

        &:first-child {
          border: 2px solid var(--orange-senac);
          border-radius: 16px;
        }
      }
    }
  }

  p {
    color: #fff;
    font-size: 18px;
  }

  .card {
    h1 {
      display: none;
    }
  }
`;
