import { Icon } from 'components/atoms';
import * as S from './styles';

export const PrincipalBannerButton = ({ icon, text, subtext }) => {
  return (
    <S.Button className="orange">
      <Icon src={icon || ''} width="40" height="40" alt="" />
      <div>
        <S.Text>{text || ''}</S.Text>
        {subtext && <S.Subtext>{subtext}</S.Subtext>}
      </div>
    </S.Button>
  );
};

export const SecondaryBannerButton = ({ icon, text, subtext }) => {
  return (
    <S.Button>
      <Icon src={icon || ''} width="40" height="40" alt="" />
      <div>
        <S.Text>{text || ''}</S.Text>
        {subtext && <S.Subtext>{subtext}</S.Subtext>}
      </div>
    </S.Button>
  );
};
