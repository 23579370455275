import { useState, useEffect } from 'react';
import { Input, BlueButton } from 'components/atoms';
import { monthYearMask } from 'helpers';
import { CardCursos } from 'pages/Alunos/components/CardsCurriculo/CardCursos';
import { useCurriculo } from 'hooks';
import { getCursos, addCursos, editCursos } from './requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import CursosIcon from 'assets/icons/009-lamp.svg';

const Cursos = () => {
  const { cursos, setCursos, preCursos, setPreCursos } = useCurriculo();
  const preCursosInitialState = {
    nome: '',
    instituicao: '',
    data_inicio: '',
    data_fim: '',
    carga_horaria: '',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreCursos({
      ...preCursos,
      [name]: value,
    });
  };

  const addMoreCursos = async () => {
    const newData = {
      ...preCursos,
      data_inicio: `01/${preCursos.data_inicio}`,
      data_fim: `01/${preCursos.data_fim}`,
    };
    const response = await addCursos(newData);

    if (response.status === 200) {
      const data = await response.json();
      setCursos([...cursos, data]);
      setPreCursos(preCursosInitialState);
      toast.success('Curso adicionado com sucesso!');
    } else {
      toast.error('Erro ao adicionar curso.');
    }
  };

  const confirmEditCursos = async () => {
    const response = await editCursos(preCursos);

    if (response.status === 200) {
      const data = await response.json();
      setCursos(cursos.map((item) => (item.id === data.id ? data : item)));
      setPreCursos(preCursosInitialState);
      toast.success('Formação acadêmica editada com sucesso!');
    } else {
      toast.error('Erro ao editar formação acadêmica');
    }
  };

  const getCursosData = async () => {
    const response = await getCursos();
    const data = await response.json();
    setCursos(data);
  };

  useEffect(() => {
    getCursosData();
  }, []);

  const checkCursos = () => {
    if (preCursos.nome === '') {
      toast.error('Preencha o nome do curso.');
    } else if (preCursos.data_inicio === '') {
      toast.error('Preencha a data de início.');
    } else if (preCursos.data_fim === '') {
      toast.error('Preencha a data de fim.');
    } else if (preCursos.carga_horaria === '') {
      toast.error('Preencha a carga horária.');
    } else {
      addMoreCursos();
    }
  };

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={CursosIcon} alt="Ícone de cursos" />
          <h1>Cursos</h1>
        </div>

        <BlueButton type="button" onClick={() => checkCursos()} disabled={cursos.some((item) => item.editing)}>
          +
        </BlueButton>
      </S.Title>

      <S.Inputs>
        <div className="second-column__cursos">
          <Input
            placeholder="Instituição de Ensino"
            name="instituicao"
            value={preCursos.instituicao}
            onChange={handleChange}
          />
        </div>
        <div className="first-column__cursos">
          <Input placeholder="Nome" name="nome" value={preCursos.nome} onChange={handleChange} />
        </div>
        <div className="third-column__cursos">
          <Input
            placeholder="Mês/Ano de início (Ex. 01/2022)"
            name="data_inicio"
            value={monthYearMask(preCursos.data_inicio)}
            onChange={handleChange}
          />
          <Input
            placeholder="Mês/Ano de fim (Ex. 01/2022)"
            name="data_fim"
            value={monthYearMask(preCursos.data_fim)}
            onChange={handleChange}
          />
        </div>
        <div className="fourth-column__cursos">
          <Input
            placeholder="Carga horária"
            name="carga_horaria"
            value={preCursos.carga_horaria}
            onChange={handleChange}
            type="number"
          />
        </div>

        <BlueButton
          type="button"
          className={!cursos.some((item) => item.editing) && 'is-editing'}
          onClick={() => confirmEditCursos()}
        >
          Confirmar edição
        </BlueButton>
      </S.Inputs>

      {cursos.map((curso, index) => (
        <CardCursos
          key={index}
          id={curso.id}
          nome={curso.nome}
          observacao={curso.observacao || curso.instituicao}
          data_inicio={curso.data_inicio}
          data_fim={curso.data_fim}
          carga_horaria={curso.carga_horaria}
          cursos={cursos}
          setCursos={setCursos}
          setPreCursos={setPreCursos}
        />
      ))}
    </>
  );
};

export default Cursos;
