import { useState } from 'react';
import { baseUrl, headers } from 'api';
import { WhiteButton, BlueButton, TextArea } from 'components/atoms';
import { Modal } from 'components/molecules';

import * as S from './styles';
import { toast } from 'react-toastify';

export const ModalDevolver = ({ id, isOpen, onClose }) => {
  const [motivo, setMotivo] = useState('');
  const handleSubmit = () => {
    fetch(`${baseUrl}/processo/${id}/devolver/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ observacao: motivo }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Vaga devolvida com sucesso!');
          onClose();
        } else if (response.status === 400) {
          toast.error('Vaga já devolvida!');
        } else {
          toast.error('Erro ao devolver vaga!');
        }
      })
      .catch(() => {
        toast.error('Erro ao devolver vaga!');
      });
  };

  return (
    <Modal title="Devolver vaga" isOpen={isOpen} onClose={onClose}>
      <S.ModalContent>
        <TextArea placeholder="Motivo" value={motivo} onChange={(e) => setMotivo(e.target.value)} />
        <div className="buttons">
          <WhiteButton onClick={onClose}>Cancelar</WhiteButton>
          <BlueButton onClick={() => handleSubmit()}>Confirmar</BlueButton>
        </div>
      </S.ModalContent>
    </Modal>
  );
};
