import { useState } from 'react';
import { Modal } from 'components/molecules';
import { useAuth } from 'hooks';
import { ButtonOrangeLetter, OrangeButton } from 'components/atoms';
import { useNavigate } from 'react-router-dom';

import AvisoImportante from 'assets/images/aviso-importante.png';

import * as S from './styles';

export const AdvertiseCurriculums = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    sessionStorage.setItem('isFirstAccess', 'false');
  };

  return (
    <S.Wrapper>
      <Modal isOpen={isOpen} onClose={handleClose} title={`Olá, ${user.nome} 🙃`} closeButton>
        <img src={AvisoImportante} />
        <div className="buttons">
          <ButtonOrangeLetter onClick={handleClose}>FECHAR</ButtonOrangeLetter>
          <OrangeButton
            onClick={() => {
              handleClose();
              navigate('/alunos/vagas/curriculo');
            }}
          >
            ATUALIZAR CURRÍCULO
          </OrangeButton>
        </div>
      </Modal>
    </S.Wrapper>
  );
};
