import { CadastroVagaContext } from 'contexts/Empresas/CadastroVaga';
import { useContext } from 'react';

export function useCadastroVaga() {
  const context = useContext(CadastroVagaContext);

  if (!context) {
    throw new Error('useCadastroVaga must be used within an CadastroVagaProvider');
  }

  return context;
}
