import { baseUrl, headers } from 'api';
import { removeMask } from 'helpers';

export const getExperienciaProfissional = async () => {
  const response = await fetch(`${baseUrl}/curriculo/experiencia-profissional/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};

export const addExperienciaProfissional = async (preExperienciaProfissional) => {
  const response = await fetch(`${baseUrl}/curriculo/experiencia-profissional/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({
      ...preExperienciaProfissional,
      telefone_fixo: removeMask(preExperienciaProfissional.telefone_fixo),
      telefone_celular: removeMask(preExperienciaProfissional.telefone_celular),
    }),
  });

  return response;
};

export const editExperienciaProfissional = async (experienciaProfissionalItem) => {
  const response = await fetch(`${baseUrl}/curriculo/experiencia-profissional/${experienciaProfissionalItem.id}/`, {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...experienciaProfissionalItem }),
  });

  return response;
};

export const deleteExperienciaProfissional = async (experienciaProfissionalItem) => {
  const response = await fetch(`${baseUrl}/curriculo/experiencia-profissional/${experienciaProfissionalItem.id}/`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
