import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Banner = styled.div`
  display: flex;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px 16px;
  color: #fff;
  background: #656565;
  align-items: center;
  font-weight: 600;
  font-size: 24px;

  &:first-child {
    width: max-content;
    margin-right: 16px;
  }

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin: 0 16px;
  }

  @media screen and (max-width: 768px) {
    &:first-child {
      display: none;
    }
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: #656565;
  font-weight: 600;
  padding: 24px 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #d2d2d2;
`;
