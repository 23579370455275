import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 24px;
  width: calc(100% - 32px);

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > div {
      width: 30%;
      margin: 8px;
    }

    > p {
      width: calc(70% - 16px);
    }
  }

  .space-between {
    justify-content: space-between;
  }

  h1 {
    color: var(--orange-senac);
    font-size: 28px;
    text-transform: uppercase;
  }

  a {
    color: #656565;
    font-weight: bold;
    display: flex;
    align-items: center;

    > img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      border-radius: 50%;
      background: var(--blue-senac);
      padding: 8px;
      margin-right: 8px;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 24px 0;

  td {
    text-transform: capitalize;
    &.actions {
      max-width: 600px !important;
    }
  }
`;

export const PrintArea = styled.div`
  .print-area {
    display: none;
  }
  @media print {
    .print-area {
      display: initial;
    }
    .no-print {
      display: none !important;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #d2d2d2;
  margin: 12px 0;
`;
