import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 8px;
    font-size: 20px;
    color: #656565;
    font-weight: 600;
  }

  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 32px;
      height: 32px;
    }
  }
`;
