import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Input, BlueButton } from 'components/atoms';
import { Recover } from 'components/organisms';
import { cpfMask, removeMask } from 'helpers';
import { useAuth } from 'hooks';
import ReCAPTCHA from 'react-google-recaptcha';

import * as S from './styles.js';
import SenacLogo from 'assets/images/senac-logo.png';
import Contact from 'assets/icons/contact.svg';
import Shield from 'assets/icons/shield.svg';

export const Admin = () => {
  const [card, setCard] = useState('login');
  const navigate = useNavigate();
  const { signIn, setIsLoggedIn, setUserType } = useAuth();
  const [captchaKey, setCaptchaKey] = useState(false);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    cpf_cnpj: '',
    password: '',
    tipo_usuario: 1,
  });

  const setLoggedInAndUserType = (token, tipo_usuario) => {
    setIsLoggedIn(true);
    setUserType(tipo_usuario);
    sessionStorage.setItem('@senac:token', token);
    sessionStorage.setItem('@senac:tipo_usuario', tipo_usuario);
  };

  const checkWhereNavigateBasedOnUserType = (tipo_usuario) => {
    if (tipo_usuario === 0 || tipo_usuario === 1 || tipo_usuario === 4) {
      navigate('/admin/dashboard');
    }
  };

  const signInAndHandleErrors = async () => {
    setLoading(true);
    const response = await signIn({ ...values, cpf_cnpj: removeMask(values.cpf_cnpj) });
    setLoading(false);

    if (response.status === 200) {
      const { token, tipo_usuario } = await response.json();
      setLoggedInAndUserType(token, tipo_usuario);
      checkWhereNavigateBasedOnUserType(tipo_usuario);
    } else {
      if (response.status === 400) {
        const { erro } = await response.json();
        toast.error(erro);
      } else {
        toast.warn(`Erro ao se comunicar com o servidor. (${response.status})`);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    signInAndHandleErrors();
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const changeRecaptcha = () => {
    setCaptchaKey(true);
  };

  return (
    <S.Content>
      <div className="container">
        {card === 'login' && (
          <form onSubmit={handleSubmit}>
            <Card>
              <S.Logo src={SenacLogo} alt="Senac" />
              <S.Title>Banco de Oportunidades</S.Title>

              <Input
                name="cpf_cnpj"
                value={cpfMask(values.cpf_cnpj)}
                onChange={handleChange}
                type="text"
                placeholder="CPF"
                icon={Contact}
                required
              />
              <Input
                name="password"
                value={values.password}
                onChange={handleChange}
                type="password"
                placeholder="Senha"
                icon={Shield}
                required
              />
              <S.Forgot onClick={() => setCard('forgot')}>Esqueceu a senha?</S.Forgot>
              <ReCAPTCHA
                sitekey="6LddpdshAAAAAB9RkTyByPLIH9o9on9qqff5-Txi"
                badge="bottomright"
                onChange={changeRecaptcha}
              />

              <BlueButton disabled={loading || !captchaKey} type="submit" style={{ margin: '16px 0' }}>
                Acessar
              </BlueButton>
            </Card>
          </form>
        )}

        {card === 'forgot' && <Recover changeCard={setCard} />}
      </div>
    </S.Content>
  );
};

export * from './Dashboard';
export * from './Users';
export * from './Users/New';
export * from './Alunos';
export * from './Vagas';
export * from './Vagas/Detalhe';
export * from './Vagas/Edit';
export * from './Vagas/Inscritos';
export * from './Empresas';
export * from './Perfil';
export * from './Relatorios';
export * from './Subscribers';
