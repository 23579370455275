import { useAuth } from 'hooks';
import * as S from './styles';

export const HeaderAdmin = ({ page }) => {
  const { openDrawer, setOpenDrawer } = useAuth();

  return (
    <S.Content>
      <div className="container">
        <S.Title>{page}</S.Title>
        <S.HamburgerMenu onClick={() => setOpenDrawer(!openDrawer)} />
      </div>
    </S.Content>
  );
};
