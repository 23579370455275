import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from './Auth';
import AlunosProvider from './Alunos';
import EmpresasProvider from './Empresas';
import { UsersProvider } from './Users';
import { ProcessosProvider } from './Processos';
import { CookiesComponent } from 'components/organisms';

export const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <ProcessosProvider>
        <UsersProvider>
          <EmpresasProvider>
            <AlunosProvider>
              <ToastContainer />
              <CookiesComponent />
              {children}
            </AlunosProvider>
          </EmpresasProvider>
        </UsersProvider>
      </ProcessosProvider>
    </AuthProvider>
  );
};
