import styled from 'styled-components';

export const Banner = styled.div`
  display: flex;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px 16px;
  color: #fff;
  background: #4eacbe;
  align-items: center;
  font-weight: 600;
  font-size: 24px;

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin: 0 16px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 24px 0;

  td {
    text-transform: capitalize;
    &.actions {
      max-width: 600px !important;
    }
  }
`;

export const PrintArea = styled.div`
  .print-area {
    display: none;
  }
  @media print {
    .print-area {
      display: initial;
    }
    .no-print {
      display: none;
    }
  }
`;
