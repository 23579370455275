import React, { useState, createContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveCurriculo } from 'pages/Alunos/VagasCurriculo/requests';
import { addAreaInteresse } from 'pages/Alunos/VagasCurriculo/AreaInteresse/requests';
import { addFormacaoAcademica } from 'pages/Alunos/VagasCurriculo/FormacaoAcademica/requests';
import {
  addExperienciaProfissional,
  deleteExperienciaProfissional,
} from 'pages/Alunos/VagasCurriculo/ExperienciaProfissional/requests';
import { addCursos } from 'pages/Alunos/VagasCurriculo/Cursos/requests';
import { addIdiomas } from 'pages/Alunos/VagasCurriculo/Idiomas/requests';

export const CurriculoContext = createContext({});

export const CurriculoProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isAdmin = pathname.includes('/admin');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [semExperienciaProfissional, setSemExperienciaProfissional] = useState(false);
  const [noExperienceFiltered, setNoExperienceFiltered] = useState([]);

  const [dados, setDados] = useState({
    nome: '',
    cpf_cnpj: '',
    telefone: '',
    celular: '',
    email: '',
    data_nascimento: '',
    rg: '',
    data_expedicao: '',
    portador_deficiencia: false,
    deficiencia: '',
    outras_deficiencias: '',
  });

  const [endereco, setEndereco] = useState({
    cep: '',
    tipo_endereco: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
  });

  const [resumoProfissional, setResumoProfissional] = useState({ resumo: '' });
  const [areaInteresse, setAreaInteresse] = useState([]);
  const [preAreaInteresse, setPreAreaInteresse] = useState([]);
  const [formacaoAcademica, setFormacaoAcademica] = useState([]);
  const [preFormacaoAcademica, setPreFormacaoAcademica] = useState({
    instituicao: '',
    descricao: '',
    data_inicio: '',
    data_fim: '',
  });
  const [experienciaProfissional, setExperienciaProfissional] = useState([]);
  const [preExperienciaProfissional, setPreExperienciaProfissional] = useState({
    funcao: '',
    empresa: '',
    data_inicio: '',
    data_fim: '',
    nome_referencia: '',
    telefone_fixo: '',
    telefone_celular: '',
  });
  const [cursos, setCursos] = useState([]);
  const [preCursos, setPreCursos] = useState({
    nome: '',
    instituicao: '',
    data_inicio: '',
    data_fim: '',
    carga_horaria: '',
  });
  const [idiomas, setIdiomas] = useState([]);
  const [preIdiomas, setPreIdiomas] = useState({
    nome: '',
    nivel: '',
  });

  const [complemento, setComplemento] = useState({
    email_alternativo: '',
    url: '',
    viagem: false,
    cnh: false,
    estrangeiro: false,
    categoria_cnh: '',
    transporte: '',
    turno: '',
  });

  const saveCurriculoAndHandleErrors = async (id) => {
    setLoading(true);

    const response = await saveCurriculo({
      dados,
      endereco,
      resumoProfissional,
      complemento,
      areaInteresse,
      isAdmin,
      id,
    });
    setLoading(false);

    const allResponsesOk = response.every((res) => res.status === 200);
    if (allResponsesOk) {
      if (isAdmin) navigate('/admin/alunos');
      toast.success('Currículo salvo com sucesso!');
    } else {
      toast.error('Erro ao salvar currículo!');
    }
  };

  const verifyExperienciaProfissionalFields =
    preExperienciaProfissional?.funcao.length &&
    preExperienciaProfissional?.empresa.length &&
    preExperienciaProfissional?.data_inicio.length;

  const deleteExperienciaProfissionalSwitch = async () => {
    return await Promise.all(
      noExperienceFiltered?.map(async (item) => {
        const response = await deleteExperienciaProfissional(item);
        if (response.status !== 200) {
          toast.error('Erro ao deletar experiência profissional.');
          return;
        }
      }),
    );
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    if (!formacaoAcademica?.length && !isAdmin) {
      toast.error('É necessário ao menos uma formação acadêmica!');
      return;
    }
    if (!semExperienciaProfissional && !experienciaProfissional?.length && !verifyExperienciaProfissionalFields) {
      toast.error('É necessário ao menos uma experiência profissional!');
      return;
    }

    await saveCurriculoAndHandleErrors(id);
    const newAreaInteresse = areaInteresse;

    Promise.all(
      preAreaInteresse.map(async (item) => {
        const response = await addAreaInteresse({ area_interesse: item });
        if (response.status === 200) {
          const data = await response.json();
          newAreaInteresse.push(data);
        } else {
          toast.error('Erro ao adicionar área de interesse.');
          return;
        }
      }),
    );
    setAreaInteresse(newAreaInteresse);

    // Verify if has Formacao Academica filled
    if (preFormacaoAcademica?.instituicao.length && preFormacaoAcademica?.data_inicio.length) {
      const newFormacao = await addFormacaoAcademica({
        ...preFormacaoAcademica,
        data_inicio: `01/${preFormacaoAcademica.data_inicio}`,
        data_fim: preFormacaoAcademica.data_fim.length > 0 ? `01/${preFormacaoAcademica.data_fim}` : null,
      });
      if (newFormacao.status !== 200) {
        toast.error('Erro ao cadastrar a formação acadêmica.');
        return;
      }
    }

    // Verify if has Experiencia Profissional filled
    if (verifyExperienciaProfissionalFields) {
      const newExperiencia = await addExperienciaProfissional({
        ...preExperienciaProfissional,
        data_inicio: `01/${preExperienciaProfissional.data_inicio}`,
        data_fim: preExperienciaProfissional.data_fim.length > 0 ? `01/${preExperienciaProfissional.data_fim}` : null,
      });
      await deleteExperienciaProfissionalSwitch();
      if (newExperiencia.status !== 200) {
        toast.error('Erro ao cadastrar nova Experiencia Profissional');
        return;
      }
    } else {
      if (semExperienciaProfissional && !experienciaProfissional?.length) {
        const newExperiencia = await addExperienciaProfissional({
          funcao: '1º emprego/sem experiência',
          empresa: '',
          data_inicio: null,
          data_fim: null,
          nome_referencia: '',
          telefone_fixo: '',
          telefone_celular: '',
        });
        if (newExperiencia.status !== 200) {
          toast.error('Erro ao cadastrar nova Experiencia Profissional');
          return;
        }
      }

      if (experienciaProfissional?.length > 0) {
        await deleteExperienciaProfissionalSwitch();
      }
    }

    // Verify if has Cursos filled
    if (
      preCursos?.nome.length &&
      preCursos?.instituicao.length &&
      preCursos?.data_inicio.length &&
      preCursos?.data_fim.length
    ) {
      const newCurso = await addCursos({
        ...preCursos,
        data_inicio: `01/${preCursos.data_inicio}`,
        data_fim: `01/${preCursos.data_fim}`,
      });
      if (newCurso.status !== 200) {
        toast.error('Erro ao cadastrar novo Curso.');
        return;
      }
    }

    // Verify if has Idioma filled
    if (preIdiomas?.nome.length && preIdiomas?.nivel.length) {
      const newIdioma = await addIdiomas(preIdiomas);
      if (newIdioma.status !== 200) {
        toast.error('Erro ao cadastrar novo Idioma.');
        return;
      }
    }
    window.location.reload();
  };

  return (
    <CurriculoContext.Provider
      value={{
        dados,
        setDados,
        endereco,
        setEndereco,
        resumoProfissional,
        setResumoProfissional,
        areaInteresse,
        setAreaInteresse,
        formacaoAcademica,
        setFormacaoAcademica,
        experienciaProfissional,
        setExperienciaProfissional,
        cursos,
        setCursos,
        idiomas,
        setIdiomas,
        complemento,
        setComplemento,
        handleSubmit,
        loading,
        preAreaInteresse,
        setPreAreaInteresse,
        preFormacaoAcademica,
        setPreFormacaoAcademica,
        preExperienciaProfissional,
        setPreExperienciaProfissional,
        preCursos,
        setPreCursos,
        preIdiomas,
        setPreIdiomas,
        semExperienciaProfissional,
        setSemExperienciaProfissional,
        noExperienceFiltered,
        setNoExperienceFiltered,
      }}
    >
      {children}
    </CurriculoContext.Provider>
  );
};
