import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TemplateAdmin } from 'components/templates';
import { BlueButton } from 'components/atoms';
import { useCadastroVaga } from 'hooks';
import Vaga from '../../../Empresas/CadastroVaga/Vaga';
import Beneficios from '../../../Empresas/CadastroVaga/Beneficios';
import { getProcesso } from '../../../Empresas/Vagas/Edit/requests';

import * as S from '../../../Empresas/Vagas/Edit/styles';
import * as Style from './styles';

import ArrowLeft from 'assets/icons/arrow-left.svg';

export const EditVagaAdmin = () => {
  const { id } = useParams();
  const { handleEdit, loading } = useCadastroVaga();
  const [processo, setProcesso] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    const data = await getProcesso(id);
    const processoData = await data.json();
    setProcesso(processoData);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  return (
    <TemplateAdmin page="Dashboard">
      <Style.Row className="row space-between">
        <h1 />
        <a onClick={() => navigate(-1)}>
          <img src={ArrowLeft} alt="" />
          Voltar
        </a>
      </Style.Row>

      <form onSubmit={(e) => handleEdit(e, processo)} style={{ width: 'calc(100% - 32px)' }}>
        <Vaga processo={processo} />
        <Beneficios processo={processo} />

        <S.Submit>
          <BlueButton type="submit" disabled={loading}>
            Salvar
          </BlueButton>
        </S.Submit>
      </form>
    </TemplateAdmin>
  );
};
