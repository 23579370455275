import { useState, useEffect, useMemo } from 'react';
import { Table } from 'components/organisms';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { cnpjCpfMaskRead, phoneMask } from 'helpers';
import { useNavigate } from 'react-router-dom';
import ExportCsv from 'assets/icons/export-csv.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from '../styles';
import { Loading } from 'components/molecules';
import { BlueButton } from 'components/atoms';

export const Alunos = ({ relatoriosMes, relatoriosAno, MonthYearSelects }) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = useMemo(() => 20);
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (page) => {
    setPageSelected(page.selected + 1);
  };

  const getInitialData = async (page) => {
    const params = new URLSearchParams({
      page_size: pageSize,
      page: page || pageSelected,
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/alunos/?${params}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setPageCount(data.count / pageSize);
      setPageSelected(1);
      const rowsFormat = data?.results?.map((item) => {
        return {
          ...item,
          mês: item.mes,
          cpf: cnpjCpfMaskRead(item.cpf),
          'nome completo': item.nome_aluno,
          'data de nascimento': item.data_nascimento,
          telefone: phoneMask(item.telefone),
          celular: phoneMask(item.celular),
          'e-mail': item.email,
          endereço: item.endereco,
          número: item.numero,
          'quantidade de alunos': item.quantidade,
          pcd: item.pcd ? 'Sim' : 'Não',
          'tipo pcd': item.tipo_pcd,
          cidade: { text: item.cidade },
        };
      });
      setRows(rowsFormat);
    } else {
      toast.error('Erro ao sincronizar os relatórios');
      setRows([]);
    }
  };

  async function handleExportCsv() {
    setIsLoading(true);
    const params = new URLSearchParams({
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/alunos/csv/?${params}`, {
      methdo: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    if (response.ok) {
      const data = await response.blob();
      const a = document.createElement('a');
      const url = URL.createObjectURL(data);
      a.style.display = 'none';
      a.href = url;
      a.download = 'relatorio-alunos.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      toast.error('Erro ao gerar CSV.');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getInitialData();
  }, [pageSelected]);

  return (
    <>
      {isLoading && <Loading text="Gerando CSV de alunos..." />}
      <div style={{ display: 'flex' }}>
        <S.GoBack onClick={() => navigate(-1)}>
          <img src={ArrowLeft} alt="" />
          Voltar
        </S.GoBack>
        <S.DownloadCsv onClick={handleExportCsv}>
          <p>EXPORTAR CSV</p>
          <img src={ExportCsv} alt="Baixar CSV" />
        </S.DownloadCsv>
      </div>

      <S.SelectSection>
        <MonthYearSelects />
        <BlueButton onClick={() => getInitialData(1)}>Buscar</BlueButton>
      </S.SelectSection>

      <S.TableWrapper>
        <Table
          columns={[
            'ANO',
            'MÊS',
            'NOME COMPLETO',
            'CPF',
            'RG',
            'DATA DE NASCIMENTO',
            'TELEFONE',
            'CELULAR',
            'E-MAIL',
            'ENDEREÇO',
            'NÚMERO',
            'COMPLEMENTO',
            'BAIRRO',
            'CIDADE',
            'CEP',
            'QUANTIDADE DE ALUNOS',
            'PCD',
            'TIPO PCD',
            'MODALIDADE',
          ]}
          rows={rows}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          pageSelected={pageSelected}
        />
      </S.TableWrapper>
    </>
  );
};
