import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useProcessos } from 'hooks';
import { Table } from 'components/organisms';
import { TemplateEmpresas } from 'components/templates';
import { ModalDesclassificar } from './ModalDesclassificar';
import { ModalContratar } from './ModalContratar';
import { ModalReservar } from './ModalReservar';
import { PrintCurriculo } from './PrintCurriculo';

import * as S from './styles';

import Curriculo from 'assets/icons/curriculo.svg';
import Home from 'assets/icons/home.svg';

export const VagasCurriculos = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [alunoSelected, setAlunoSelected] = useState(null);
  const [rows, setRows] = useState([]);
  const [isOpenDesclassificar, setIsOpenDesclassificar] = useState(false);
  const [isOpenContratar, setIsOpenContratar] = useState(false);
  const [isOpenReservar, setIsOpenReservar] = useState(false);
  const [openCurriculo, setOpenCurriculo] = useState(false);
  const { getCurriculo, getCurriculumByCompany, curriculumList } = useProcessos();
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = 20;

  const handlePageChange = (e) => {
    navigate(`/empresas/vagas/curriculos/?page=${pageSelected < 1 ? 1 : e.selected + 1}`);
    getCurriculumByCompany(`?page_size=${pageSize}&page=${pageSelected < 1 ? 1 : e.selected + 1}`);
  };

  useEffect(() => {
    const page = searchParams.get('page');
    if (page) setPageSelected(page);
  }, [searchParams]);

  useEffect(() => {
    getCurriculumByCompany(`?page_size=${pageSize}&page=${pageSelected}`);
  }, []);

  useEffect(() => {
    if (curriculumList?.results?.length > 0) {
      const rows_format = curriculumList.results.map((aluno) => {
        return {
          'nome completo': aluno.usuario?.nome?.toLowerCase(),
          vaga: aluno.processo?.nome_vaga?.toLowerCase(),
          handleClick: async () => {
            navigate(`/empresas/aluno/${aluno.usuario.id}`);
          },
          actions: [
            {
              action: 'Imprimir',
              color: '#9B9B9B',
              onClick: async () => {
                const curriculo = await getCurriculo(aluno.usuario.id);
                setOpenCurriculo(true);
                setAlunoSelected(curriculo);
              },
            },
            {
              action: 'Contratar',
              color: '#004B8D',
              onClick: async () => {
                setOpenCurriculo(false);
                setIsOpenContratar(true);
                setAlunoSelected(aluno);
              },
            },
            {
              action: 'Cadastro de Reserva',
              color: '#F68B1F',
              onClick: () => {
                setOpenCurriculo(false);
                setIsOpenReservar(true);
                setAlunoSelected(aluno);
              },
            },
            {
              action: 'Desclassificar',
              color: '#656565',
              onClick: async () => {
                setOpenCurriculo(false);
                setIsOpenDesclassificar(true);
                setAlunoSelected(aluno);
              },
            },
          ],
        };
      });

      setRows(rows_format);
      setPageCount(curriculumList.total_pages);
    }
  }, [curriculumList, getCurriculo]);

  return (
    <S.PrintArea>
      <TemplateEmpresas title="PORTAL DA EMPRESA" className="no-print">
        <S.Row>
          <Link to="/empresas/vagas">
            <S.Banner>
              <img src={Home} alt="" />
              Início
            </S.Banner>
          </Link>
          <S.Banner>
            <img src={Curriculo} alt="Ícone currículos" />
            Currículos
          </S.Banner>
        </S.Row>

        <S.TableContainer>
          <Table
            columns={['Nome Completo', 'Vaga', '']}
            rows={rows}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            pageSelected={pageSelected}
          />
        </S.TableContainer>

        <ModalDesclassificar
          aluno={alunoSelected}
          isOpen={isOpenDesclassificar}
          onClose={() => setIsOpenDesclassificar(false)}
        />
        <ModalContratar aluno={alunoSelected} isOpen={isOpenContratar} onClose={() => setIsOpenContratar(false)} />
        <ModalReservar aluno={alunoSelected} isOpen={isOpenReservar} onClose={() => setIsOpenReservar(false)} />
      </TemplateEmpresas>

      {alunoSelected && openCurriculo ? <PrintCurriculo curriculo={alunoSelected} className="print-area" /> : null}
    </S.PrintArea>
  );
};
