import { useParams, Link, useNavigate } from 'react-router-dom';
import { BlueButton } from 'components/atoms';
import { TemplateAdmin } from 'components/templates';
import { useCadastro } from 'hooks';
import { Dados } from 'pages/Empresas/CadastroEmpresa/Dados';
import { Endereco } from 'pages/Empresas/CadastroEmpresa/Endereco';
import { Contato } from 'pages/Empresas/CadastroEmpresa/Contato';

import * as S from 'pages/Alunos/VagasCurriculo/styles';
import * as Style from './styles';

import Database from 'assets/icons/database.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';

export const EditEmpresa = () => {
  const { id } = useParams();
  const { handleSubmit, loading } = useCadastro();
  const navigate = useNavigate();

  return (
    <TemplateAdmin page="Dashboard">
      <S.Banner>
        <img src={Database} alt="" />
        Empresa
      </S.Banner>
      <Style.Row className="row space-between">
        <h1 />
        <a onClick={() => navigate(-1)}>
          <img src={ArrowLeft} alt="" />
          Voltar
        </a>
      </Style.Row>

      <form onSubmit={(e) => handleSubmit(e, id)}>
        <Dados id={id} />
        <Endereco id={id} />
        <Contato id={id} />

        <S.Submit>
          <BlueButton disabled={loading} type="submit">
            Salvar
          </BlueButton>
        </S.Submit>
      </form>
    </TemplateAdmin>
  );
};
