import styled from 'styled-components';

export const Title = styled.h1`
  margin-top: 38px;
  font-size: 28px;
  color: var(--blue-senac);
  font-weight: 600;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title__text {
    display: flex;
    align-items: center;

    img {
      margin-right: 24px;
    }

    h1 {
      font-size: 28px;
    }
  }

  .row {
    display: flex;
  }

  button {
    width: max-content;
    height: 42px;
    font-size: 20px;
    margin-left: 16px;
  }
`;

export const Inputs = styled.div`
  width: 100%;
  position: relative;
`;

export const Select = styled.select`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #d2d2d2;
  background-color: white;
  padding: 16px 40px 16px 16px;
  margin-bottom: 10px;
  appearance: listbox;
`;

export const InputCheckbox = styled.input``;

export const Label = styled.label`
  margin-left: 8px;
`;

export const Areas = styled.div`
  display: grid;
  grid-gap: 10;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 24px;
`;
