import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
`;

export const Children = styled.div`
  width: calc(100% - 300px);
  min-height: 100vh;
  background-color: #f5f6f7;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 15px;
  }
`;
