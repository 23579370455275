import { TemplateAlunos } from 'components/templates';
import { VagaCard } from '../components/VagaCard';
import React, { useEffect } from 'react';
import { useProcessos } from 'hooks';
import { Link } from 'react-router-dom';

import * as S from './styles';

import Finalizados from 'assets/icons/finalizados.svg';
import Home from 'assets/icons/home.svg';
import { Loading } from 'components/molecules';

export const VagasFinalizadas = () => {
  const { processos, getProcessosEmpresa, isLoading } = useProcessos();

  useEffect(() => {
    getProcessosEmpresa('?status=finalizado');
  }, []);

  return (
    <TemplateAlunos title="PORTAL DO CANDIDATO">
      <S.Row>
        <Link to="/alunos/vagas">
          <S.Banner>
            <img src={Home} alt="" />
            Início
          </S.Banner>
        </Link>
        <S.Banner>
          <img src={Finalizados} alt="" />
          Vagas finalizadas
        </S.Banner>
      </S.Row>

      {isLoading ? (
        <Loading text="Carregando processos..." />
      ) : (
        processos?.map((item) => <VagaCard vaga={item} finalized />)
      )}
    </TemplateAlunos>
  );
};
