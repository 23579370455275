import { headers } from 'api';
import { WhiteButton, BlueButton } from 'components/atoms';
import { Modal } from 'components/molecules';
import { toast } from 'react-toastify';

import * as S from './styles';

export const ModalClassificar = ({ id, isOpen, onClose }) => {
  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_API_URL}/processo-aluno/${id}/classificar/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Aluno classificado com sucesso.');
          onClose();
        } else {
          toast.error('Erro ao classificar o aluno.');
        }
      })
      .catch(() => {
        toast.error('Erro ao classificar o aluno.');
      });
  };

  return (
    <Modal title="Classificar" isOpen={isOpen} onClose={onClose}>
      <S.ModalContent>
        <p>Deseja classificar o aluno?</p>
        <div className="buttons">
          <WhiteButton onClick={onClose}>Não</WhiteButton>
          <BlueButton onClick={handleSubmit}>Sim</BlueButton>
        </div>
      </S.ModalContent>
    </Modal>
  );
};
