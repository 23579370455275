import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;

  img {
    width: 38px;
    height: 38px;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Menu = styled.div`
  width: 200px;
  height: 190px;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50px;
  right: 0px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MenuItem = styled(Link)`
  width: 75%;
  text-align: left;
  color: var(--blue-senac);
  font-weight: 600;
  font-size: 15px;
  line-height: 34px;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    color: var(--blue-senac-hover);
  }
`;
