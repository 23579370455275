import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  padding: 24px 15px;
  border-radius: 10px;
  background-color: ${({ color }) => (color ? color : '#fff')};
  background-color: ${({ orange }) => (orange ? 'var(--orange-senac)' : null)};
  background-color: ${({ blue }) => (blue ? 'var(--blue-senac)' : null)};
`;
