import styled from 'styled-components';

export const Overlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;

  @media screen and (max-width: 768px) {
    z-index: 998;
  }
`;
