import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  border-radius: 16px;
  background: #f3f3f3;
  margin: 8px 0;
  padding: 15px;
  position: relative;
`;

export const Title = styled.p`
  font-size: 24px;
  color: var(--blue-senac);
  font-weight: 600;
  cursor: pointer;
`;

export const Modelo = styled.p`
  color: #656565;
  font-size: 18px;
`;

export const Cidade = styled.p`
  color: var(--orange-senac);
  font-size: 18px;
`;

export const CandidateButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #e8e8e8;
  border-radius: 0 15px 15px 0;
  padding: 24px;
  border: none;
  color: #656565;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    position: relative;
    top: auto;
    bottom: -15px;
    left: -15px;
    right: auto;
    height: 60px;
    width: calc(100% + 30px);
    border-radius: 0 0 15px 15px;
    font-size: 18px;
    padding: 16px;
  }
`;
