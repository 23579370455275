import styled from 'styled-components';

export const Banner = styled.div`
  display: flex;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px 16px;
  color: #fff;
  background: var(--blue-senac);
  align-items: center;
  font-weight: 600;
  font-size: 24px;

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin: 0 16px;
  }
`;

export const Title = styled.h3`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  padding: 16px;
  border-radius: 4px;
  margin: 16px 0;
  color: #fff;
  background-color: #dc3545;
`;

export const Submit = styled.div`
  width: 230px;
  margin: 8px 0px 20px 0px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
