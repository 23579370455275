import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Select = styled.select`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #d2d2d2;
  background-color: white;
  padding: 16px 40px 16px 16px;
  margin-bottom: 10px;
  appearance: listbox;
  color: gray;
`;
