import React, { useState, createContext, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { saveVaga, editVaga } from 'pages/Empresas/CadastroVaga/requests';
import { toast } from 'react-toastify';

export const CadastroVagaContext = createContext({});

export const CadastroVagaProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isAdmin = pathname.includes('/admin');
  const [loading, setLoading] = useState(false);
  const [turnos, setTurnos] = useState([]);
  const [horarios, setHorarios] = useState();
  const cadastroVagaInitialState = {
    status: 'em analise',
    nome_vaga: '',
    cargo_digitado: '',
    qtd_vagas: '',
    valor_minimo: '',
    valor_maximo: '',
    dia: '',
    horario: '',
    tipo_vaga: '',
    atividade: '',
    observacao: '',
    escolaridade: '',
    areas_interesses: [],
    data_expiracao: '',
    cidade: '',
    vale_transporte: false,
    valor_transporte: '',
    vale_alimentacao: false,
    valor_alimentacao: '',
    assistencia_medica: false,
    obs_assistencia_medica: '',
    horario_especifico: '',
    horario_observacao: '',
  };

  const [cadastroVaga, setCadastroVaga] = useState(cadastroVagaInitialState);

  function verifyData() {
    if (cadastroVaga.nome_vaga.length < 3) {
      toast.error('Preencha o campo "Nome da vaga" para continuar com o cadastro');
      return false;
    }
    if (cadastroVaga.data_expiracao.length < 10) {
      toast.error('Preencha o campo "Data de expiração da vaga" para continuar com o cadastro');
      return false;
    }
    if (cadastroVaga.qtd_vagas <= 0) {
      toast.error('Preencha o campo "Quantidade de vagas" para continuar com o cadastro');
      return false;
    }
    if (!cadastroVaga.cidade.length) {
      toast.error('Selecione uma cidade para continuar com o cadastro');
      return false;
    }
    if (!cadastroVaga.tipo_vaga.length) {
      toast.error('Selecione um tipo de vaga para continuar com o cadastro');
      return false;
    }
    if (cadastroVaga.escolaridade.length < 3) {
      toast.error('Preencha o campo "Escolaridade" para continuar com o cadastro');
      return false;
    }
    if (cadastroVaga.atividade.length < 3) {
      toast.error('Preencha o campo "Atividades a serem executadas" para continuar com o cadastro');
      return false;
    }
    if (cadastroVaga.observacao.length < 3) {
      toast.error('Preencha o campo "Requisitos da vaga" para continuar com o cadastro');
      return false;
    }
    if (!turnos.length) {
      toast.error('Selecione ao menos um "Turno" para continuar com o cadastro');
      return false;
    }
    if (!horarios) {
      toast.error('Selecione ao menos um "Horário" para continuar com o cadastro');
      return false;
    }
    if (horarios && horarios === 'especifico' && cadastroVaga?.horario_especifico.length < 3) {
      toast.error('Preencha o campo "Horário Específico" para continuar com o cadastro');
      return false;
    }
    return true;
  }

  const saveCadastroVagaAndHandleErrors = async () => {
    if (verifyData() === false) return;

    setLoading(true);
    const response = await saveVaga({
      cadastroVaga: { ...cadastroVaga, turno: turnos.join(', '), horario_comercial: horarios },
    });
    setLoading(false);

    if (response.ok) {
      toast.success('Cadastro realizado com sucesso!');
      setCadastroVaga(cadastroVagaInitialState);
      setTurnos([]);
      setHorarios('');
      navigate('/empresas/vagas');
    } else {
      toast.error('Erro ao cadastrar vaga!');
    }
  };

  const saveEditVaga = async (processo) => {
    setLoading(true);
    const status = searchParams.get('status') || cadastroVaga.status;
    const response = await editVaga({
      cadastroVaga: { ...cadastroVaga, status, turno: turnos.join(', '), horario_comercial: horarios },
      id: processo.id,
      isAdmin,
    });
    setLoading(false);

    if (response.status === 200) {
      toast.success('Edição realizado com sucesso!');
      setCadastroVaga(cadastroVagaInitialState);
      setTurnos([]);
      setHorarios('');
      if (isAdmin) {
        navigate(-1);
      } else {
        navigate('/empresas/vagas');
      }
    } else {
      toast.error('Erro ao editar vaga!');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    saveCadastroVagaAndHandleErrors();
  };

  const handleEdit = async (e, processo) => {
    e.preventDefault();
    saveEditVaga(processo);
  };

  return (
    <CadastroVagaContext.Provider
      value={{
        loading,
        setLoading,
        cadastroVaga,
        setCadastroVaga,
        handleSubmit,
        handleEdit,
        turnos,
        setTurnos,
        horarios,
        setHorarios,
        isAdmin,
      }}
    >
      {children}
    </CadastroVagaContext.Provider>
  );
};
