import * as S from './styles';

export const Select = ({ title, options, actualSelected, ref, className, ...props }) => {
  return (
    <S.Wrapper className={className}>
      <S.Select {...props} value={!actualSelected ? 'titulo' : actualSelected}>
        <option value="titulo" disabled>
          {title}
        </option>

        {options.map((option, index) => (
          <option key={index} id={option.id} value={option.value} ref={ref}>
            {option.label}
          </option>
        ))}
      </S.Select>
    </S.Wrapper>
  );
};
