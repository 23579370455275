import { useState, useEffect, useMemo } from 'react';
import { Table } from 'components/organisms';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { cnpjCpfMaskRead, phoneMask } from 'helpers';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from '../styles';
import { BlueButton } from 'components/atoms';

export const Empresas = ({ relatoriosMes, relatoriosAno, MonthYearSelects }) => {
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = useMemo(() => 20);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPageSelected(page.selected + 1);
  };

  const getInitialData = async (page) => {
    const params = new URLSearchParams({
      page_size: pageSize,
      page: page || pageSelected,
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/empresas/?${params}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setPageCount(data.total_pages);
      setPageSelected(1);
      const rowsFormat = data?.results?.map((item) => {
        return {
          ...item,
          mês: item.mes,
          'cnpj/cpf': cnpjCpfMaskRead(item.cpf_cnpj),
          'inscrição municipal': item.inscricao_municipal,
          'razão social': item.razao_social,
          'nome fantasia': item.nome_fantasia,
          'ramo de atividade': item.ramo_atividade,
          'quantidade de funcionários': item.quantidade_funcionario,
          telefone: phoneMask(item.telefone),
          celular: phoneMask(item.celular),
          'tipo da empresa': item.tipo_empresa,
          'e-mail': item.email,
          endereço: item.endereco,
          número: item.numero,
          'tipo de endereço': item.tipo_endereco,
          'nome do responsável': item.nome_responsavel,
          'telefone do responsável': item.telefone_responsavel,
          'quantidade de empresas': item.quantidade,
        };
      });
      setRows(rowsFormat);
    } else {
      toast.error('Erro ao sincronizar os relatórios');
      setRows([]);
    }
  };

  useEffect(() => {
    getInitialData();
  }, [pageSelected]);

  return (
    <>
      <S.GoBack onClick={() => navigate(-1)}>
        <img src={ArrowLeft} alt="" />
        Voltar
      </S.GoBack>

      <S.SelectSection>
        <MonthYearSelects />
        <BlueButton onClick={() => getInitialData(1)}>Buscar</BlueButton>
      </S.SelectSection>

      <S.TableWrapper>
        <Table
          columns={[
            'ANO',
            'MÊS',
            'CNPJ/CPF',
            'INSCRIÇÃO MUNICIPAL',
            'RAZÃO SOCIAL',
            'NOME FANTASIA',
            'RAMO DE ATIVIDADE',
            'QUANTIDADE DE FUNCIONÁRIOS',
            'TELEFONE',
            'CELULAR',
            'TIPO DA EMPRESA',
            'E-MAIL',
            'ENDEREÇO',
            'NÚMERO',
            'COMPLEMENTO',
            'BAIRRO',
            'CIDADE',
            'CEP',
            'TIPO DE ENDEREÇO',
            'NOME DO RESPONSÁVEL',
            'TELEFONE DO RESPONSÁVEL',
            'QUANTIDADE DE EMPRESAS',
          ]}
          rows={rows}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          pageSelected={pageSelected}
        />
      </S.TableWrapper>
    </>
  );
};
