import { baseUrl, headers } from 'api';

export const getEndereco = async (id) => {
  const url = id ? `${baseUrl}/dashboard/empresa/${id}/endereco/` : `${baseUrl}/empresa/endereco/`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
