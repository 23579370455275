import { useState } from 'react';
import { useAuth } from 'hooks';

import * as S from './styles';

import MenuIcon from 'assets/icons/menu.svg';

export const Menu = ({ actualPath }) => {
  const { logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const checkIsAlunoOrEmpresa = () => {
    return actualPath === 'alunos' ? menuAlunoOptions : menuEmpresaOptions;
  };

  const menuAlunoOptions = [
    { label: 'Painel', to: '/alunos/vagas' },
    { label: 'Currículo', to: '/alunos/vagas/curriculo' },
    { label: 'Alterar senha', to: '/alunos/alterar-senha' },
    { label: 'Sair', to: '/', function: logout },
  ];

  const menuEmpresaOptions = [
    { label: 'Painel', to: '/empresas/vagas' },
    { label: 'Dados da empresa', to: '/empresas/cadastro' },
    { label: 'Alterar senha', to: '/empresas/alterar-senha' },
    { label: 'Sair', to: '/', function: logout },
  ];

  return (
    <S.Content>
      <img src={MenuIcon} alt="Ícone de menu" onClick={() => setIsMenuOpen(!isMenuOpen)} />
      {isMenuOpen && (
        <S.Menu>
          {checkIsAlunoOrEmpresa().map((option, index) => (
            <S.MenuItem
              key={index}
              to={option.to}
              onClick={() => {
                option.function();
                setIsMenuOpen(false);
              }}
            >
              {option.label}
            </S.MenuItem>
          ))}
        </S.Menu>
      )}
    </S.Content>
  );
};
