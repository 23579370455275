import styled from 'styled-components';

export const HamburgerMenu = styled.div`
  display: none;
  width: 40px;
  height: 5px;
  border-radius: 25px;
  background: var(--blue-senac);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -12px;
    left: 0;
    width: 40px;
    height: 5px;
    border-radius: 25px;
    background: var(--blue-senac);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 40px;
    height: 5px;
    border-radius: 25px;
    background: var(--blue-senac);
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const Content = styled.div`
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 200;
  background: #f5f6f7;

  .container {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  color: #656565;
`;
