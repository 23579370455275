import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { baseUrl, headers } from 'api';
import { Card, BlueButton, WhiteButton, Select } from 'components/atoms';
import { Modal, Loading } from 'components/molecules';
import { TemplateEmpresas } from 'components/templates';
import { getUnidades, useProcessos } from 'hooks';
import { toast } from 'react-toastify';

import Comment from 'assets/icons/comment.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from './styles';

export const VagaDetalhe = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [unidades, setUnidades] = useState([]);
  const [unidadeSelected, setUnidadeSelected] = useState('');
  const { vaga, getVaga } = useProcessos();
  const { id } = useParams();

  const getInitialData = async () => {
    setIsLoading(true);
    await getVaga(id);
    const resUnits = await getUnidades();
    const units = await resUnits.json();
    const unitsOptions = units.results.map((item) => {
      return {
        label: item.titulo,
        value: item.id,
      };
    });
    setUnidades(unitsOptions);
    setIsLoading(false);
  };

  const handleSubmit = () => {
    fetch(`${baseUrl}/processo/${id}/encaminhar/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ unidade: parseInt(unidadeSelected) }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Vaga encaminhada com sucesso!');
          setModalOpen(false);
        } else if (response.status === 400) {
          toast.error('Vaga já encaminhada!');
        } else {
          toast.error('Erro ao encaminhar vaga!');
        }
      })
      .catch(() => {
        toast.error('Erro ao encaminhar vaga!');
      });
  };

  useEffect(() => {
    getInitialData(id);
  }, []);

  return (
    <>
      <TemplateEmpresas page="PORTAL DA EMPRESA">
        <S.Cards>
          <Card blue>
            <img src={Comment} alt="" />
            <p>Vagas</p>
          </Card>
        </S.Cards>

        <S.Curriculo>
          <div className="row space-between">
            <h1>{vaga?.nome_vaga}</h1>
            <a onClick={() => navigate(-1)}>
              <img src={ArrowLeft} alt="" />
              Voltar
            </a>
          </div>
          <S.Separator />

          <h2>Atividades a serem executadas</h2>
          <p>{vaga?.observacao}</p>
          <S.Separator />

          <h2>Processo Seletivo</h2>
          <div className="row" style={{ alignItems: 'baseline' }}>
            <div>
              <h3>Data Expiração</h3>
              <p>{vaga?.data_expiracao}</p>
            </div>
            <div>
              <h3>Áreas de interesses</h3>
              {vaga?.areas_interesses?.map((area) => (
                <p>- {area?.nome}</p>
              ))}
            </div>
            {/* <div>
              <h3>Links</h3>
              <p>facebook.com/link</p>
            </div> */}
            <div>
              <h3>Município Destino</h3>
              <p>{vaga?.cidade?.nome}</p>
            </div>
          </div>
          <S.Separator />

          <h2>Vaga</h2>
          <div className="row">
            <div>
              <h3>Atividades a serem executadas</h3>
              <p>{vaga?.atividade}</p>
            </div>
            <div>
              <h3>Quantidade de Vagas</h3>
              <p>{vaga?.qtd_vagas}</p>
            </div>
          </div>
          <div className="row">
            <div>
              <h3>Valor Mínimo</h3>
              <p>
                {Number(vaga?.valor_minimo).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
            <div>
              <h3>Valor Máximo</h3>
              <p>
                {Number(vaga?.valor_maximo).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
            {/* <div>
              <h3>Salário</h3>
              <p>
                {Number(vaga?.valor_salario).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div> */}
            <div>
              <h3>Dias e Horários</h3>
              <p>{vaga?.dia || 'Não informado'}</p>
            </div>
            <div>
              <h3>Tipo de Vaga</h3>
              <p>{vaga?.tipo_vaga.replace('_', ' ')}</p>
            </div>
          </div>
          <div className="row">
            <div>
              <h3>Escolaridade</h3>
              <p>{vaga?.escolaridade}</p>
            </div>
            <div>
              <h3>Requisitos da vaga</h3>
              <p>{vaga?.observacao}</p>
            </div>
          </div>
          <S.Separator />

          <h2>Benefícios</h2>
          <div className="row">
            <div>
              <h3>Vale Transporte</h3>
              <p>
                <strong>{vaga?.vale_transporte ? 'SIM' : 'NÃO'}</strong>
              </p>
              <p>
                {Number(vaga?.valor_transporte).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                / dia
              </p>
            </div>
            <div>
              <h3>Vale Alimentação/Refeição</h3>
              <p>
                <strong>{vaga?.vale_alimentacao ? 'SIM' : 'NÃO'}</strong>
              </p>
              <p>
                {Number(vaga?.valor_alimentacao).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                / dia
              </p>
            </div>
            <div>
              <h3>Assistência Médica</h3>
              <p>
                <strong>{vaga?.assistencia_medica ? 'SIM' : 'NÃO'}</strong>
              </p>
              <p>{vaga?.obs_assistencia_medica}</p>
            </div>
          </div>
          <S.Separator />
        </S.Curriculo>
      </TemplateEmpresas>

      <Modal title="Encaminhar Vaga para Cidades" onClose={() => setModalOpen(false)} isOpen={modalOpen} closeButton>
        <S.ModalContent>
          <Select
            title="Cidade/Unidade"
            options={unidades}
            onChange={(e) => setUnidadeSelected(e.target.value)}
            actualSelected={unidadeSelected}
          />
          <div className="buttons">
            <WhiteButton onClick={() => setModalOpen(false)}>Cancelar</WhiteButton>
            <BlueButton onClick={() => handleSubmit()}>Confirmar</BlueButton>
          </div>
        </S.ModalContent>
      </Modal>

      {isLoading ? <Loading text="Carregando dados..." /> : null}
    </>
  );
};
