import styled from 'styled-components';

export const Title = styled.h1`
  background: #f3f3f3;
  border-radius: 15px;
  padding: 24px 15px;
  font-size: 28px;
  color: var(--blue-senac);
  width: 100%;
  text-align: left;
`;

export const Content = styled.div`
  padding: 20px 0;
  width: 100%;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;

    > div {
      width: 33.3%;
    }
  }

  h1 {
    font-size: 24px;
    color: var(--blue-senac);
    margin: 16px 0;
    font-weight: bold;
  }

  h2 {
    font-size: 20px;
    color: var(--orange-senac);
    margin-bottom: 4px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    color: #656565;
    font-weight: bold;
  }

  button {
    width: max-content;
    margin: 24px 0;
  }

  @media screen and (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #656565;
  margin: 8px 0;
`;
