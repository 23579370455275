import { useState, useEffect } from 'react';
import { useCurriculo } from 'hooks';
import { getAreaInteresseSelect, getAreaInteresse } from './requests';
import { CardAreaInteresse } from 'pages/Alunos/components/CardsCurriculo/CardAreaInteresse';

import * as S from './styles';

import InteresseIcon from 'assets/icons/007-interesse.svg';

const AreaInteresse = () => {
  const { areaInteresse, setAreaInteresse, preAreaInteresse, setPreAreaInteresse } = useCurriculo();
  const [areaInteresseSelect, setAreaInteresseSelect] = useState([]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPreAreaInteresse([...preAreaInteresse, parseInt(value)]);
    } else {
      const removedAreaInteresse = preAreaInteresse.filter((item) => item !== parseInt(value));
      setPreAreaInteresse(removedAreaInteresse);
    }
  };

  const getAreaInteresseSelectData = async () => {
    const response = await getAreaInteresseSelect();
    const data = await response.json();
    setAreaInteresseSelect([
      ...data.map((item) => ({
        id: item.id,
        value: item.nome,
      })),
    ]);
  };

  const getAreaInteresseData = async () => {
    const response = await getAreaInteresse();
    const data = await response.json();
    setAreaInteresse(data);
  };

  useEffect(() => {
    getAreaInteresseSelectData();
    getAreaInteresseData();
  }, []);

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={InteresseIcon} alt="Ícone de interesse" />
          <h1>Área de interesse</h1>
        </div>
      </S.Title>

      <S.Areas>
        {areaInteresseSelect.map((item) => (
          <S.Inputs>
            <S.InputCheckbox
              name="areaInteresse"
              type="checkbox"
              value={item.id}
              id={item.id}
              key={item.id}
              onChange={handleChange}
            />
            <S.Label htmlFor={item.id}>{item.value}</S.Label>
          </S.Inputs>
        ))}
      </S.Areas>

      {areaInteresse.map((area, index) => (
        <CardAreaInteresse
          key={index}
          id={area.id}
          title={area.area_interesse.nome}
          areaInteresse={areaInteresse}
          setAreaInteresse={setAreaInteresse}
        />
      ))}
    </>
  );
};

export default AreaInteresse;
