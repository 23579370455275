import React, { useEffect } from 'react';
import { AdvertiseNewData, AdvertiseCurriculums } from 'components/organisms';
import { TemplateAlunos } from 'components/templates';
import { VagaCard } from '../components/VagaCard';
import { useProcessos, useAuth } from 'hooks';

import * as S from './styles';
import { Loading } from 'components/molecules';

export const Vagas = () => {
  const { processos, getProcessosEmpresa, isLoading, handlePaginateStudents, currentPagesStudent, totalPagesStudent } =
    useProcessos();
  const { user } = useAuth();
  const isFirstAccess = !sessionStorage.getItem('isFirstAccess');

  useEffect(() => {
    getProcessosEmpresa();
  }, []);

  return (
    <TemplateAlunos title="PORTAL DO CANDIDATO">
      <S.Title>Vagas disponíveis</S.Title>

      {isLoading ? <Loading text="Carregando processos..." /> : null}

      {processos?.map((item) => (
        <>{item.status !== 'finalizado' ? <VagaCard vaga={item} /> : null}</>
      ))}

      {currentPagesStudent < totalPagesStudent ? (
        <S.LoadMore onClick={handlePaginateStudents}>Carregar Mais</S.LoadMore>
      ) : null}

      {!user?.ultimo_acesso && user?.ultimo_acesso !== '' ? <AdvertiseNewData /> : null}
      {isFirstAccess ? <AdvertiseCurriculums /> : null}
    </TemplateAlunos>
  );
};
