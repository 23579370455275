import React from 'react';
import { Card, Input, BlueButton } from 'components/atoms';
import { baseUrl, headers } from 'api';

import * as S from '../styles';
import SenacLogo from 'assets/images/senac-logo.png';
import Email from 'assets/icons/email.svg';
import { toast } from 'react-toastify';

export const Recover = () => {
  const [email, setEmail] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${baseUrl}/usuarios/recuperar-senha/`, {
      method: 'POST',
      headers: { ...headers },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Verifique seu email para recuperar a senha!');
        }
      })
      .catch(() => {
        toast.error('Erro ao recuperar a senha!');
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <S.Logo src={SenacLogo} alt="Senac" />
        <S.Title>Banco de Oportunidades</S.Title>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="E-mail"
          icon={Email}
        />
        <BlueButton type="submit" style={{ margin: '16px 0' }}>
          Recuperar Senha
        </BlueButton>
      </Card>
    </form>
  );
};
