import styled from 'styled-components';

export const ModalContent = styled.div`
  width: 100%;

  > p {
    color: #fff;
    font-size: 24px;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;

    > button {
      width: 200px;
      margin: 16px 8px;
    }
  }
`;
