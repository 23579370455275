import { Header } from 'components/organisms';

export const MainTemplate = ({ title, children }) => {
  return (
    <>
      <Header title={title} />
      <div className="container">{children}</div>
    </>
  );
};
