import { useState, useEffect } from 'react';
import { Input, BlueButton } from 'components/atoms';
import { monthYearMask } from 'helpers';
import { CardFormacaoAcademica } from 'pages/Alunos/components/CardsCurriculo';
import { useCurriculo } from 'hooks';
import { getFormacaoAcademica, addFormacaoAcademica, editFormacaoAcademica } from './requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import FormacaoIcon from 'assets/icons/008-formacao.svg';

const FormacaoAcademica = () => {
  const { formacaoAcademica, setFormacaoAcademica, preFormacaoAcademica, setPreFormacaoAcademica } = useCurriculo();
  const preFormacaoAcademicaInitialState = {
    instituicao: '',
    descricao: '',
    data_inicio: '',
    data_fim: '',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreFormacaoAcademica({
      ...preFormacaoAcademica,
      [name]: value,
    });
  };

  const addMoreFormacaoAcademica = async () => {
    const newData = {
      ...preFormacaoAcademica,
      data_inicio: `01/${preFormacaoAcademica.data_inicio}`,
      data_fim: preFormacaoAcademica.data_fim.length > 0 ? `01/${preFormacaoAcademica.data_fim}` : null,
    };
    const response = await addFormacaoAcademica(newData);

    if (response.status === 200) {
      const data = await response.json();
      setFormacaoAcademica([...formacaoAcademica, data]);
      setPreFormacaoAcademica(preFormacaoAcademicaInitialState);
      toast.success('Formação acadêmica adicionada com sucesso!');
    } else {
      toast.error('Erro ao adicionar formação acadêmica.');
    }
  };

  const confirmEditFormacaoAcademica = async () => {
    const response = await editFormacaoAcademica(preFormacaoAcademica);

    if (response.status === 200) {
      const data = await response.json();
      setFormacaoAcademica(formacaoAcademica.map((item) => (item.id === data.id ? data : item)));
      setPreFormacaoAcademica(preFormacaoAcademicaInitialState);
      toast.success('Formação acadêmica editada com sucesso!');
    } else {
      toast.error('Erro ao editar formação acadêmica');
    }
  };

  const getFormacaoAcademicaData = async () => {
    const response = await getFormacaoAcademica();
    const data = await response.json();
    setFormacaoAcademica(data);
  };

  useEffect(() => {
    getFormacaoAcademicaData();
  }, []);

  const checkFormacaoAcademica = () => {
    if (preFormacaoAcademica.instituicao === '') {
      toast.error('Preencha a instituição!');
    } else if (preFormacaoAcademica.descricao === '') {
      toast.error('Preencha a descrição!');
    } else if (preFormacaoAcademica.data_inicio === '') {
      toast.error('Preencha a data de início!');
    } else {
      addMoreFormacaoAcademica();
    }
  };

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={FormacaoIcon} alt="Ícone de formação" />
          <h1>Formação acadêmica</h1>
        </div>

        <BlueButton
          type="button"
          onClick={() => checkFormacaoAcademica()}
          disabled={formacaoAcademica.some((item) => item.editing)}
        >
          +
        </BlueButton>
      </S.Title>

      <S.Inputs>
        <div className="first-column__formacao-academica">
          <Input
            placeholder="Instituição de ensino"
            name="instituicao"
            value={preFormacaoAcademica.instituicao}
            onChange={handleChange}
          />
        </div>
        <div className="second-column__formacao-academica">
          <Input
            placeholder="Descrição do curso"
            name="descricao"
            value={preFormacaoAcademica.descricao}
            onChange={handleChange}
          />
        </div>
        <div className="third-column__formacao-academica">
          <Input
            placeholder="Mês/Ano de início (Ex. 01/2022)"
            name="data_inicio"
            value={monthYearMask(preFormacaoAcademica.data_inicio)}
            onChange={handleChange}
          />
          <Input
            placeholder="Mês/Ano de fim (Ex. 01/2022)"
            name="data_fim"
            value={monthYearMask(preFormacaoAcademica.data_fim)}
            onChange={handleChange}
          />
        </div>

        <BlueButton
          type="button"
          className={!formacaoAcademica.some((item) => item.editing) && 'is-editing'}
          onClick={() => confirmEditFormacaoAcademica()}
        >
          Confirmar edição
        </BlueButton>
      </S.Inputs>

      {formacaoAcademica.map((formacao, index) => (
        <CardFormacaoAcademica
          key={index}
          id={formacao.id}
          instituicao={formacao.instituicao}
          descricao={formacao.descricao}
          data_inicio={formacao.data_inicio}
          data_fim={formacao.data_fim}
          formacaoAcademica={formacaoAcademica}
          setFormacaoAcademica={setFormacaoAcademica}
          setPreFormacaoAcademica={setPreFormacaoAcademica}
        />
      ))}
    </>
  );
};

export default FormacaoAcademica;
