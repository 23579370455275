import { useEffect, useState } from 'react';
import { Input, Switch, InputFile, Select } from 'components/atoms';
import { cnpjMask, phoneMask, dateMask } from 'helpers';
import { getDadosPessoais } from './requests';
import { useAuth, useCurriculo, useProcessos } from 'hooks';
import { saveProfilePhoto } from '../requests';

import * as S from './styles';

import UsuarioIcon from 'assets/icons/005-user.svg';
import ProfileIcon from 'assets/images/profile-icon.png';

const DadosPessoais = ({ id }) => {
  const { user, setUser } = useAuth();
  const { dados, setDados } = useCurriculo();
  const [photo, setPhoto] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const { curriculo, getCurriculo } = useProcessos();
  const pcdOptions = [
    {
      label: 'Deficiência Física',
      value: 'Deficiência Física',
    },
    {
      label: 'Deficiência Visual',
      value: 'Deficiência Visual',
    },
    {
      label: 'Deficiência Auditiva',
      value: 'Deficiência Auditiva',
    },
    {
      label: 'Deficiência Intelectual',
      value: 'Deficiência Intelectual',
    },
    {
      label: 'Deficiência Psicossocial',
      value: 'Deficiência Psicossocial',
    },
    {
      label: 'Deficiência Múltipla',
      value: 'Deficiência Múltipla',
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDados({
      ...dados,
      [name]: value,
    });
  };

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;
    setDados({
      ...dados,
      [name]: checked,
    });
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    setPhoto(files[0]);

    const formData = new FormData();
    formData.append('image', files[0]);

    const photoData = await saveProfilePhoto(formData);
    const photoSaved = await photoData.json();
    const newPhotoUrl = `https://senac-api-bo.128bits.cc${photoSaved.image}`;
    setPhotoUrl(newPhotoUrl);
    setUser({ ...user, image: newPhotoUrl });
    localStorage.setItem('userLogged', JSON.stringify({ ...user, image: newPhotoUrl }));
  };

  const getDadosPessoaisData = async () => {
    const response = await getDadosPessoais(id);
    const data = await response.json();

    setDados({
      nome: data.nome || '',
      cpf_cnpj: data.cpf_cnpj || '',
      telefone: data.telefone || '',
      celular: data.celular || '',
      email: data.email || '',
      data_nascimento: data.data_nascimento || '',
      rg: data.rg || '',
      data_expedicao: data.data_expedicao || '',
      portador_deficiencia: data.portador_deficiencia || false,
      deficiencia: data.deficiencia || '',
      outras_deficiencias: data.outras_deficiencias || '',
    });
  };

  useEffect(() => {
    getDadosPessoaisData();
    if (id) getCurriculo(id);
  }, []);

  useEffect(() => {
    if (curriculo) setPhotoUrl(curriculo.image);
  }, [curriculo]);

  useEffect(() => {
    if (user?.image) {
      setPhotoUrl(user.image);
    }
  }, [user]);

  return (
    <>
      <S.Title>
        <div className="title__text">
          <img src={UsuarioIcon} alt="Ícone usuário" />
          <h1>Dados pessoais</h1>
        </div>
      </S.Title>

      <S.Inputs>
        <div className="image">
          <S.ProfileImage src={photoUrl || ProfileIcon} alt="Foto de perfil" />
          <InputFile
            label="Insira uma foto"
            id="photo"
            file={photo}
            onChange={handleFileChange}
            accept="image/png, image/jpeg"
          />
        </div>
        <div className="first-column__dados-pessoais">
          <Input placeholder="Nome" name="nome" value={dados.nome} onChange={handleChange} required={!id} />
          <Input placeholder="CPF" name="cpf_cnpj" value={cnpjMask(dados.cpf_cnpj)} disabled />
        </div>
        <div className="second-column__dados-pessoais">
          <Input placeholder="Fone fixo" name="telefone" value={phoneMask(dados.telefone)} onChange={handleChange} />
          <Input
            placeholder="Fone celular"
            name="celular"
            value={phoneMask(dados.celular)}
            onChange={handleChange}
            required={!id}
          />
          <Input placeholder="E-mail" name="email" type="email" value={dados.email} onChange={handleChange} />
        </div>
        <div className="second-column__dados-pessoais">
          <Input
            placeholder="Nascimento"
            name="data_nascimento"
            value={dateMask(dados.data_nascimento)}
            onChange={handleChange}
            required={!id}
          />
          <Input placeholder="RG" name="rg" value={dados.rg} onChange={handleChange} type="number" />
          <Input
            placeholder="Orgão Emissor"
            name="data_expedicao"
            value={dados.data_expedicao}
            onChange={handleChange}
          />
        </div>
        <div className="second-column__dados-pessoais">
          <Switch
            text="Pessoa com deficiência - PCD?"
            id="1"
            name="portador_deficiencia"
            onChange={handleChangeSwitch}
            checked={dados.portador_deficiencia}
          />
          {dados.portador_deficiencia ? (
            <>
              <Select
                title="Tipos de PCD"
                options={pcdOptions}
                name="deficiencia"
                actualSelected={dados.deficiencia}
                onChange={handleChange}
              />
            </>
          ) : null}
        </div>
      </S.Inputs>
    </>
  );
};

export default DadosPessoais;
