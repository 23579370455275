import { Link, useResolvedPath, useMatch } from 'react-router-dom';

export const CustomLink = ({ to, children }) => {
  const resolvedPath = useResolvedPath(to);
  const match = useMatch(to);

  return (
    <Link className={match ? 'active' : null} to={resolvedPath}>
      {children}
    </Link>
  );
};
