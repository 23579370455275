import { BlueButton, WhiteButton } from 'components/atoms';
import { Modal } from 'components/molecules';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ModalFinalizar } from 'pages/Admin/Vagas/ModalFinalizar';
import { useProcessos } from 'hooks';
import { FormNps } from '../FormNps';
import { FormProrrogar } from '../FormProrrogar';

import * as S from './styles';

export const VagaCard = ({ processo, candidateText }) => {
  const navigate = useNavigate();
  const [modalProrrogarFinalizar, setModalProrrogarFinalizar] = useState(false);
  const [modalProrrogar, setModalProrrogar] = useState(false);
  const [modalNps, setModalNps] = useState(false);
  const [showModalReabrir, setShowModalReabrir] = useState(false);
  const [modalFinalizar, setModalFinalizar] = useState(false);
  const { getProcessosEmpresa } = useProcessos();

  return (
    <>
      {processo ? (
        <>
          <S.Card key={processo.id}>
            <div>
              {processo.status === 'rejeitada' || processo.status === 'em analise' ? (
                <Link to={`/empresas/vagas/edit/${processo.id}`}>
                  <S.Title>{processo.nome_vaga}</S.Title>
                </Link>
              ) : (
                <>
                  {processo.status === 'finalizado' ? (
                    <Link to={`/empresas/vaga/${processo.id}`}>
                      <S.Title>{processo.nome_vaga}</S.Title>
                    </Link>
                  ) : (
                    <>
                      {!processo.expirada ? (
                        <Link to={`/empresas/vagas/curriculos/${processo.id}`}>
                          <S.Title>{processo.nome_vaga}</S.Title>
                        </Link>
                      ) : (
                        <S.Title onClick={() => setModalProrrogarFinalizar(true)}>{processo.nome_vaga}</S.Title>
                      )}
                    </>
                  )}
                </>
              )}
              <S.Modelo>{processo.cargo_digitado}</S.Modelo>
              <S.Cidade>{processo.cidade?.nome}</S.Cidade>
            </div>
            {processo.status === 'rejeitada' || processo.status === 'em analise' ? (
              <Link to={`/empresas/vagas/edit/${processo.id}`}>
                <S.CandidateButton>{candidateText}</S.CandidateButton>
              </Link>
            ) : null}
            {processo.status === 'finalizado' ? (
              <S.CandidateButtonWrapper>
                <S.CandidateButton
                  onClick={() => navigate(`/empresas/vagas/cadastro/?tocopy=${processo.id}`)}
                  className="second-button"
                >
                  Copiar
                </S.CandidateButton>
                <S.CandidateButton onClick={() => setShowModalReabrir(true)}>{candidateText}</S.CandidateButton>
              </S.CandidateButtonWrapper>
            ) : null}
            {processo.status === 'ativada' ? (
              <>
                {!processo.expirada ? (
                  <>
                    <S.ActionButton
                      onClick={() => navigate(`/empresas/vaga/${processo.id}`)}
                      color="var(--orange-senac)"
                    >
                      Visualizar
                    </S.ActionButton>
                    <S.ActionButton
                      onClick={() => navigate(`/empresas/vagas/curriculos/${processo.id}`)}
                      color="var(--blue-senac)"
                    >
                      Inscritos
                    </S.ActionButton>
                    <S.ActionButton onClick={() => setModalFinalizar(true)}>Finalizar</S.ActionButton>
                    <Link to={`/empresas/vagas/curriculos/${processo.id}`}>
                      <S.CandidateButton>
                        {candidateText}
                        {processo.expirada ? <span>Expirada ({processo.data_expiracao})</span> : null}
                      </S.CandidateButton>
                    </Link>
                  </>
                ) : (
                  <S.CandidateButton onClick={() => setModalProrrogarFinalizar(true)}>
                    {candidateText}
                    {processo.expirada ? <span>Expirada ({processo.data_expiracao})</span> : null}
                  </S.CandidateButton>
                )}
              </>
            ) : null}
          </S.Card>

          {modalFinalizar ? (
            <ModalFinalizar
              id={processo.id}
              isOpen={modalFinalizar}
              onClose={() => {
                setModalFinalizar(false);
                getProcessosEmpresa('?status=ativada');
              }}
            />
          ) : null}

          {showModalReabrir ? (
            <Modal
              closeButton
              isOpen={showModalReabrir}
              onClose={() => setShowModalReabrir(false)}
              title="Deseja reabrir a vaga?"
            >
              <S.RowModal>
                <WhiteButton onClick={() => setShowModalReabrir(false)}>Não</WhiteButton>
                <BlueButton
                  onClick={() => navigate(`/empresas/vagas/cadastro/?processo=${processo.id}&status=em analise`)}
                >
                  Sim
                </BlueButton>
              </S.RowModal>
            </Modal>
          ) : null}

          {modalProrrogarFinalizar ? (
            <Modal
              isOpen={modalProrrogarFinalizar}
              closeButton
              onClose={() => setModalProrrogarFinalizar(false)}
              title="Prorrogar ou Finalizar?"
            >
              <S.RowModal>
                <BlueButton
                  onClick={() => {
                    setModalProrrogar(true);
                    setModalProrrogarFinalizar(false);
                  }}
                >
                  Prorrogar
                </BlueButton>
                <BlueButton
                  onClick={() => {
                    setModalNps(true);
                    setModalProrrogarFinalizar(false);
                  }}
                >
                  Finalizar
                </BlueButton>
              </S.RowModal>
            </Modal>
          ) : null}

          {modalProrrogar ? (
            <FormProrrogar
              isOpen={modalProrrogar}
              onClose={() => {
                setModalProrrogar(false);
                getProcessosEmpresa();
              }}
              processoSelected={processo.id}
            />
          ) : null}
          {modalNps ? (
            <FormNps isOpen={modalNps} onClose={() => setModalNps(false)} processoSelected={processo.id} />
          ) : null}
        </>
      ) : null}
    </>
  );
};
