import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { GlobalStyles } from 'styles/GlobalStyles';
import { AppProvider } from './contexts';

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <GlobalStyles />
        <Router />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
