import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, ButtonOrangeLetter, Input, BlueButton, OrangeButton } from 'components/atoms';
import { cpfMask, removeMask, cnpjMask } from 'helpers';
import { useAuth } from 'hooks';
import ReCAPTCHA from 'react-google-recaptcha';

import * as S from '../styles';
import SenacLogo from 'assets/images/senac-logo.png';
import Contact from 'assets/icons/contact.svg';
import Shield from 'assets/icons/shield.svg';

export const Login = ({ changeCard }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { signIn, setIsLoggedIn, setUserType, setUser } = useAuth();
  const isCompany = pathname.includes('/empresas');
  const [captchaKey, setCaptchaKey] = useState(false);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    cpf_cnpj: '',
    password: '',
    tipo_usuario: pathname === '/empresas' ? 2 : 3,
  });

  const setLoggedInAndUserType = (token, tipo_usuario) => {
    setIsLoggedIn(true);
    setUserType(tipo_usuario);
    sessionStorage.setItem('@senac:token', token);
    sessionStorage.setItem('@senac:tipo_usuario', tipo_usuario);
  };

  const checkIfUserAlreadyRegistred = (ultimo_acesso) => {
    if (!ultimo_acesso) {
      navigate('/empresas/cadastro');
    } else {
      navigate('/empresas/vagas');
    }
  };

  const checkWhereNavigateBasedOnUserType = (tipo_usuario, ultimo_acesso) => {
    if (tipo_usuario === 3) {
      navigate('/alunos/vagas');
    } else {
      checkIfUserAlreadyRegistred(ultimo_acesso);
    }
  };

  const singInAndHandleErrors = async () => {
    setLoading(true);
    const response = await signIn({ ...values, cpf_cnpj: removeMask(values.cpf_cnpj) });
    setLoading(false);

    if (response.status === 200) {
      const userLogged = await response.json();
      setLoggedInAndUserType(userLogged.token, userLogged.tipo_usuario);
      checkWhereNavigateBasedOnUserType(userLogged.tipo_usuario, userLogged.ultimo_acesso);
      const userData = {
        id: userLogged.usuario.id || '',
        tipo_usuario: userLogged.usuario.tipo_usuario || '',
        status: userLogged.usuario.status || false,
        nome: userLogged.usuario.nome || '',
        cpf_cnpj: userLogged.usuario.cpf_cnpj || '',
        email: userLogged.usuario.email || '',
        image: userLogged.usuario.image || '',
        acesso: userLogged.usuario.acesso || '',
        ultimo_acesso: userLogged.usuario.ultimo_acesso || '',
      };
      localStorage.setItem('userLogged', JSON.stringify(userData));
      setUser(userData);
    } else {
      if (response.status === 400) {
        const { erro } = await response.json();
        toast.error(erro);
      } else {
        toast.warn(`Erro ao se comunicar com o servidor. (${response.status})`);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    singInAndHandleErrors();
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const changeRecaptcha = () => {
    setCaptchaKey(true);
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <Card>
        <S.Logo src={SenacLogo} alt="Senac" />
        <S.Title>Banco de Oportunidades</S.Title>

        <Input
          name="cpf_cnpj"
          value={isCompany ? cnpjMask(values.cpf_cnpj) : cpfMask(values.cpf_cnpj)}
          onChange={handleChange}
          type="text"
          placeholder={isCompany ? 'CNPJ/CPF' : 'CPF'}
          icon={Contact}
          required
        />
        <Input
          name="password"
          value={values.password}
          onChange={handleChange}
          type="password"
          placeholder="Senha"
          icon={Shield}
          required
        />

        <S.Forgot onClick={() => changeCard('forgot')}>Esqueceu a senha?</S.Forgot>
        <ReCAPTCHA sitekey="6LddpdshAAAAAB9RkTyByPLIH9o9on9qqff5-Txi" badge="bottomright" onChange={changeRecaptcha} />

        {isCompany ? (
          <OrangeButton
            disabled={loading || !captchaKey}
            type="submit"
            style={{ margin: '16px 0', color: '#fff', fontWeight: 'bold' }}
          >
            Acessar
          </OrangeButton>
        ) : (
          <BlueButton disabled={loading || !captchaKey} type="submit" style={{ margin: '16px 0' }}>
            Acessar
          </BlueButton>
        )}
        <S.Text>
          Ainda não tem cadastro?
          <ButtonOrangeLetter onClick={() => changeCard('register')}>Registre aqui</ButtonOrangeLetter>
        </S.Text>
      </Card>
    </S.Form>
  );
};
