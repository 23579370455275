import { baseUrl, headers } from 'api';

export const getUnidades = async () => {
  const res = fetch(`${baseUrl}/unidade/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });
  return res;
};

export const getCidades = async () => {
  const res = fetch(`${baseUrl}/cidade/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });
  return res;
};

export * from './useAuth';
export * from './Alunos/useCurriculo';
export * from './Empresas/useCadastro';
export * from './Empresas/useCadastroVaga';
export * from './useUsers';
export * from './useProcessos';
