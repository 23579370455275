import styled from 'styled-components';

export const Content = styled.header`
  padding: 20px 0;
  border-bottom: 6px solid #f68b1f;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 200;
  background: #fff;

  .container {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    > a {
      width: 230px;
      display: flex;
      justify-content: flex-start;
    }
  }
`;

export const Logo = styled.img`
  width: 130px;
  height: 70px;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    width: 95px;
    height: 45px;
  }
`;

export const Title = styled.h1`
  color: #656565;
  font-size: 35px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuContent = styled.div`
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
