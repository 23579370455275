import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Card, WhiteButton, BlueButton, Input } from 'components/atoms';
import { Modal } from 'components/molecules';
import { Table } from 'components/organisms';
import { TemplateAdmin } from 'components/templates';
import { ModalAtivar } from './ModalAtivar';
import { ModalEncaminhar } from './ModalEncaminhar';
import { ModalDevolver } from './ModalDevolver';
import { ModalFinalizar } from './ModalFinalizar';

import CommentWhite from 'assets/icons/comment-white.png';
import { useAuth, useProcessos } from 'hooks';

import * as S from './styles';
import { FormProrrogar } from 'pages/Empresas/components';
import { formatDateDjango } from 'helpers';

export const AdminVagas = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { processos, getProcessos, pageProcessos } = useProcessos();
  const [rows, setRows] = useState([]);
  const [filterVagas, setFilterVagas] = useState('moderacao');
  const [modalAtivar, setModalAtivar] = useState(false);
  const [modalEncaminhar, setModalEncaminhar] = useState(false);
  const [modalDevolver, setModalDevolver] = useState(false);
  const [modalFinalizar, setModalFinalizar] = useState(false);
  const [processoSelected, setProcessoSelected] = useState(null);
  const [showReOpenModal, setShowReOpenModal] = useState(false);
  const [showProrrogarModal, setShowProrrogarModal] = useState(false);
  const [pageSelected, setPageSelected] = useState(1);
  const { userType } = useAuth();
  const page_size = 20;

  const handlePageChange = (e) => {
    if (filterVagas) {
      const filter = {
        usuario: id || '',
        page_size,
        page: pageSelected < 1 ? 1 : e.selected + 1,
        filter: filterVagas,
      };
      const filterFormatted = new URLSearchParams(filter);
      navigate(
        `/admin/vagas?${filterFormatted
          .toString()
          .replace(/\w+=&/g, '')
          .replace(/&\w+=$/, '')}`,
      );
    }
  };

  useEffect(() => {
    if (filterVagas) {
      const filter = {
        usuario: id || '',
        page_size,
        page: pageSelected || 1,
        status: filterVagas,
      };
      const filterFormatted = new URLSearchParams(filter);
      getProcessos(
        `?${filterFormatted
          .toString()
          .replace(/\w+=&/g, '')
          .replace(/&\w+=$/, '')}`,
      );
    }
  }, [filterVagas, pageSelected]);

  useEffect(() => {
    if (processos?.length > 0) {
      const rows_format = processos.map((processo) => {
        return {
          empresa: processo.usuario.nome.toLowerCase(),
          vaga: processo.nome_vaga.toLowerCase(),
          cidade: {
            color: processo.cidade?.nome.toLowerCase() === 'teresina' ? 'var(--blue-senac)' : 'var(--orange-senac)',
            text: processo.cidade?.nome,
          },
          status:
            processo.status === 'em analise' ? (
              'Em Análise'
            ) : processo.expirada && processo.status === 'ativada' ? (
              <span style={{ color: '#ff3333' }}>Expirada {processo.data_expiracao}</span>
            ) : processo.status === 'ativada' ? (
              'Ativada'
            ) : processo.status === 'rejeitada' ? (
              'Rejeitada'
            ) : processo.status === 'cancelado' ? (
              'Cancelado'
            ) : processo.status === 'encaminhada' ? (
              'Encaminhada'
            ) : processo.status === 'andamento' ? (
              'Em Andamento'
            ) : processo.status === 'finalizado' ? (
              'Finalizado'
            ) : (
              'Em análise'
            ),
          data: formatDateDjango(processo.created_at),
          handleClick: () => {
            navigate(`/admin/vagas/${processo.id}`);
          },
          actions: [
            // {
            //   action: 'Visualizar',
            //   color: '#9B9B9B',
            //   onClick: () => {
            //     navigate(`/admin/vagas/${processo.id}`);
            //   },
            // },
            {
              action: 'Ativar',
              color: '#004B8D',
              onClick: () => {
                setModalAtivar(true);
                setProcessoSelected(processo.id);
              },
              hide: filterVagas !== 'moderacao',
            },
            {
              action: 'Encaminhar',
              color: '#F68B1F',
              onClick: () => {
                setModalEncaminhar(true);
                setProcessoSelected(processo.id);
              },
              hide: filterVagas !== 'moderacao',
            },
            {
              action: 'Devolver',
              color: '#4EACBE',
              onClick: () => {
                setModalDevolver(true);
                setProcessoSelected(processo.id);
              },
              hide: filterVagas !== 'moderacao',
            },
            {
              action: 'Editar',
              color: '#656565',
              onClick: () => {
                navigate(`/admin/vagas/edit/${processo.id}`);
              },
              hide:
                filterVagas === 'rejeitada' ||
                (filterVagas === 'ativada' && userType !== 0 && userType !== 1) ||
                filterVagas === 'finalizado',
            },
            {
              action: 'Reabrir',
              color: '#656565',
              onClick: () => {
                setShowReOpenModal(true);
                setProcessoSelected(processo.id);
              },
              hide: filterVagas !== 'finalizado',
            },
            {
              action: 'Prorrogar',
              color: '#656565',
              onClick: () => {
                setShowProrrogarModal(true);
                setProcessoSelected(processo.id);
              },
              hide: !processo.expirada || filterVagas !== 'ativada',
            },
            {
              action: 'Finalizar',
              color: '#75BD10',
              onClick: () => {
                setModalFinalizar(true);
                setProcessoSelected(processo.id);
              },
              hide: filterVagas === 'finalizado',
            },
            {
              action: 'Inscritos',
              color: '#4EACBE',
              onClick: () => {
                navigate(`/admin/vagas/inscritos/${processo.id}`);
              },
              hide: filterVagas !== 'ativada' && filterVagas !== 'finalizado',
            },
          ],
        };
      });

      setRows(rows_format);
    } else {
      setRows([]);
    }
  }, [filterVagas, navigate, processos]);

  useEffect(() => {
    const filter = searchParams.get('filter');
    const page = searchParams.get('page');
    setPageSelected(page);
    setFilterVagas(filter || 'moderacao');
  }, [searchParams]);

  const searchVaga = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (filterVagas) {
      const filter = {
        usuario: id || '',
        page_size,
        page: '1',
        status: filterVagas,
        search: value,
      };
      const filterFormatted = new URLSearchParams(filter);
      getProcessos(
        `?${filterFormatted
          .toString()
          .replace(/\w+=&/g, '')
          .replace(/&\w+=$/, '')}`,
      );
    }
  };

  return (
    <TemplateAdmin page="Dashboard">
      <S.Cards>
        <Card
          orange
          onClick={() => {
            setFilterVagas('moderacao');
            navigate(id ? `/admin/empresas/vagas/${id}?filter=moderacao` : '/admin/vagas/?filter=moderacao');
          }}
        >
          <img src={CommentWhite} alt="" />
          <p>Moderação</p>
        </Card>
        <Card
          blue
          onClick={() => {
            setFilterVagas('ativada');
            navigate(id ? `/admin/empresas/vagas/${id}?filter=ativada` : '/admin/vagas/?filter=ativada');
          }}
        >
          <p>Vagas Ativas</p>
        </Card>
        <Card
          color="#75BD10"
          onClick={() => {
            setFilterVagas('finalizado');
            navigate(id ? `/admin/empresas/vagas/${id}?filter=finalizado` : '/admin/vagas/?filter=finalizado');
          }}
        >
          <p>Finalizados</p>
        </Card>
        <Card
          color="#4EACBE"
          onClick={() => {
            setFilterVagas('rejeitada');
            navigate(id ? `/admin/empresas/vagas/${id}?filter=rejeitada` : '/admin/vagas/?filter=rejeitada');
          }}
        >
          <p>Devolvidos</p>
        </Card>
        <Input border="var(--orange-senac)" placeholder="Buscar por Empresa, Cidade ou Vaga" onChange={searchVaga} />
      </S.Cards>

      <S.TableCard>
        <Card>
          <Table
            columns={['EMPRESA', 'VAGA', 'CIDADE', 'STATUS', 'DATA', '']}
            rows={rows}
            fontSize="14px"
            pageCount={pageProcessos}
            handlePageChange={handlePageChange}
            pageSelected={pageSelected || 1}
          />
        </Card>
      </S.TableCard>

      <ModalAtivar
        id={processoSelected}
        isOpen={modalAtivar}
        onClose={() => {
          setModalAtivar(false);
          setProcessoSelected(null);
          getProcessos();
        }}
      />
      <ModalEncaminhar
        id={processoSelected}
        isOpen={modalEncaminhar}
        onClose={() => {
          setModalEncaminhar(false);
          setProcessoSelected(null);
          getProcessos();
        }}
      />
      <ModalDevolver
        id={processoSelected}
        isOpen={modalDevolver}
        onClose={() => {
          setModalDevolver(false);
          setProcessoSelected(null);
          getProcessos();
        }}
      />
      <ModalFinalizar
        id={processoSelected}
        isOpen={modalFinalizar}
        onClose={() => {
          setModalFinalizar(false);
          setProcessoSelected(null);
          getProcessos();
        }}
      />

      <FormProrrogar
        processoSelected={processoSelected}
        isOpen={showProrrogarModal}
        onClose={() => {
          setShowProrrogarModal(false);
          setProcessoSelected(null);
          getProcessos();
        }}
        isAdmin
      />

      {showReOpenModal ? (
        <Modal
          closeButton
          isOpen={showReOpenModal}
          onClose={() => {
            setShowReOpenModal(false);
            setProcessoSelected(null);
          }}
          title="Deseja reabrir a vaga?"
        >
          <S.RowModal>
            <WhiteButton onClick={() => setShowReOpenModal(false)}>Não</WhiteButton>
            <BlueButton
              onClick={() =>
                navigate(`/admin/vagas/edit/${processoSelected}?processo=${processoSelected}&status=em analise`)
              }
            >
              Sim
            </BlueButton>
          </S.RowModal>
        </Modal>
      ) : null}
    </TemplateAdmin>
  );
};
