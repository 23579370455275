import { CadastroContext } from 'contexts/Empresas/Cadastro';
import { useContext } from 'react';

export function useCadastro() {
  const context = useContext(CadastroContext);

  if (!context) {
    throw new Error('useCadastro must be used within an CadastroProvider');
  }

  return context;
}
