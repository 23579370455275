import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 28px;
  color: var(--blue-senac);
  font-weight: 600;
  padding: 24px 0;
  width: 100%;
  text-align: left;
`;

export const Inputs = styled.div`
  * {
    display: grid;
    grid-gap: 10px;
  }

  .first-column__beneficios {
    grid-template-columns: 1fr 3.5fr;
  }

  .second-column__beneficios {
    grid-template-columns: 1fr 2.2fr;
  }

  .third-column__beneficios {
    grid-template-columns: 1fr 3fr;
  }

  @media screen and (max-width: 768px) {
    * {
      grid-gap: 0px;
      grid-template-columns: 1fr !important;
    }
  }
`;
