import { useState } from 'react';
import { headers } from 'api';
import { WhiteButton, BlueButton, TextArea } from 'components/atoms';
import { Modal } from 'components/molecules';
import { toast } from 'react-toastify';

import * as S from './styles';

export const ModalOcultar = ({ id, isOpen, onClose }) => {
  const [motivo, setMotivo] = useState('');

  const handleSubmit = () => {
    if (motivo.length <= 0) {
      toast.error('Você precisa preencher o campo de Feedback');
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/processo-aluno/${id}/ocultar/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ obs: motivo }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Aluno ocultado com sucesso.');
          onClose();
        } else {
          toast.error('Erro ao ocultar o aluno.');
        }
      })
      .catch(() => {
        toast.error('Erro ao ocultar o aluno.');
      });
  };

  return (
    <Modal title="Ocultar" isOpen={isOpen} onClose={onClose}>
      <S.ModalContent>
        <p>Vocêm tem certeza que deseja ocultar este aluno?</p>
        <TextArea placeholder="Feedback" value={motivo} onChange={(e) => setMotivo(e.target.value)} />
        <div className="buttons">
          <WhiteButton onClick={onClose}>Cancelar</WhiteButton>
          <BlueButton onClick={handleSubmit}>Confirmar</BlueButton>
        </div>
      </S.ModalContent>
    </Modal>
  );
};
