import styled from 'styled-components';
import BgLogin from 'assets/images/bg-login.jpg';

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${() => `url(${BgLogin})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--blue-senac);
    opacity: 0.65;
    z-index: 1;
  }

  .container {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    z-index: 2;
    position: relative;

    > form {
      width: 400px;
      max-width: 100%;
    }
  }
`;
