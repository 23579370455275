import styled from 'styled-components';

export const Title = styled.h1`
  margin-top: 38px;
  font-size: 28px;
  color: var(--blue-senac);
  font-weight: 600;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title__text {
    display: flex;
    align-items: center;

    img {
      margin-right: 24px;
    }

    h1 {
      font-size: 28px;
    }
  }

  button {
    width: 42px;
    height: 42px;
    font-size: 20px;
  }
`;

export const Inputs = styled.div`
  * {
    display: grid;
    grid-gap: 10px;
  }

  .first-column__experiencia-profissional {
    grid-template-columns: 1fr;
  }

  .second-column__experiencia-profissional {
    grid-template-columns: 1fr;
  }

  .third-column__experiencia-profissional {
    grid-template-columns: 1fr 1fr;
  }

  .fourth-column__experiencia-profissional {
    grid-template-columns: 1fr;
  }

  .fifth-column__experiencia-profissional {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    * {
      grid-gap: 0px;
      grid-template-columns: 1fr !important;
    }
  }
`;
