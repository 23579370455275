import { useState, useEffect, useMemo } from 'react';
import { Table } from 'components/organisms';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { cnpjCpfMaskRead, moneyMask, phoneMask } from 'helpers';
import { useNavigate } from 'react-router-dom';

import * as S from '../styles';

import ArrowLeft from 'assets/icons/arrow-left.svg';
import { BlueButton } from 'components/atoms';

export const Consolidado = ({ relatoriosMes, relatoriosAno, MonthYearSelects }) => {
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = useMemo(() => 20);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPageSelected(page.selected + 1);
  };

  const getInitialData = async (page) => {
    const params = new URLSearchParams({
      page_size: pageSize,
      page: page || pageSelected,
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/consolidado/?${params}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    if (response.ok) {
      const data = await response.json();
      setPageCount(data.count / pageSize);
      setPageSelected(1);
      const rowsFormat = data?.results?.map((item) => {
        return {
          ...item,
          mês: item.mes,
          'cnpj/cpf': cnpjCpfMaskRead(item.cpf_cnpj_empresa),
          'inscrção municipal': item.inscricao_municipal,
          'razão social': item.razao_social,
          // @todo: adicionar nome fantasia
          'nome fantasia': item.nome_fantasia,
          'ramo de atividade': item.ramo_atividade,
          'qtd funcionários': item.quantidade_funcionario,
          telefone: phoneMask(item.telefone),
          celular: phoneMask(item.celular),
          'tipo da empresa': item.tipo_empresa,
          'e-mail': item.email_empresa,
          endereço: item.endereco_empresa,
          número: item.numero_empresa,
          complemento: item.complemento_empresa,
          bairro: item.bairro_empresa,
          cidade: item.cidade_empresa,
          cep: item.cep_empresa,
          'tipo de endereço': item.tipo_endereco_empresa,
          'nome do responsável': item.nome_responsavel,
          'telefone do responsável': phoneMask(item.telefone_responsavel),
          'quantidade de empresas': item.quantidade_empresa,
          salário: moneyMask(item.salario),
          'valor mínimo': moneyMask(item.valor_minimo),
          'valor máximo': moneyMask(item.valor_maximo),
          // @todo: adicionar dias da vaga
          // @todo: adicionar horários da vaga
          'cidade da vaga': item.cidade,
          'vale transporte': item.vale_transporte ? 'Sim' : 'Não',
          'vale alimentação/refeição': item.vale_transporte ? 'Sim' : 'Não',
          'assistência médica': item.assistencia_medica ? 'Sim' : 'Não',
          'status da vaga': item.status,
          'nome do aluno': item.nome_aluno,
          'cpf do aluno': cnpjCpfMaskRead(item.cpf_cnpj_aluno),
          'qtd de encaminhamento': item.qtde_encaminhamento,
          'qtd de contratação': item.qtde_contratacao,
          pcd: item.pcd ? 'Sim' : 'Não',
          tipo: item.pcd_tipo,
        };
      });
      setRows(rowsFormat);
    } else {
      toast.error('Erro ao sincronizar os relatórios');
      setRows([]);
    }
  };

  useEffect(() => {
    getInitialData();
  }, [pageSelected]);

  return (
    <>
      <S.GoBack onClick={() => navigate(-1)}>
        <img src={ArrowLeft} alt="" />
        Voltar
      </S.GoBack>

      <S.SelectSection>
        <MonthYearSelects />
        <BlueButton onClick={() => getInitialData(1)}>Buscar</BlueButton>
      </S.SelectSection>

      <S.TableWrapper>
        <Table
          columns={[
            'ANO',
            'MÊS',
            'CNPJ/CPF',
            'INCRIÇÃO MUNICIPAL',
            'RAZÃO SOCIAL',
            'NOME FANTASIA',
            'RAMO DE ATIVIDADE',
            'QTD FUNCTIONÁRIOS',
            'TELEFONE',
            'CELULAR',
            'TIPO DA EMPRESA',
            'E-MAIL',
            'ENDEREÇO',
            'NÚMERO',
            'COMPLEMENTO',
            'BAIRRO',
            'CIDADE',
            'CEP',
            'TIPO DE ENDEREÇO',
            'NOME DO RESPONSÁVEL',
            'TELEFONE DO RESPONSÁVEL',
            'QUANTIDADE DE EMPRESAS',
            'SALÁRIO',
            'VALOR MÍNIMO',
            'VALOR MÁXIMO',
            'DIAS',
            'HORÁRIOS',
            'ESCOLARIDADE',
            'CIDADE DA VAGA',
            'SEGMENTO',
            'VALE TRANSPORTE',
            'VALE ALIMENTAÇÃO/REFEIÇÃO',
            'ASSISTÊNCIA MÉDICA',
            'STATUS DA VAGA',
            'NOME DO ALUNO',
            'CPF DO ALUNO',
            'QTD DE ENCAMINHAMENTO',
            'QTD DE CONTRATAÇÃO',
            'PCD',
            'TIPO',
          ]}
          rows={rows}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          pageSelected={pageSelected}
        />
      </S.TableWrapper>
    </>
  );
};
