import { baseUrl, headers } from 'api';

export const getYears = async () => {
  const response = await fetch(`${baseUrl}/dashboard/anos/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });
  const data = await response.json();

  return data.anos;
};

export const getIndicators = async (params) => {
  const response = await fetch(`${baseUrl}/dashboard/indicadores/${params || ''}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });
  const data = await response.json();

  return data;
};

export const getRankingVacancies = async (params) => {
  const response = await fetch(`${baseUrl}/dashboard/vagas-segmento/${params || ''}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });
  const data = await response.json();

  return data?.results;
};

export const getGraphic = async (params) => {
  const response = await fetch(`${baseUrl}/dashboard/contratacoes/${params || ''}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });
  const data = await response.json();

  return data;
};

export const getResume = async () => {
  const response = await fetch(`${baseUrl}/dashboard/resumo/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });
  const data = await response.json();

  return data;
};
