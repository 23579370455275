import { useState } from 'react';
import { baseUrl, headers } from 'api';
import { WhiteButton, BlueButton, TextArea } from 'components/atoms';
import { Modal } from 'components/molecules';
import { toast } from 'react-toastify';

import * as S from './styles';

export const ModalFinalizar = ({ id, isOpen, onClose }) => {
  const [showMotivo, setShowMotivo] = useState(false);
  const [motivo, setMotivo] = useState('');
  const handleSubmit = () => {
    fetch(`${baseUrl}/processo/${id}/finalizar/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ observacao: motivo }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Vaga finalizada com sucesso!');
          setShowMotivo(false);
          onClose();
        } else if (response.status === 400) {
          toast.error('Vaga já finalizada!');
        } else {
          toast.error('Erro ao finalizar vaga!');
        }
      })
      .catch(() => {
        toast.error('Erro ao finalizar vaga!');
      });
  };

  return (
    <>
      {!showMotivo ? (
        <Modal title="Deseja finalizar a vaga?" isOpen={isOpen} onClose={onClose}>
          <S.ModalContent>
            <div className="buttons">
              <WhiteButton onClick={onClose}>Não</WhiteButton>
              <BlueButton onClick={() => setShowMotivo(true)}>Sim</BlueButton>
            </div>
          </S.ModalContent>
        </Modal>
      ) : (
        <Modal
          title="Finalizar vaga"
          isOpen={showMotivo}
          onClose={() => {
            setShowMotivo(false);
            onClose();
          }}
        >
          <S.ModalContent>
            <TextArea placeholder="Motivo" value={motivo} onChange={(e) => setMotivo(e.target.value)} />
            <div className="buttons">
              <WhiteButton
                onClick={() => {
                  setShowMotivo(false);
                  onClose();
                }}
              >
                Cancelar
              </WhiteButton>
              <BlueButton onClick={() => handleSubmit()}>Confirmar</BlueButton>
            </div>
          </S.ModalContent>
        </Modal>
      )}
    </>
  );
};
