import { useEffect, useState } from 'react';
import { TemplateCurriculum } from 'components/templates';
import { Loading } from 'components/molecules';

export const PrintCurriculo = ({ curriculo, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (curriculo) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [curriculo]);

  useEffect(() => {
    if (!isLoading) {
      window.print();
    }
  }, [isLoading]);

  if (curriculo)
    return (
      <>
        {isLoading ? <Loading text="Carregando dados..." /> : null}
        <TemplateCurriculum curriculo={curriculo} className={className} />
      </>
    );
  return null;
};
