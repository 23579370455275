import styled from 'styled-components';

export const GoBack = styled.a`
  color: #656565;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 50%;
    background: var(--blue-senac);
    padding: 8px;
    margin-right: 8px;
  }
`;
