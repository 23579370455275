import { useState, useEffect } from 'react';
import { getCidades, useProcessos } from 'hooks';
import { Input, BlueButton, Select } from 'components/atoms';

import * as S from './styles';

import SearchIcon from 'assets/icons/search.svg';

export const SearchVacancies = ({ disabled }) => {
  const [cidades, setCidades] = useState([]);
  const [values, setValues] = useState({
    search: '',
    cidade: '',
  });
  const { getProcessosEmpresa } = useProcessos();

  const getInitialData = async () => {
    const cities = await getCidades();
    const citiesData = await cities.json();
    const citiesFormatted = citiesData.map((city) => {
      return {
        label: city.nome,
        value: String(city.id),
      };
    });
    if (citiesFormatted) setCidades(citiesFormatted);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(values);
    getProcessosEmpresa(`?${params.toString()}`);
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <S.Content className={disabled && 'disabled'}>
      <S.Title>Filtros</S.Title>
      <S.Form className="row" onSubmit={handleSubmit}>
        <Input placeholder="Nome da vaga" name="search" value={values.search} onChange={handleChange} />
        <Select title="Cidade" options={cidades} name="cidade" actualSelected={values.cidade} onChange={handleChange} />
        <BlueButton style={{ margin: '8px 0' }} type="submit">
          <img src={SearchIcon} alt="" width="14" height="14" style={{ marginRight: 8 }} />
          BUSCAR
        </BlueButton>
      </S.Form>
    </S.Content>
  );
};
