import { useState } from 'react';
import { baseUrl, headers } from 'api';
import { WhiteButton, BlueButton, TextArea, Select, Input } from 'components/atoms';
import { Modal } from 'components/molecules';
import { toast } from 'react-toastify';
import { useProcessos } from 'hooks';

import * as S from './styles';

export const ModalDesclassificar = ({ aluno, isOpen, onClose }) => {
  const { getCurriculumByCompany } = useProcessos();
  const [obs, setObs] = useState('');
  const [motivo, setMotivo] = useState('');
  const [motivoDetalhe, setMotivoDetalhe] = useState('');
  const motivos = [
    {
      label: 'Sem experiência profissional',
      value: 'Sem experiência profissional',
    },
    {
      label: 'Baixo conhecimento técnico',
      value: 'Baixo conhecimento técnico',
    },
    {
      label: 'Sem contato com o candidato',
      value: 'Sem contato com o candidato',
    },
    {
      label: 'Reprovado(a) na entrevista',
      value: 'Reprovado(a) na entrevista',
    },
    {
      label: 'Não compareceu a entrevista',
      value: 'Não compareceu a entrevista',
    },
    {
      label: 'Currículo não atende ao perfil desejado',
      value: 'Currículo não atende ao perfil desejado',
    },
  ];

  const handleVerifyFields = () => {
    if (motivo?.length < 3) {
      toast.error('Selecione um motivo!');
      return false;
    }
    if (motivo === 'Reprovado(a) na entrevista' || motivo === 'Currículo não atende ao perfil desejado') {
      if (motivoDetalhe?.length < 3) {
        toast.error('Preencha o campo de feedback!');
        return false;
      }
    }
    return true;
  };

  const handleContratar = () => {
    if (!handleVerifyFields()) {
      return;
    }
    fetch(`${baseUrl}/processo-aluno/${aluno.id}/desclassificar/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({ obs }),
    }).then((response) => {
      if (response.ok) {
        toast.success('Aluno desclassificado!');
        setObs('');
        setMotivo('');
        setMotivoDetalhe('');
        getCurriculumByCompany(`?processo=${aluno.processo.id}`);
        onClose();
      } else {
        toast.error('Erro ao desclassificar aluno!');
      }
    });
  };

  return (
    <Modal title="Desclassificar currículo" isOpen={isOpen} onClose={onClose}>
      <S.ModalContent>
        <p>Você tem certeza que deseja desclassificar este currículo?</p>
        <Select
          title="Motivo*"
          options={motivos}
          actualSelected={motivo}
          onChange={(e) => setMotivo(e.target.value)}
          required
        />
        {(motivo === 'Reprovado(a) na entrevista' || motivo === 'Currículo não atende ao perfil desejado') && (
          <Input
            placeholder="Seu feedback é muito importante para a formação de futuros profissionais*"
            required
            value={motivoDetalhe}
            onChange={(e) => setMotivoDetalhe(e.target.value)}
          />
        )}
        <TextArea placeholder="Observação" value={obs} onChange={(e) => setObs(e.target.value)} />
        <div className="buttons">
          <WhiteButton onClick={onClose}>Cancelar</WhiteButton>
          <BlueButton onClick={() => handleContratar()}>Confirmar</BlueButton>
        </div>
      </S.ModalContent>
    </Modal>
  );
};
