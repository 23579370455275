import styled from 'styled-components';

export const Cards = styled.div`
  display: flex;
  width: 100%;

  > div,
  a > div {
    width: max-content;
    margin: 0 24px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
      object-fit: contain;
    }
  }

  > div:last-child {
    margin: 0 24px 0 auto;
    width: 310px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
      padding: 12px 16px;
      margin-bottom: 24px;
    }
  }
`;

export const TableCard = styled.div`
  margin-top: 24px;
  width: calc(100% - 32px);
  max-width: 100%;
  overflow: hidden;
  > div {
    overflow: auto;
  }
  td {
    &:nth-child(1),
    &:nth-child(2) {
      text-transform: capitalize;
    }
    &.actions {
      max-width: 600px !important;
    }
  }

  @media screen and (max-width: 1280px) {
    td {
      max-width: 120px !important;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  margin-top: 24px;
  width: calc(100% - 32px);

  .row {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: calc(50% - 32px);
      margin: 8px 16px;
    }
  }

  button {
    width: max-content;
    min-width: 200px;
    max-width: 100%;
    margin: 16px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    .row {
      > div {
        width: 100%;
        margin: 0;
      }
    }

    button {
      width: 100%;
      margin: 24px 0;
    }
  }
`;

export const RowModal = styled.div`
  display: flex;
  justify-content: space-around;
  button {
    width: 45%;
    margin: 16px 0;
  }
`;
