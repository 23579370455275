import { TemplateAlunos } from 'components/templates';
import { VagaCard } from '../components/VagaCard';
import React, { useEffect } from 'react';
import { useProcessos } from 'hooks';
import { Link } from 'react-router-dom';

import * as S from './styles';

import ProfileUser from 'assets/icons/add-user.svg';
import Home from 'assets/icons/home.svg';
import { Loading } from 'components/molecules';

export const VagasInscritas = () => {
  const { processos, getProcessosEmpresa, isLoading } = useProcessos();

  useEffect(() => {
    getProcessosEmpresa('?inscricao=true');
  }, []);

  return (
    <TemplateAlunos title="PORTAL DO CANDIDATO">
      <S.Row>
        <Link to="/alunos/vagas">
          <S.Banner>
            <img src={Home} alt="" />
            Início
          </S.Banner>
        </Link>
        <S.Banner>
          <img src={ProfileUser} alt="" />
          Vagas inscritas
        </S.Banner>
      </S.Row>
      {isLoading ? (
        <Loading text="Carregando processos..." />
      ) : (
        processos?.map((item) => <VagaCard vaga={item} hasCandidated />)
      )}
    </TemplateAlunos>
  );
};
