import styled from 'styled-components';
import BgLogin from 'assets/images/bg-login.jpg';

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${() => `url(${BgLogin})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--blue-senac);
    opacity: 0.65;
    z-index: 1;
  }

  .container {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    z-index: 2;
    position: relative;

    > form {
      width: 400px;
      max-width: 100%;
      > div > div {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 80px;
  object-fit: contain;
  object-position: center;
  margin: 16px 0;
`;

export const Title = styled.p`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin: 8px 0;
`;

export const Forgot = styled.p`
  font-size: 14px;
  color: #656565;
  margin: 8px 0 8px auto;
  display: flex;
  width: max-content;
  cursor: pointer;
`;

export const Text = styled.p`
  width: max-content;
  font-size: 14px;
  font-weight: 600;
  color: #656565;
  margin: 8px auto;
  display: flex;
  align-items: center;
`;
