import { Header, NavigationAlunos } from 'components/organisms';
import { WhatsappIcon } from 'components/atoms';

export const TemplateAlunos = ({ title, className, children }) => {
  return (
    <div className={className || ''}>
      <Header title={title} />
      <div className="container">
        <NavigationAlunos />
        {children}
      </div>
      <WhatsappIcon />
    </div>
  );
};
