import { deleteAreaInteresse } from 'pages/Alunos/VagasCurriculo/AreaInteresse/requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import Trash from 'assets/icons/trash.svg';

export const CardAreaInteresse = ({ id, title, areaInteresse, setAreaInteresse }) => {
  const deleteItem = async () => {
    const response = await deleteAreaInteresse(id);

    if (response.status === 200) {
      const data = await response.json();
      setAreaInteresse(areaInteresse.filter((item) => item.id !== data.id));
      toast.success('Área de interesse deletada com sucesso!');
    } else {
      toast.error('Erro ao deletar Área de interesse.');
    }
  };

  return (
    <S.Card>
      <S.Text>
        <S.Title>{title}</S.Title>
      </S.Text>

      <S.ActionButtons>
        <S.ActionButtonDelete type="button" onClick={() => deleteItem()}>
          <img src={Trash} alt="Ícone de lixeira" />
        </S.ActionButtonDelete>
      </S.ActionButtons>
    </S.Card>
  );
};
