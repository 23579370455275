import { baseUrl, headers } from 'api';

export const getFormacaoAcademica = async () => {
  const response = await fetch(`${baseUrl}/curriculo/formacao-academica/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};

export const addFormacaoAcademica = async (preFormacaoAcademica) => {
  const response = await fetch(`${baseUrl}/curriculo/formacao-academica/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...preFormacaoAcademica }),
  });

  return response;
};

export const editFormacaoAcademica = async (formacaoAcademicaItem) => {
  const response = await fetch(`${baseUrl}/curriculo/formacao-academica/${formacaoAcademicaItem.id}/`, {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({ ...formacaoAcademicaItem }),
  });

  return response;
};

export const deleteFormacaoAcademica = async (formacaoAcademicaItem) => {
  const response = await fetch(`${baseUrl}/curriculo/formacao-academica/${formacaoAcademicaItem.id}/`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
