import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  border-radius: 16px;
  background: #f3f3f3;
  margin: 8px 0;
  padding: 15px;
  position: relative;
  display: flex;
  align-items: center;

  > div {
    width: 60%;
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    > div {
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-size: 24px;
  color: var(--blue-senac);
  font-weight: 600;
  cursor: pointer;
`;

export const Modelo = styled.p`
  color: #656565;
  font-size: 18px;
`;

export const Cidade = styled.p`
  color: var(--orange-senac);
  font-size: 18px;
`;

export const CandidateButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #e8e8e8;
  border-radius: 0 15px 15px 0;
  padding: 24px;
  border: none;
  color: #656565;
  font-weight: 700;
  text-transform: uppercase;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: #ff3333;
  }

  &.second-button {
    right: 150px;
    border-radius: 0;
    border-right: 2px solid #d1d1d1;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    top: auto;
    bottom: -15px;
    left: -15px;
    right: auto;
    height: 60px;
    width: calc(100vw - 30px);
    border-radius: 0 0 15px 15px;
    font-size: 18px;
    padding: 16px;
  }
`;

export const CandidateButtonWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 100vw !important;
    position: relative;
    bottom: -15px;
    border-radius: 0 0 15px 15px;
    height: 60px;
    > button {
      width: calc(50% + 15px);
      right: -15px;
      left: auto;
      bottom: 0;
      position: absolute;
      border-radius: 0 0 15px 0;
      &.second-button {
        left: -15px;
        right: auto;
        border-radius: 0 0 0 15px;
      }
    }
  }
`;

export const RowModal = styled.div`
  display: flex;
  justify-content: space-around;
  button {
    width: 45%;
    margin: 16px 0;
  }
`;

export const ActionButton = styled.button`
  height: max-content;
  background: ${({ color }) => (color ? color : '#656565')};
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 12px;
  text-align: center;
  margin: 4px;
  display: ${({ hide }) => (hide ? 'none' : 'initial')};
`;
