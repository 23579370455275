import styled from 'styled-components';

export const Text = styled.p`
  position: fixed;
  width: 100%;
  height: max-content;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  margin: auto 0;
  font-size: 24px;
  z-index: 9999;
  justify-content: center;
  color: #fff;
`;
