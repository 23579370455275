export const moneyMask = (value) => {
  if (!value) {
    return '';
  }

  if (typeof value === 'string') {
    value = value.replaceAll('R$ ', '').replaceAll('.', '').replace(',', '.').replace(/\D/g, '');
    value = Number(value);
  } else if (typeof value === 'number') {
    value = parseFloat(value * 100);
  }

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(value) / 100);

  return value ? `R$ ${result}` : '';
};

export const removeMoneyMask = (value) => {
  const result = value?.replaceAll('R$ ', '').replaceAll('.', '').replace(',', '.');
  return parseFloat(result);
};
