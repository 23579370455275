import { deleteExperienciaProfissional } from 'pages/Alunos/VagasCurriculo/ExperienciaProfissional/requests';
import { toast } from 'react-toastify';

import * as S from './styles';

import Trash from 'assets/icons/trash.svg';
import Pen from 'assets/icons/pen.svg';

export const CardExperienciaProfissional = ({
  id,
  funcao,
  empresa,
  data_inicio,
  data_fim,
  experienciaProfissional,
  setExperienciaProfissional,
  setPreExperienciaProfissional,
}) => {
  const checkIfAlreadyEditing = () => {
    const experienciaIndex = experienciaProfissional.findIndex((item) => item.editing);
    return experienciaIndex > -1 ? true : false;
  };

  const deleteItem = async (id) => {
    if (checkIfAlreadyEditing()) {
      alert('Termine a edição antes de deletar.');
    } else {
      const formacaoIndex = experienciaProfissional.findIndex((item) => item.id === id);
      const response = await deleteExperienciaProfissional(experienciaProfissional[formacaoIndex]);

      if (response.status === 200) {
        const data = await response.json();
        setExperienciaProfissional(experienciaProfissional.filter((item) => item.id !== data.id));
        toast.success('Formação acadêmica deletada com sucesso!');
      } else {
        toast.error('Erro ao deletar formação acadêmica.');
      }
    }
  };

  const editItem = (id) => {
    if (checkIfAlreadyEditing()) {
      alert('Termine a edição antes de editar.');
    } else {
      const experienciaIndex = experienciaProfissional.findIndex((item) => item.id === id);
      const newExperienciaProfissional = [...experienciaProfissional];

      newExperienciaProfissional[experienciaIndex] = {
        ...newExperienciaProfissional[experienciaIndex],
        editing: true,
      };

      setExperienciaProfissional(newExperienciaProfissional);
      setPreExperienciaProfissional(newExperienciaProfissional[experienciaIndex]);
    }
  };

  return (
    <S.Card>
      <S.Text>
        <S.Title>{`${funcao} - ${empresa}`}</S.Title>
        {data_inicio && <S.Description>{`Período: ${data_inicio} à ${data_fim || 'atualmente'}`}</S.Description>}
      </S.Text>

      <S.ActionButtons>
        <S.ActionButtonDelete type="button" onClick={() => deleteItem(id)}>
          <img src={Trash} alt="Ícone de lixeira" />
        </S.ActionButtonDelete>

        <S.ActionButtonEdit type="button" onClick={() => editItem(id)}>
          <img src={Pen} alt="Ícone de lápis" />
        </S.ActionButtonEdit>
      </S.ActionButtons>
    </S.Card>
  );
};
