import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
    --orange-senac: #F68B1F;
    --blue-senac: #004B8D;
    --blue-senac-hover: #003E75;
  }

  body {
    background-color: #FFF;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .Toastify {
    .w-560 {
      max-width: 95vw;
      width: 560px;
      position: absolute;
      right: 0;
    }

    @media screen and (max-width: 768px) {
      .w-560 {
        right: 4px;
      }
    }
  }
`;
