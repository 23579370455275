export function uniqByKeepFirst(a, key) {
  if (a) {
    let seen = new Set();
    return a.filter((item) => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }
}

export function uniqByKeepLast(a, key) {
  if (a) {
    return [...new Map(a.map((x) => [key(x), x])).values()];
  }
}
