import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  align-items: center;
`;

export const Button = styled.button`
  padding: 24px;
  border: 2px solid #fff;
  border-radius: 15px;
  background: transparent;
  width: 100%;
  height: 100%;

  &.orange {
    border-color: var(--orange-senac);
  }

  > img {
    margin: 0;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  height: 28px;
  margin: 8px 0;
`;

export const Subtext = styled.p`
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: 300;
`;
