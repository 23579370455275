import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Table } from 'components/organisms';
import { Loading } from 'components/molecules';
import { baseUrl, headers } from 'api';

import * as S from '../styles';

export const List = ({ relatoriosMes, relatoriosAno, MonthYearSelects }) => {
  const navigate = useNavigate();
  const [loadingMessage, setLoadingMessage] = useState();

  async function handleExportCsv(relatory) {
    if (!relatory) return;

    setLoadingMessage(`Gerando CSV de ${relatory}...`);
    const params = new URLSearchParams({
      ano: relatoriosAno,
      mes: relatoriosMes,
    });
    const response = await fetch(`${baseUrl}/dashboard/relatorio/${relatory}/csv/?${params}`, {
      methdo: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    if (response.ok) {
      const data = await response.blob();
      const a = document.createElement('a');
      const url = URL.createObjectURL(data);
      a.style.display = 'none';
      a.href = url;
      a.download = `relatorio-${relatory}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      toast.error('Erro ao gerar CSV.');
    }
    setLoadingMessage(undefined);
  }

  const rows = useMemo(() => {
    return [
      {
        'listagem de relatórios': 'Encaminhamentos',
        handleClick: () => navigate('/admin/relatorios/encaminhamentos'),
        actions: [
          {
            action: 'CSV',
            color: '#004B8D',
            onClick: () => {
              handleExportCsv('encaminhamento');
            },
          },
        ],
      },
      {
        'listagem de relatórios': 'Contratação',
        handleClick: () => navigate('/admin/relatorios/contratacao'),
        actions: [
          {
            action: 'CSV',
            color: '#004B8D',
            onClick: () => {
              handleExportCsv('contratacao');
            },
          },
        ],
      },
      {
        'listagem de relatórios': 'Alunos',
        handleClick: () => navigate('/admin/relatorios/alunos'),
        actions: [
          {
            action: 'CSV',
            color: '#004B8D',
            onClick: () => {
              handleExportCsv('alunos');
            },
          },
        ],
      },
      {
        'listagem de relatórios': 'Empresas',
        handleClick: () => navigate('/admin/relatorios/empresas'),
        actions: [
          {
            action: 'CSV',
            color: '#004B8D',
            onClick: () => {
              handleExportCsv('empresas');
            },
          },
        ],
      },
      {
        'listagem de relatórios': 'Vagas',
        handleClick: () => navigate('/admin/relatorios/vagas'),
        actions: [
          {
            action: 'CSV',
            color: '#004B8D',
            onClick: () => {
              handleExportCsv('vagas');
            },
          },
        ],
      },
      {
        'listagem de relatórios': 'Consolidado',
        handleClick: () => navigate('/admin/relatorios/consolidado'),
        actions: [
          {
            action: 'CSV',
            color: '#004B8D',
            onClick: () => {
              handleExportCsv('consolidado');
            },
          },
        ],
      },
      {
        'listagem de relatórios': 'Usuários',
        handleClick: () => navigate('/admin/relatorios/usuarios'),
        actions: [
          {
            action: 'CSV',
            color: '#004B8D',
            onClick: () => {
              handleExportCsv('usuarios');
            },
          },
        ],
      },
    ];
  }, [relatoriosAno, relatoriosMes]);

  return (
    <S.TableWrapper>
      {loadingMessage && <Loading text={loadingMessage} />}

      <MonthYearSelects />
      <Table columns={['LISTAGEM DE RELATÓRIOS', '']} rows={rows} />
    </S.TableWrapper>
  );
};
