import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Input, BlueButton } from 'components/atoms';
import { baseUrl, headers } from 'api';

import * as S from './styles';

import SenacLogo from 'assets/images/senac-logo.png';
import Shield from 'assets/icons/shield.svg';
import { toast } from 'react-toastify';

export const RecuperarSenha = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [values, setValues] = useState({
    token: '',
    senha1: '',
    senha2: '',
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${baseUrl}/usuarios/nova-senha/`, {
      method: 'POST',
      headers: { ...headers },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Senha alterada com sucesso!');
          navigate('/');
        } else {
          toast.error('Token inválido!');
        }
      })
      .catch(() => {
        toast.error('Token inválido!');
      });
  };

  useEffect(() => {
    if (token) {
      setValues({ ...values, token });
    }
  }, [token]);

  return (
    <S.Content>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <Card>
            <S.Logo src={SenacLogo} alt="Senac" />
            <S.Title>Banco de Oportunidades</S.Title>
            <Input
              name="senha1"
              value={values.senha1}
              type="password"
              placeholder="Nova Senha"
              icon={Shield}
              onChange={handleChange}
            />
            <Input
              name="senha2"
              value={values.senha2}
              type="password"
              placeholder="Repetir Nova Senha"
              icon={Shield}
              onChange={handleChange}
            />
            <BlueButton
              type="submit"
              style={{ margin: '16px 0' }}
              disabled={values.senha1 !== values.senha2 && values.senha1.length > 0}
            >
              Enviar
            </BlueButton>
          </Card>
        </form>
      </div>
    </S.Content>
  );
};
