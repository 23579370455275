import { useEffect, useState } from 'react';
import { Modal } from 'components/molecules';
import { baseUrl, headers } from 'api';
import { BlueButton } from 'components/atoms';
import { useAuth } from 'hooks';

import * as S from './styles';
import { toast } from 'react-toastify';

export const FormNps = ({ isOpen, onClose, processoSelected }) => {
  const { user } = useAuth();
  const [questions, setQuestions] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [answersText, setAnswersText] = useState([]);

  const getInitialData = async () => {
    const response = await fetch(`${baseUrl}/pergunta/`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    const data = await response.json();
    setQuestions(data);
  };

  const handleChangeCheckbox = (e) => {
    const answer = {
      resposta_numerico: Number(e.target.value),
      pergunta: Number(e.target.name),
    };
    const questionAnswered = answers.find((item) => item.pergunta === answer.pergunta);

    if (questionAnswered) {
      const newAnswers = answers.filter((item) => item.pergunta !== answer.pergunta);
      setAnswers([...newAnswers, answer]);
    } else {
      setAnswers([...answers, answer]);
    }
  };

  const handleChangeText = (e) => {
    const answer = {
      resposta_texto: e.target.value,
      pergunta: e.target.name,
    };
    const questionAnswered = answersText.find((item) => item.pergunta === answer.pergunta);

    if (questionAnswered) {
      const newAnswers = answersText.filter((item) => item.pergunta !== answer.pergunta);
      setAnswersText([...newAnswers, answer]);
    } else {
      setAnswersText([...answersText, answer]);
    }
  };

  const submitAnswer = async (body) => {
    const response = await fetch(`${baseUrl}/resposta/`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      toast.success('Sucesso ao enviar feedback!', { toastId: 'formNps' });
      onClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = `${('0' + new Date().getDate()).slice(-2)}/${('0' + (new Date().getMonth() + 1)).slice(
      -2,
    )}/${new Date().getFullYear()}`;

    const body = {
      usuario: user.id,
      data,
      ativo: true,
      modulo: 'empresa',
      comentario: false,
      processo: processoSelected,
    };

    let dataToSubmit = [];

    if (answers.length > 0) {
      answers.map((item) => {
        dataToSubmit.push({
          ...item,
          ...body,
          resposta_texto: null,
        });
      });
    }
    if (answersText.length > 0) {
      answersText.map((item) => {
        dataToSubmit.push({
          ...item,
          ...body,
          resposta_numerico: null,
        });
      });
    }

    submitAnswer(dataToSubmit);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <Modal closeButton isOpen={isOpen} onClose={onClose} title="Nos dê seu feedback!">
        <S.SubTitle>
          Para continuarmos melhorando, precisamos saber seu nível de satisfação! Em uma escala de “0” a “10”, qual é o
          seu nível de satisfação “0” para totalmente insatisfeito e “10” para totalmente satisfeito.
        </S.SubTitle>

        {questions?.map((question, index) => (
          <div key={question.id}>
            <S.Question>
              {index + 1} - {question.titulo}
            </S.Question>
            {question.tipo_numerico ? (
              <S.Answers>
                {[...Array(11)].map((_, i) => (
                  <div>
                    <input
                      id={question.id + '-' + i}
                      type="radio"
                      name={question.id}
                      value={i}
                      onChange={handleChangeCheckbox}
                      checked={answers.some((item) => item.pergunta === question.id && item.resposta_numerico === i)}
                    />
                    <label htmlFor={question.id + '-' + i}>{i}</label>
                  </div>
                ))}
              </S.Answers>
            ) : (
              <S.TextArea maxLength={250} onChange={handleChangeText} name={question.id} />
            )}
          </div>
        ))}

        <S.Submit>
          <BlueButton onClick={handleSubmit}>Enviar</BlueButton>
        </S.Submit>
      </Modal>
    </>
  );
};
