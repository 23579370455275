import { baseUrl, headers } from 'api';

export const getProcesso = async (id) => {
  const response = await fetch(`${baseUrl}/processo/${id}/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};
