import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SearchVacancies } from '..';

import * as S from './styles';

import Curriculo from 'assets/icons/curriculo.svg';
import ProfileUser from 'assets/icons/add-user.svg';
import Finalizados from 'assets/icons/finalizados.svg';

export const NavigationAlunos = () => {
  const { pathname } = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (pathname !== '/alunos/vagas') {
      setIsDisabled(true);
    }
  }, [pathname]);

  return (
    <div>
      <S.Banners>
        <Link to="/alunos/vagas/curriculo">
          <div className="curriculo">
            <img src={Curriculo} alt="Ícone de currículo" />
            Currículo
          </div>
        </Link>
        <Link to="/alunos/vagas/inscritas">
          <div className="inscritos">
            <img src={ProfileUser} alt="Ícone de vagas inscritas" />
            Inscritas
          </div>
        </Link>
        <Link to="/alunos/vagas/finalizadas">
          <div>
            <img src={Finalizados} alt="Ícone de vagas finalizadas" />
            Finalizadas
          </div>
        </Link>
        <SearchVacancies disabled={isDisabled} />
      </S.Banners>
    </div>
  );
};
