import { Header, NavigationEmpresas } from 'components/organisms';
import { WhatsappIcon } from 'components/atoms';

export const TemplateEmpresas = ({ title, className, children }) => {
  return (
    <div className={className}>
      <Header title={title} />
      <div className="container">
        <NavigationEmpresas />
        {children}
      </div>
      <WhatsappIcon />
    </div>
  );
};
