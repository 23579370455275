import { Card, Overlay } from 'components/atoms';

import * as S from './styles';
import CloseIcon from 'assets/icons/close.svg';

export const Modal = ({ children, onClose, title, isOpen, closeButton }) => {
  return (
    <S.Wrapper className={isOpen ? 'open' : ''}>
      <Card className="card" orange>
        <S.Title>
          {title}
          {closeButton && <S.Close src={CloseIcon} onClick={onClose} />}
        </S.Title>
        {children}
      </Card>
      <Overlay onClick={onClose} />
    </S.Wrapper>
  );
};
