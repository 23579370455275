import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

export const GraficoStatus = ({ data }) => {
  const [series, setSeries] = useState([data]);
  const options = {
    stroke: { lineCap: 'round' },
    colors: ['#004B8D'],
    labels: ['Contratação'],
  };

  useEffect(() => {
    setSeries([data]);
  }, [data]);

  return <Chart options={options} series={series} type="radialBar" width={220} height={220} />;
};
