import { baseUrl, headers } from 'api';
import { removeMask } from 'helpers';

export const saveCadastroEmpresa = async ({ formData, id }) => {
  const url = id ? `${baseUrl}/dashboard/empresa/${id}/dados/` : `${baseUrl}/empresa/`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: formData,
  });

  return response;
};

export const getCadastroEmpresa = async (id) => {
  const url = id ? `${baseUrl}/dashboard/empresa/${id}/dados/` : `${baseUrl}/empresa/`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
  });

  return response;
};

export const saveEndereco = async (endereco) => {
  const response = await fetch(`${baseUrl}/empresa/endereco/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({
      ...endereco,
      logradouro: endereco.endereco_completo,
      cep: removeMask(endereco.cep),
    }),
  });

  return response;
};

export const saveContato = (contato) => {
  const response = fetch(`${baseUrl}/empresa/contato/`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: sessionStorage.getItem('@senac:token'),
    },
    body: JSON.stringify({
      nome: contato.nome_do_responsavel,
      telefone: removeMask(contato.telefone),
    }),
  });

  return response;
};

export const handleSubmit = async (formData, endereco, contato) => {
  const response = await Promise.all([saveCadastroEmpresa({ formData }), saveEndereco(endereco), saveContato(contato)]);

  return response;
};
