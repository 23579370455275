import { createContext, useCallback, useState } from 'react';
import { baseUrl, headers } from 'api';

export const ProcessosContext = createContext({});

export const ProcessosProvider = ({ children }) => {
  const [processos, setProcessos] = useState(null);
  const [currentPagesStudent, setCurrentPagesStudent] = useState(1);
  const [totalPagesStudent, setTotalPagesStudent] = useState(1);
  const [curriculo, setCurriculo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageProcessos, setPageProcessos] = useState(1);
  const [pageCurriculums, setPageCurriculums] = useState(1);
  const [vaga, setVaga] = useState(null);
  const [curriculumList, setCurriculumList] = useState(null);

  const getProcessos = async (params) => {
    const res = await fetch(`${baseUrl}/dashboard/processo/${params || ''}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await res.json();
    setProcessos(data.results);
    setPageProcessos(data.total_pages);
  };

  const getProcessosEmpresa = async (params) => {
    setIsLoading(true);
    const res = await fetch(`${baseUrl}/processo/${params || ''}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await res.json();
    setTotalPagesStudent(data.total_pages);
    setCurrentPagesStudent(data.page);
    setProcessos(data.results);
    setIsLoading(false);
  };

  const getVaga = async (id) => {
    const res = await fetch(`${baseUrl}/dashboard/processo/${id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await res.json();
    setVaga(data);
  };

  const getCurriculo = async (id) => {
    const res = await fetch(`${baseUrl}/processo/curriculo/${id}/`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await res.json();
    setCurriculo(data);
    return data;
  };

  const getCurriculumByCompany = async (params) => {
    const res = await fetch(`${baseUrl}/empresa/curriculos/${params || ''}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await res.json();
    setCurriculumList(data);
    return data;
  };

  const getCurriculumAdmin = async ({ processoId, params }) => {
    const res = await fetch(`${baseUrl}/dashboard/processo/${processoId}/curriculos/${params || ''}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await res.json();
    setCurriculumList(data);
    setPageCurriculums(data?.total_pages);
    return data;
  };

  const handlePaginateStudents = async () => {
    setIsLoading(true);
    const res = await fetch(`${baseUrl}/processo/?page=${currentPagesStudent + 1}&page_size=${20}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });

    const data = await res.json();
    if (data.results) {
      setTotalPagesStudent(data.total_pages);
      setCurrentPagesStudent(data.page);
      setProcessos([...processos, ...data.results]);
    }
    setIsLoading(false);
  };

  return (
    <ProcessosContext.Provider
      value={{
        processos,
        getProcessos,
        curriculo,
        getCurriculo,
        vaga,
        getVaga,
        getProcessosEmpresa,
        getCurriculumByCompany,
        curriculumList,
        pageProcessos,
        getCurriculumAdmin,
        isLoading,
        pageCurriculums,
        setPageCurriculums,
        handlePaginateStudents,
        currentPagesStudent,
        totalPagesStudent,
      }}
    >
      {children}
    </ProcessosContext.Provider>
  );
};
