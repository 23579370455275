import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const TextArea = styled.textarea`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #d2d2d2;
  padding: 16px 40px 16px 16px;
  margin: 8px 0;
  height: 250px;
  resize: none;
`;
