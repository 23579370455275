import styled from 'styled-components';

export const WhiteButton = styled.button`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 16px;
  color: var(--blue-senac);
  font-weight: bold;

  p {
    color: var(--blue-senac);
  }
`;

export const BlueButton = styled.button`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-senac);
  border: none;
  border-radius: 16px;
  color: #fff;
  font-weight: bold;
  opacity: 1;
  transition: 0.1s all;

  &:disabled {
    opacity: 0.5;
  }

  &.is-editing {
    display: none;
  }
`;

export const OrangeButton = styled.button`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--orange-senac);
  border: none;
  border-radius: 16px;
`;

export const ButtonOrangeLetter = styled.button`
  border: none;
  padding: 0;
  color: var(--orange-senac);
  background-color: transparent;
  font-weight: bold;
`;
