import { useNavigate } from 'react-router-dom';
import { CustomLink, Overlay } from 'components/atoms';
import { useAuth } from 'hooks';

import Logo from 'assets/images/white_logo.svg';
import Dash from 'assets/icons/dashboard.svg';
import Database from 'assets/icons/database.svg';
import Clipboard from 'assets/icons/clipboard.svg';
import Comment from 'assets/icons/comment.svg';
import User from 'assets/icons/user.svg';
import Users from 'assets/icons/users.svg';
import AdminAlunosIcon from 'assets/icons/admin_alunos.svg';
import ImportIcon from 'assets/icons/import_icon.svg';
import Subscribers from 'assets/icons/subscribers.svg';

import * as S from './styles';

export const Drawer = () => {
  const { userType, openDrawer, setOpenDrawer } = useAuth();
  const navigate = useNavigate();

  const handleExit = () => {
    sessionStorage.removeItem('@senac:token');
    navigate('/');
  };

  return (
    <>
      <S.Drawer className={openDrawer ? 'open' : ''}>
        <img src={Logo} alt="Senac" />
        <ul>
          <li>
            <CustomLink to="/admin/dashboard">
              <img src={Dash} alt="" />
              Dashboard
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/admin/perfil">
              <img src={User} alt="" />
              Perfil
            </CustomLink>
          </li>
          {userType === 0 || userType === 1 ? (
            <li>
              <CustomLink to="/admin/usuarios">
                <img src={Users} alt="" />
                Usuários
              </CustomLink>
            </li>
          ) : null}
          <li>
            <CustomLink to="/admin/vagas">
              <img src={Comment} alt="" />
              Vagas
            </CustomLink>
          </li>
          {userType === 0 || userType === 1 ? (
            <li>
              <CustomLink to="/admin/inscritos">
                <img src={Subscribers} alt="" />
                Inscritos
              </CustomLink>
            </li>
          ) : null}
          <li>
            <CustomLink to="/admin/alunos">
              <img src={AdminAlunosIcon} alt="" />
              Alunos
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/admin/empresas">
              <img src={Database} alt="" />
              Empresas
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/admin/relatorios">
              <img src={Clipboard} alt="" />
              Relatórios
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/admin/importacao">
              <img src={ImportIcon} alt="" />
              Importação
            </CustomLink>
          </li>
        </ul>

        <S.Leave onClick={handleExit}>Sair</S.Leave>
      </S.Drawer>
      {openDrawer ? <Overlay onClick={() => setOpenDrawer(false)} /> : null}
    </>
  );
};
