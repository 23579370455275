import * as S from './styles';

export const Ranking = ({ data }) => {
  return (
    <S.Content>
      <thead>
        <tr>
          <th>Vagas</th>
          <th>Segmento</th>
          <th>Quantidades</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => (
          <tr key={item?.id}>
            <td>{item?.nome}</td>
            <td>{item?.segmento}</td>
            <td>{item?.quantidade}</td>
          </tr>
        ))}
      </tbody>
    </S.Content>
  );
};
